import {useForm, yupResolver} from "./use-form";

function useSmartForm({schema, defaultValues}) {
	if (!schema) {
		throw new Error("A schema must be provided to useSmartForm");
	}

	return useForm({
		mode: "all",
		criteriaMode: "all",
		resolver: yupResolver(schema),
		defaultValues: defaultValues ? defaultValues : {}
	});
}

export default useSmartForm;