const axios = require('axios');

function searchCompanies(searchTerm) {
	return axios.get(`/companies`, {
		params: {
			term: searchTerm
		}
	});
}

function getAllCompanies(searchTerm) {
	return axios.get(`/companies`);
}

function searchProducts(searchTerm, vicariousCompany) {
	return axios.get(`/products`, {
		params: {
			term: searchTerm,
			vicariousCompany: vicariousCompany
		}
	});
}

function getSingleModel(model, id) {
	return axios.get(`${model}/${id}`);
}

function deleteSingleModel(model, id) {
	return axios.delete(`${model}/${id}`);
}

function searchEduProducts(searchTerm) {
	return axios.get(`/eduProducts`, {
		params: {
			term: searchTerm
		}
	});
}

function searchEduSchools(searchTerm) {
	return axios.get(`/eduSchools`, {
		params: {
			term: searchTerm
		}
	});
}

export {searchCompanies, searchProducts, getSingleModel, deleteSingleModel, getAllCompanies, searchEduProducts, searchEduSchools};