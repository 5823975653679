import * as React from "react";

function SvgBenroFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M9.9 0h-.6c.3 0 .5.1.8.1.8.2 1.6.6 2.2 1.2 1 1 1.6 2.3 1.5 3.7 0 2.1-1.1 3.7-3.4 4.7.9.2 1.7.7 2.4 1.3.9.9 1.4 2.1 1.4 3.4.1 1.9-.8 3.6-2.4 4.6-.9.5-1.9.8-3 .9.4.1.7.1 1.1.1 5.5.1 10.1-4.4 10.1-10C19.9 4.4 15.4-.1 9.9 0M0 2.7h7c1.6 0 3 1.3 3 2.9 0 1.6-1.3 2.9-3 2.9H0V2.7zm0 14.2h7c1.6 0 3-1.3 3-2.9 0-1.6-1.3-2.9-3-2.9H0v5.8z"
      />
    </svg>
  );
}

export default SvgBenroFavicon;
