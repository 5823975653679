import clsx from "clsx";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CircularProgress, makeStyles,
	TextField
} from "@material-ui/core";
import loginLogo from '../assets/MAC-Group_Logo.svg';
import {styles} from "../utils/styles";
import * as yup from "yup";
import {useState} from "react";
import useSmartForm from "../utils/smart-form";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
	...styles,
	loginCard: {
		width: 550
	},
	forgotPasswordText: {
		color: theme.palette.primary.main
	},
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	logo: {
		height: 'auto',
		width: 300
	}
}));


const forgotPasswordSchema = yup.object().shape({
	email: yup.string().email("Please enter a valid email").required("Email is required")
});

function ForgotPassword() {
	const classes = useStyles();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const {register, handleSubmit, formState: {errors}} = useSmartForm({schema: forgotPasswordSchema});
	const {ref: emailRef, ...emailRegister} = register('email');
	const { enqueueSnackbar } = useSnackbar();

	async function submitForgotPassword(data) {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		try {
			await axios.post('/forgotPassword', {
				email: data.email
			});
			enqueueSnackbar("If a user with that email exists, we will send you a forgot password email!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "success"
			});
			history.push("/login");
		} catch (e) {
			enqueueSnackbar("Something went wrong!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
			setIsLoading(false);
		}
	}

	return (
		<div className={clsx(classes.column, classes.alignCenterCenter, classes.fullHeight)}>
			<Card className={classes.loginCard} raised={true}>
				<CardContent className={classes.column}>
					<div className={clsx(classes.row, classes.alignCenter)}>
						<img className={classes.logo} src={loginLogo} alt="Logo"/>
					</div>
					<TextField {...emailRegister} variant="outlined" label="Email" autoComplete="email" autoFocus={true} type="email"
										 required={true} margin="normal" inputRef={emailRef} error={!!errors.email}
										 helperText={errors.email?.message}
										 onKeyPress={(e) => {
											 if (e.key === "Enter") {
												 handleSubmit(submitForgotPassword)();
											 }
										 }}/>
				</CardContent>
				<CardActions className={clsx(classes.row, classes.alignEnd)}>
					<Button variant="outlined" onClick={() => history.goBack()} disabled={isLoading}>
						<span className={classes.forgotPasswordText}>Cancel</span>
					</Button>
					<div className={classes.relativePosition}>
						<Button variant="contained" color="primary" onClick={handleSubmit(submitForgotPassword)} disabled={isLoading}>Submit</Button>
						{isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
					</div>
				</CardActions>
			</Card>
		</div>
	);
}

export default ForgotPassword;