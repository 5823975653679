import * as React from "react";
const SvgSpecialOpportunitiesGreen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 21 21",
    }}
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      d="m20.4 12.5-.6-1.1c-.3-.6-.3-1.2 0-1.8l.6-1.1c.2-.4.1-.9-.3-1.2L19 6.6c-.5-.3-.8-.9-.9-1.5L18 3.8c0-.5-.4-.9-.9-.9l-1.3-.1c-.6 0-1.2-.3-1.5-.9L13.6.8c-.3-.4-.8-.5-1.2-.3l-1.1.6c-.5.3-1.2.3-1.7 0L8.5.6c-.4-.2-1-.1-1.2.3L6.6 2c-.4.5-.9.8-1.5.9H3.8c-.4 0-.8.4-.9.9v1.3c0 .6-.4 1.2-.9 1.5l-1.1.7c-.4.2-.5.8-.3 1.2l.6 1.1c.3.6.3 1.2 0 1.8l-.6 1.1c-.2.4-.1.9.3 1.2l1.1.7c.5.3.8.9.9 1.5l.1 1.3c0 .5.4.9.9.9l1.3.1c.6 0 1.2.3 1.5.9l.7 1.1c.3.4.8.5 1.2.3l1.1-.6c.5-.3 1.2-.3 1.7 0l1.1.6c.4.2 1 .1 1.2-.3l.7-1.1c.3-.5.9-.8 1.5-.9l1.3-.1c.5 0 .9-.4.9-.9l.1-1.3c0-.6.4-1.2.9-1.5l1.1-.7c.3-.2.4-.8.2-1.2zM9.9 5.2v-.9c0-.4.3-.7.7-.7.4 0 .7.3.7.7v.9c1.2.2 2.3.7 2.8 1.4.1.1.2.3.2.5 0 .5-.4.8-.8.8-.2 0-.4-.1-.7-.3-.5-.5-1.3-.8-2.2-.8-1.3 0-1.8.6-1.8 1.2 0 .5.4.8.9 1.1.1 0 .1.2-.1.2s-2 .5-2 .5c-.3-.5-.6-1-.6-1.9 0-1.3.9-2.5 2.9-2.7zm1.4 10.7v.9c0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7v-.9c-1.5-.2-2.7-1-3.2-1.8-.1-.1-.1-.3-.1-.4 0-.5.4-.8.8-.8.3 0 .5.2.7.3.7.7 1.5 1.2 2.5 1.2 1.4 0 2.1-.5 2.1-1.4 0-.6-.4-1-1.1-1.3-.1 0-.1-.2 0-.2s2.1-.6 2.1-.6c.5.5.8 1.1.8 2 0 1.7-1.1 2.8-3.2 3zm4.8-6.3L5.5 12.5h-.2c-.3 0-.6-.2-.7-.5-.1-.4.1-.8.5-.9l10.6-2.9h.2c.3 0 .6.2.7.5.1.4-.1.8-.5.9z"
    />
  </svg>
);
export default SvgSpecialOpportunitiesGreen;
