import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	makeStyles,
	TextField
} from "@material-ui/core";
import {styles} from "../utils/styles";
import clsx from "clsx";
import * as yup from "yup";
import useSmartForm from "../utils/smart-form";
import {autocompleteRenderInput, useAutocomplete, useDefaults} from "../utils/autocomplete";
import {useHistory, useParams} from "react-router";
import {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FormAutocomplete from "../components/form-autocomplete";
import {getSingleModel, searchCompanies} from "../utils/common-requests";
import User from "../data/models/user";
import FormCheckbox from "../components/form-checkbox";
import store from "../data/store";
import {useSnackbar} from "notistack";
import Spacer from "../components/spacer";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
	...styles,
	cardContent: {
		paddingTop: 0
	},
	brandList: {
		overflowY: "auto"
	},
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

const editSchema = yup.object().shape({
	firstName: yup.string().required("First Name is required").max(255, 'Must be less than 255 characters'),
	lastName: yup.string().required("Last Name is required").max(255, 'Must be less than 255 characters'),
	email: yup.string().email("Please enter a valid email").required("Email is required").max(255, 'Must be less than 255 characters'),
	company: yup.object().nullable().when('role', {
		is: (role) => !role || !role.internal,
		then: yup.object().nullable().required("Dealer is required")
	}),
	role: yup.object().required("User Role is required").nullable(),
	salesmanNumber: yup.string(),
	whatsNew: yup.boolean(),
	accountingDepartment: yup.boolean(),
	isSpiffQualified: yup.boolean()
});

function getCompanyAdmin(companyId) {
	const storeState = store.getState();
	const users = storeState.users.users;
	return users.find((u) => (u.company === companyId) && u.userRole === "Administrator");
}

function getBrandPermissionsForCompany(companyVal) {
	if (!companyVal) {
		return {}
	} else {
		const storeState = store.getState();
		const admin = getCompanyAdmin(companyVal.recNo);

		if (admin) {
			return JSON.parse(JSON.stringify(admin.brandPermissions));
		} else {
			let tempMap = {};
			const brandCategories = storeState.categories.categories;

			brandCategories.forEach((bc) => {
				if (!tempMap[bc.brand]) {
					tempMap[bc.brand] = {};
				}

				if (bc.dealerType === "5") {
					if (companyVal.dealerType.includes('5')) {
						tempMap[bc.brand][bc.id] = true;
					}
				} else {
					tempMap[bc.brand][bc.id] = true;
				}

			});

			return tempMap;
		}
	}
}

function EditUser() {
	const classes = useStyles();
	const history = useHistory();
	const {id} = useParams();
	const {enqueueSnackbar} = useSnackbar();
	const [loadingUser, setLoadingUser] = useState(true);
	const [updating, setUpdating] = useState(false);
	const filterCompany = useSelector((state) => state.users.companyFilter);
	const [salesmanName, setSalesmanName] = useState('');
	const [brandPermissions, setBrandPermissions] = useState({});
	const brandCategories = useSelector((state) => state.categories.categories);
	const currentUser = useSelector((state) => state.users.currentUser);
	const userRoles = useSelector((state) => state.users.roles);
	const currentUserRole = userRoles.find((role) => role.title === currentUser.userRole);
	const isExternal = !currentUserRole.internal;
	const autocompleteDefaults = useDefaults();
	const [setCompanySearch, companyResults, companiesIsLoading] = useAutocomplete(isExternal ? () => ({data: []}) : searchCompanies);
	const {register, handleSubmit, formState: {errors}, control, reset, watch, setValue} = useSmartForm({
		schema: editSchema,
		defaultValues: {
			"role": null,
			"company": null
		}
	});
	const {ref: firstNameRef, ...firstNameRegister} = register('firstName');
	const {ref: lastNameRef, ...lastNameRegister} = register('lastName');
	const {ref: emailRef, ...emailRegister} = register('email');
	const {ref: salesmanNumberRef, ...salesmanNumberRegister} = register('salesmanNumber');

	const selectedRole = watch('role');
	const salesmanNumber = watch('salesmanNumber');
	const company = watch('company');

	const filteredRoles = userRoles.filter((role) => {
		const isLowerRank = role.rank < currentUserRole.rank;
		const isContact = role.title === "Contact";

		if (isContact) {
			return currentUserRole.internal;
		} else if (company || !currentUserRole.internal) {
			return (isLowerRank && role.internal === false);
		} else {
			return isLowerRank;
		}
	});

	useEffect(() => {
		(async function () {
			if (!id) return;
			try {
				const response = await getSingleModel('users', id);
				const user = User.fromJson(response.data);
				const userRole = userRoles.find((role) => role.title === user.userRole);
				if (user.salesmanName) {
					setSalesmanName(user.salesmanName);
				}
				if (user.brandPermissions) {
					setBrandPermissions(user.brandPermissions);
				}

				reset({
					'firstName': user.firstName,
					'lastName': user.lastName,
					'email': user.email,
					'company': !userRole.internal ? {id: user.companyId, name: user.companyName, recNo: user.company, dealerType: user.dealerType} : null,
					'companyInput': !userRole.internal ? `${user.companyName} - ${user.companyId}` : '',
					'role': userRoles.find((itm) => itm.title === user.userRole),
					'roleInput': user.userRole,
					'salesmanNumber': user.salesmanNumber,
					'accountDisabled': user.accountDisabled,
					'whatsNew': user.whatsNew,
					'sepUser': !!user.sepUser,
					'accountingDepartment': user.accountingDepartment,
					'isSpiffQualified': user.isSpiffQualified
				}, {
					keepErrors: false,
					keepDirty: false,
					keepValues: false,
					keepDefaultValues: false,
					keepIsSubmitted: false,
					keepTouched: false,
					keepIsValid: false,
					keepSubmitCount: false
				});
				setLoadingUser(false);
			} catch (e) {
				enqueueSnackbar("Failed to load user for edit!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					variant: "error"
				});
				history.push('/manageUsers');
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function updateUser(data) {
		if (updating) {
			return;
		}
		setUpdating(true);
		try {
			const response = await axios.put(`/users/${id}`, {
				...data,
				salesmanNumber: data.salesmanNumber ? data.salesmanNumber : '',
				company: data.company ? data.company.recNo : null,
				userRole: data.role.title,
				brandPermissions: JSON.stringify(brandPermissions)
			});
			enqueueSnackbar(response.data.message, {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: response.data.id ? "success" : "error"
			});
			setUpdating(false);
			if (response.data.id) {
				history.push('/manageUsers');
			}
		} catch (e) {
			enqueueSnackbar("Failed to save user!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
			setUpdating(false);
		}
	}

	function setBrandPermissionsFromCompanyChange(companyVal) {
		if (selectedRole?.title === "Contact") return;
		setBrandPermissions(getBrandPermissionsForCompany(companyVal));
	}

	function setBrandPermissionsFromRoleChange(role) {
		if (!role || role.title === "Brand Manager" || role.title === "Contact") {
			setBrandPermissions({});
		} else if (role.internal) {
			let tempMap = {};

			brandCategories.forEach((bc) => {
				if (!tempMap[bc.brand]) {
					tempMap[bc.brand] = {};
				}

				tempMap[bc.brand][bc.id] = true;

			});

			setBrandPermissions(tempMap);
		} else if (!role.internal) {
			setBrandPermissions(getBrandPermissionsForCompany(company));
		}
	}

	function toggleBrandPermission(val, categoryId, brandId) {
		let tempBP = JSON.parse(JSON.stringify(brandPermissions));

		if (!tempBP[brandId]) {
			tempBP[brandId] = {};
		}

		tempBP[brandId][categoryId] = val;

		setBrandPermissions(tempBP);
	}

	function getCategoryChecked(brandCategory) {
		if (!brandPermissions[brandCategory.brand]) {
			return false;
		} else {
			return !!brandPermissions[brandCategory.brand][brandCategory.id];
		}
	}

	return (
		<div className={clsx(classes.row, classes.alignCenterStart, "flex")}>
			<Card className={clsx("flex50", classes.column)} raised={true}>
				<CardHeader title="Edit User"/>
				<CardContent className={clsx("flex", classes.column, classes.cardContent)}>
					{loadingUser ?
						<div className={clsx("flex", classes.column, classes.alignCenterCenter)}>
							<CircularProgress/>
						</div> :
						<>
							<div className={classes.row}>
								<TextField {...firstNameRegister} className={"flex"} variant="outlined" label="First Name"
													 autoComplete="given-name" autoFocus={true} type="text" required={true} margin="normal"
													 inputRef={firstNameRef} error={!!errors.firstName} helperText={errors.firstName?.message}
													 disabled={updating}/>
								<span className={classes.noShrink} style={{width: 16}}/>
								<TextField {...lastNameRegister} className={"flex"} variant="outlined" label="Last Name"
													 autoComplete="family-name" type="email" required={true} margin="normal" inputRef={lastNameRef}
													 error={!!errors.lastName} helperText={errors.lastName?.message} disabled={updating}/>
							</div>
							<div className={classes.row}>
								<TextField {...emailRegister} className={"flex"} variant="outlined" label="Email" autoComplete="email"
													 type="email" required={true} margin="normal" inputRef={emailRef} error={!!errors.email}
													 helperText={errors.email?.message} disabled={updating}/>
								<span className={classes.noShrink} style={{width: 16}}/>
								<FormAutocomplete
									{...autocompleteDefaults}
									formProps={{name: "role", control: control}}
									className="flex"
									getOptionLabel={(x) => x.title}
									options={filteredRoles}
									onChange={(e, v, r) => {
										setValue('roleInput', v ? v.title : '');
										if (v?.title !== selectedRole?.title) {
											setValue('salesmanNumber', '');
										}

										if ((!v && !isExternal && !filterCompany) || (v && v.internal)) {
											setValue('company', null);
											setValue('companyInput', '');
											setSalesmanName('');
											setValue('whatsNew', false);
										}

										if (!v || !["Administrator", "Buyer", "User"].includes(v.title)) {
											setValue('sepUser', false);
										}

										setBrandPermissionsFromRoleChange(v);
									}}
									renderInput={autocompleteRenderInput({
										label: "User Role",
										margin: "normal",
										name: "roleInput",
										register: register,
										error: !!errors.role || !!errors.roleInput,
										helperText: errors.role?.message || errors.roleInput?.message,
										required: true,
										disabled: !!(updating || id === currentUser.id.toString())
									})}
									disabled={!!(updating || id === currentUser.id.toString())}
								/>
							</div>
							<div className={classes.row}>
								{selectedRole?.internal ?
									<TextField className={"flex"} variant="outlined" label="Company" margin="normal" disabled={true}
														 value={"MAC Group"}/> :
									<FormAutocomplete
										{...autocompleteDefaults}
										formProps={{name: "company", control: control}}
										className="flex"
										getOptionLabel={(x) => x && x.id ? `${x.name} - ${x.id}` : 'MAC Group'}
										filterOptions={(options, _) => options}
										loading={companiesIsLoading}
										options={companyResults}
										onInputChange={(e, value, reason) => setCompanySearch(value)}
										onChange={(e, v, r) => {
											setSalesmanName(v ? v.salesmanName ? v.salesmanName : '' : '');
											setBrandPermissionsFromCompanyChange(v);
											setValue('companyInput', v ? v.name : '');
										}}
										renderInputParams={{
											label: "Company",
											isLoading: companiesIsLoading,
											margin: "normal",
											name: "companyInput",
											register: register,
											error: !!errors.company || !!errors.companyInput,
											helperText: errors.company?.message || errors.companyInput?.message,
											required: true,
											disabled: !!(updating || isExternal || (selectedRole?.internal ?? true) || filterCompany)
										}}
										disabled={!!(updating || isExternal || (selectedRole?.internal ?? true) || filterCompany)}
									/>}
								<span className={classes.noShrink} style={{width: 16}}/>
								{selectedRole?.internal ?
									<TextField key={"salesmanNumber"} {...salesmanNumberRegister} className={"flex"} variant="outlined" label="Salesman Number"
														 margin="normal" inputRef={salesmanNumberRef}
														 error={!!errors.salesmanNumber} helperText={errors.salesmanNumber?.message}
														 disabled={!!(updating || isExternal || (selectedRole?.title !== "Salesman"))}
														 InputLabelProps={{shrink: !!salesmanNumber}}/> :
									<TextField key={"salesmanName"} className={"flex"} variant="outlined" label="Salesman" margin="normal" disabled={true}
														 value={salesmanName} InputLabelProps={{shrink: !!salesmanName}}/>}
							</div>
							<span style={{height: 8, display: "block", flexShrink: 0}}/>
							<Fragment>
								<div className={clsx(classes.row)}>
									{currentUserRole.internal && selectedRole && !selectedRole.internal &&
									<FormCheckbox
										className={clsx('flex', classes.alignCenterCenter)}
										name="whatsNew"
										control={control}
										label="WNWH Pricing Email"
										disabled={updating}
										labelPlacement='start'
									/>
									}
									{selectedRole && ["Administrator", "Buyer", "User"].includes(selectedRole.title) &&
									<FormCheckbox
										className={clsx('flex', classes.alignCenterCenter)}
										name="sepUser"
										control={control}
										label="EPP User"
										disabled={updating}
										labelPlacement='start'
									/>
									}
								</div>
								<span style={{height: 8, display: "block", flexShrink: 0}}/>
							</Fragment>
							<Fragment>
								<div className={classes.row}>
									<span style={{marginLeft: 8}}/>
									<FormCheckbox
										className={clsx(classes.row, classes.alignCenterCenter, 'flex')}
										name="accountDisabled"
										control={control}
										label="Account Access Disabled"
										disabled={updating}
									/>
									<Spacer/>
									<FormCheckbox
										className={clsx(classes.row, classes.alignCenterCenter, 'flex')}
										name="accountingDepartment"
										control={control}
										label="Accounting Department"
										disabled={updating || isExternal}
									/>
									<Spacer/>
									<FormCheckbox
										className={clsx(classes.row, classes.alignCenterCenter, 'flex')}
										name="isSpiffQualified"
										control={control}
										label="Spiff Qualified"
										disabled={updating || isExternal}
									/>
								</div>
							</Fragment>
							{/*<Typography variant="subtitle2" color="textSecondary" align="center">*/}
							{/*	Brand Permissions*/}
							{/*</Typography>*/}
							{/*<List className={clsx(classes.brandList, "flex")}>*/}
							{/*	<Divider/>*/}
							{/*	{brandCategories.map((bc) => (*/}
							{/*		<Fragment key={bc.name}>*/}
							{/*			<ListItem>*/}
							{/*				<ListItemText primary={bc.name}/>*/}
							{/*				<ListItemSecondaryAction>*/}
							{/*					<Checkbox*/}
							{/*						color="primary"*/}
							{/*						edge="end"*/}
							{/*						disabled={!!(currentUser?.id?.toString() === id && currentUserRole.title !== "Super Administrator") ||*/}
							{/*						(bc.name === "Broncolor" && company && !company.dealerType?.includes('5')) || selectedRole?.title === "Contact"}*/}
							{/*						onChange={(e) => {*/}
							{/*							toggleBrandPermission(e.target.checked, bc.id, bc.brand);*/}
							{/*						}}*/}
							{/*						checked={getCategoryChecked(bc)}*/}
							{/*					/>*/}
							{/*				</ListItemSecondaryAction>*/}
							{/*			</ListItem>*/}
							{/*			<Divider/>*/}
							{/*		</Fragment>*/}
							{/*	))}*/}
							{/*</List>*/}
						</>
					}
				</CardContent>
				<CardActions className={clsx(classes.row, classes.alignEnd)}>
					<div className={classes.row}>
						<Button onClick={() => history.goBack()} disabled={updating}>
							Cancel
						</Button>
						{!loadingUser && <div className={classes.relativePosition}>
							<Button color="primary" onClick={handleSubmit(updateUser)} disabled={updating}>Save</Button>
							{updating && <CircularProgress size={24} className={classes.buttonProgress}/>}
						</div>}
					</div>
				</CardActions>
			</Card>
		</div>
	);
}

export default EditUser;