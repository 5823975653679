import {Switch, FormControlLabel} from "@material-ui/core";
import {useController} from "../utils/use-form";

function FormSwitch(props) {
	const {name, control, color, ...restProps} = props;
	const {field} = useController({
		name: name,
		control: control
	});

	return (
		<FormControlLabel
			{...restProps}
			onChange={(e) => {
				field.onChange(e.target.checked);

				if (restProps.onChange) {
					restProps.onChange(e);
				}
			}}
			checked={!!field.value}
			inputRef={field.ref}
			control={<Switch color={color ? color : "primary"}/>}
		/>
	);
}

export default FormSwitch;