import {PaymentInputsWrapper, usePaymentInputs} from "react-payment-inputs";
import images from 'react-payment-inputs/images';
import {css} from "styled-components";
import {useController} from "../utils/use-form";

const validateCard = require('card-validator');

const wrapperStyles = {
	fieldWrapper: {
		base: css`
						flex: 1;
					`
	},
	inputWrapper: {
		base: css`
						box-shadow: unset;
						height: 56px;
					`,
		errored: css`
						box-shadow: unset;
						border-color: rgb(244, 67, 54);
					`,
		focused: css`
            border-color: #246DFF;
            border-width: 2px;
            box-shadow: unset;
          `
	},
	input: {
		base: css`
            width: 100%;
            color: rgba(0, 0, 0, 0.87);
						padding: 0;
						font-size: 1rem;
						font-family: WotfardRegular,Roboto,Helvetica,Arial,"Open Sans";
						font-weight: 400;
						line-height: 1;
          `,
	},
	errorText: {
		base: css`
            color: rgb(244, 67, 54);
            margin-left: 14px;
          `
	}
};

function FormCardInput({numberFormProps, dateFormProps, cvcFormProps}) {
	const {field: numberField} = useController({
		name: numberFormProps.name,
		control: numberFormProps.control
	});
	const {field: dateField} = useController({
		name: dateFormProps.name,
		control: dateFormProps.control
	});
	const {field: cvcField} = useController({
		name: cvcFormProps.name,
		control: cvcFormProps.control
	});

	const {
		wrapperProps,
		getCardImageProps,
		getCardNumberProps,
		getExpiryDateProps,
		getCVCProps,
		meta
	} = usePaymentInputs({
		cardNumberValidator: ({cardNumber}) => validateCard.number(cardNumber).isValid ? null : "Card number invalid",
		expiryDateValidator: ({date}) => validateCard.expirationDate(date).isValid ? null : "Expiration date invalid",
		cvcValidator: ({cvc}) => {
			return validateCard.cvv(cvc, meta?.cardType?.type === "amex" ? 4 : 3).isValid ? null : "CVC invalid"
		},
	});

	return (
		<PaymentInputsWrapper
			{...wrapperProps}
			styles={wrapperStyles}
		>
			<svg {...getCardImageProps({ images })} />
			<input
				{...getCardNumberProps({
					onChange: (e) => {
						numberField.onChange(e.target.value)
					},
					onBlur: (e) => {
						numberField.onBlur();
					}
				})}
				style={{
					flex: 5
				}}
				value={numberField.value}
			/>
			<input
				{...getExpiryDateProps({
					onChange: (e) => {
						dateField.onChange(e.target.value)
					},
					onBlur: (e) => {
						dateField.onBlur();
					}
				})}
				style={{
					flex: 3
				}}
				value={dateField.value}
			/>
			<input
				{...getCVCProps({
					onChange: (e) => {
						cvcField.onChange(e.target.value)
					},
					onBlur: (e) => {
						cvcField.onBlur();
					}
				})}
				style={{
					flex: 2
				}}
				value={cvcField.value}
			/>
		</PaymentInputsWrapper>
	);
}

export default FormCardInput;