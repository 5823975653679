import moment from "moment";

function formatCurrency(number) {
	if (!number)
		return '0.00';

	return parseFloat(number).toLocaleString('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
}

let appBarRef = null;

function setAppBarRef(val) {
	appBarRef = val;
}

let contentNoPaddingRef = null;

function setContentNoPaddingRef(val) {
	contentNoPaddingRef = val;
}

function formatPhoneNumber(val) {
	if (val?.length !== 10) {
		return val;
	} else {
		return `(${val.substr(0,3)}) ${val.substr(3,3)}-${val.substr(6)}`;
	}
}

function serializeDate(val) {
	if (val) {
		return moment(val).format('YYYY-MM-DD');
	} else {
		return null;
	}
}

export {formatCurrency, appBarRef, setAppBarRef, contentNoPaddingRef, setContentNoPaddingRef, formatPhoneNumber, serializeDate};