import {Button, IconButton} from "@material-ui/core";
import * as React from "react";
import {useSnackbar} from "notistack";
import Spacer from "./spacer";
import {Delete, Edit} from "@material-ui/icons";

function InvoiceFileUploader({handleFile, selectedFile}){
  const uploadInputRef = React.useRef(null);
  const {enqueueSnackbar} = useSnackbar();
  const editMode = !!selectedFile;
  const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];

  return (
    <>
      <input
        ref={uploadInputRef}
        type="file"
        accept=".pdf, .png, .jpg, .jpeg"
        style={{ display: "none" }}
        onChange={(e) => {
          if(e.target.files.length > 0){
            if(!allowedTypes.includes(e.target.files[0].type)) {
              enqueueSnackbar('Please select a PDF/PNG/JPEG/JPG file', {variant: 'error'});
            }else{
              handleFile(e.target.files[0]);
            }
          }

        }}
      />
      {editMode ? (
        <div>
          {selectedFile.name}
          <Spacer/>
          <IconButton
            size='small'
            onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
          >
            <Edit/>
          </IconButton>
          <Spacer/>
          <IconButton
            size='small'
            onClick={() => handleFile(null)}
          >
            <Delete color="error"/>
          </IconButton>
        </div>
      ) : (
        <Button
          onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
          variant="contained"
        >
          Upload Invoice
        </Button>
      )}
    </>
  )
}

export default InvoiceFileUploader;