import Model from "../model";

class User extends Model {
	firstName;
	lastName;
	email;
	company;
	companyName;
	companyId;
	dealerType;
	userRole;
	brandPermissions;
	salesmanNumber;
	salesmanName;
	accountDisabled;
	whatsNew;
	sepUser;
	accountingDepartment;
	vendorId;
	isSpiffQualified;

	constructor({id, firstName, lastName, email, company, companyName, dealerType, userRole, brandPermissions, companyId, salesmanNumber, salesmanName, accountDisabled, whatsNew, sepUser, accountingDepartment, vendorId, isSpiffQualified}) {
		super({id: id});

		let formattedBrandPermissions;

		if (!brandPermissions) {
			formattedBrandPermissions = {};
		} else if (typeof brandPermissions === "string") {
			formattedBrandPermissions = JSON.parse(brandPermissions);
		} else if (typeof brandPermissions === "object") {
			formattedBrandPermissions = brandPermissions;
		} else {
			formattedBrandPermissions = {};
		}

		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.company = company;
		this.companyName = companyName;
		this.companyId = companyId;
		this.dealerType = dealerType;
		this.userRole = userRole;
		this.brandPermissions = formattedBrandPermissions;
		this.salesmanNumber = salesmanNumber;
		this.salesmanName = salesmanName;
		this.accountDisabled = accountDisabled;
		this.whatsNew = whatsNew;
		this.sepUser = sepUser;
		this.accountingDepartment = accountingDepartment;
		this.vendorId = vendorId;
		this.isSpiffQualified = isSpiffQualified;
	}

	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	static fromJson(map) {
		return new User({
			id: map.id,
			firstName: map.firstName,
			lastName: map.lastName,
			email: map.email,
			company: map.company,
			companyName: map.companyName,
			companyId: map.companyId,
			dealerType: map.dealerType,
			userRole: map.userRole,
			brandPermissions: map.brandPermissions,
			salesmanNumber: map.salesmanNumber,
			salesmanName: map.salesmanName,
			accountDisabled: map.accountDisabled,
			whatsNew: map.whatsNew,
			sepUser: map.sepUser,
			accountingDepartment: map.accountingDepartment,
			vendorId: map.vendorId,
			isSpiffQualified: map.isSpiffQualified
		});
	}

	toJson() {
		return {
			id: this.id,
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email,
			company: this.company,
			companyName: this.companyName,
			companyId: this.companyId,
			dealerType: this.dealerType,
			userRole: this.userRole,
			brandPermissions: this.brandPermissions,
			salesmanNumber: this.salesmanNumber,
			salesmanName: this.salesmanName,
			accountDisabled: this.accountDisabled,
			whatsNew: this.whatsNew,
			sepUser: this.sepUser,
			accountingDepartment: this.accountingDepartment,
			vendorId: this.vendorId,
			isSpiffQualified: this.isSpiffQualified
		}
	}
}

export default User;