import {Autocomplete} from "@material-ui/lab";
import {useController} from "../utils/use-form";
import PropTypes from 'prop-types';
import {formAutocompleteRenderInput} from "../utils/autocomplete";

function FormAutocomplete(props) {
	const {field} = useController({
		name: props.formProps.name,
		control: props.formProps.control
	});
	const {formProps, renderInputParams, immuneValueKey, ...restProps} = props;
	const renderInput = props.renderInput || formAutocompleteRenderInput({
		...renderInputParams,
		control: props.formProps.control,
		onBlur: (e) => {
			field.onBlur();
			if (renderInputParams.onBlur) {
				renderInputParams.onBlur(e);
			}
		}
	});

	return (
		<Autocomplete
			{...restProps}
			ref={field.ref}
			value={field.value}
			onChange={(e, v, r) => {
				// we use this so we can add arbitrary options via filterOptions for things like creating new objects that don't match an actual option
				// and can have their own unique structure
				if (!v || !!!v[immuneValueKey]) {
					field.onChange(v);
				}
				if (props.onChange) {
					props.onChange(e, v, r);
				}
			}}
			renderInput={renderInput}
		/>
	);
}

FormAutocomplete.propTypes = {
	formProps: PropTypes.shape({
		name: PropTypes.string.isRequired,
		control: PropTypes.object.isRequired
	})
}

export default FormAutocomplete;