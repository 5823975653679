import {Typography} from "@material-ui/core";


function AddressBlock({name, name2, address, address2, city, state, zip}) {
	return (
		<>
			<Typography variant="body2">{name}</Typography>
			<Typography variant="body2">{name2}</Typography>
			<Typography variant="body2">{address}</Typography>
			<Typography variant="body2">{address2}</Typography>
			<Typography variant="body2">
				{city || state || zip ? `${city}, ${state} ${zip}` : null}
			</Typography>
		</>
	);
}

export default AddressBlock;