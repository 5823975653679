import * as React from "react";

function SvgQuickOrder(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20" {...props}>
      <path
        d="M22.5 8.003c.8 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5H22.2l-1.3 7.8c-.1.7-.7 1.2-1.5 1.2H4.5c-.7 0-1.3-.5-1.5-1.2l-1.3-7.7h-.2c-.8 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5H3l1.1-5c.3-1.4 1.5-2.4 2.9-2.4h2c0-.4.3-.7.7-.7h4.5c.4 0 .7.3.7.7h1.9c1.4 0 2.6 1 2.9 2.3l1.1 5h1.7zm-18 0h14.9l-1-4.6c-.2-.7-.8-1.2-1.5-1.2h-2c0 .4-.3.7-.7.7H9.7c-.4 0-.7-.3-.7-.7H7c-.7 0-1.3.5-1.5 1.2l-1 4.6z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgQuickOrder;
