import * as React from "react";

function SvgPhottixFavicon(props) {
  return (
    <svg
      id="Phottix_Favicon_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 20 20"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Phottix_Favicon_svg__Group_192">
        <g
          id="Phottix_Favicon_svg__Group_182"
          transform="translate(1.395 -4.908)"
        >
          <path
            id="Phottix_Favicon_svg__Path_100"
            d="M0 20c1.1 1.8 2.7 3.2 4.6 4.1v-9.6L0 20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_183"
          transform="translate(13.319 -19.432)"
        >
          <path
            id="Phottix_Favicon_svg__Path_101"
            d="M0 20a9.6 9.6 0 00-3.3-.6c-.7 0-1.3.1-2 .2L0 27.2V20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_184"
          transform="translate(6.667 -.571)"
        >
          <path
            id="Phottix_Favicon_svg__Path_102"
            d="M0 20c1.1.4 2.2.6 3.3.6.7 0 1.3-.1 2-.2L0 12.8V20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_185"
          transform="translate(7.309 -19.63)"
        >
          <path
            id="Phottix_Favicon_svg__Path_103"
            d="M0 20c-1.7.5-3.3 1.4-4.6 2.7l8.7 3.2L0 20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_186"
          transform="translate(12.677 -.365)"
        >
          <path
            id="Phottix_Favicon_svg__Path_104"
            d="M0 20c1.7-.5 3.3-1.4 4.6-2.7l-8.7-3.2L0 20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_187"
          transform="translate(2.274 -16.347)"
        >
          <path
            id="Phottix_Favicon_svg__Path_105"
            d="M0 20c-1.1 1.4-1.9 3.1-2.2 4.9l8.9-2.4L0 20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_188"
          transform="translate(10.937 -6.114)"
        >
          <path
            id="Phottix_Favicon_svg__Path_106"
            d="M0 20l6.8 2.5C8 21.1 8.7 19.4 9 17.6L0 20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_189"
          transform="translate(18.61 -15.085)"
        >
          <path
            id="Phottix_Favicon_svg__Path_107"
            d="M0 20c-1.1-1.8-2.7-3.2-4.6-4.1v9.6L0 20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_190"
          transform="translate(.031 -10.77)"
        >
          <path
            id="Phottix_Favicon_svg__Path_108"
            d="M0 20v.8c0 1.5.4 3.1 1 4.5l6-7.2L0 20z"
          />
        </g>
        <g
          id="Phottix_Favicon_svg__Group_191"
          transform="translate(19.965 -9.223)"
        >
          <path
            id="Phottix_Favicon_svg__Path_109"
            d="M0 20v-.8c0-1.5-.4-3.1-1-4.5l-6 7.1L0 20z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgPhottixFavicon;
