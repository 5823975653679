import * as React from "react";

function SvgDealerBulletins(props) {
  return (
    <svg
      id="Dealer-Bulletins_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 28.5 40"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Dealer-Bulletins_svg__Group_324" transform="translate(-18.75 -30)">
        <path
          id="Dealer-Bulletins_svg__Path_336"
          d="M34.4 44.3c-.7 0-1.4-.5-1.4-1.3V30H23c-2.3 0-4.2 1.8-4.3 4.1V65.7c0 2.3 1.8 4.2 4.1 4.3H43c2.3 0 4.2-1.8 4.3-4.1V44.3H34.4zM38.3 61L34 65.3c-.1.1-.3.3-.4.3-.4.2-.8.2-1.1 0-.1-.1-.3-.1-.4-.3L27.7 61c-.6-.6-.6-1.4 0-2 .6-.6 1.4-.6 2 0l1.9 1.9V50c0-.8.6-1.4 1.4-1.5.8 0 1.4.6 1.5 1.4v11l1.9-1.9c.6-.6 1.4-.6 2 0 .4.6.4 1.4-.1 2z"
        />
        <path
          id="Dealer-Bulletins_svg__Path_337"
          d="M47.2 41.4c0-.2 0-.4-.1-.6 0-.1-.1-.3-.3-.4l-10-10c-.1-.1-.3-.3-.4-.3-.1-.1-.4-.1-.6-.1v11.4h11.4z"
        />
      </g>
    </svg>
  );
}

export default SvgDealerBulletins;
