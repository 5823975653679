import {makeStyles, Portal} from "@material-ui/core";
import {styles} from "../utils/styles";
import clsx from "clsx";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import Iframe from "react-iframe";
import {contentNoPaddingRef} from "../utils/utilities";

const useStyles = makeStyles((theme) => ({
	...styles
}));

function BrandInformation() {
	// this id is the brand number
	const {id} = useParams();
	const classes = useStyles();
	const brands = useSelector((state) => state.brands.brands);
	const matchingBrand = brands.find((b) => b.number.toString() === id);

	return (
		!matchingBrand ?
			<div className={clsx(classes.column, classes.alignCenterCenter, "flex")}>
				<span>Brand Not Found!</span>
			</div> :
			<Portal container={contentNoPaddingRef.current}>
				<Iframe
					url={matchingBrand['pageUrl']}
					className={clsx(classes.iFrame, "flex")}
				/>
			</Portal>
	);
}

export default BrandInformation;