import Model from "../model";

class Category extends Model {
	brand;
	number;
	name;
	dealerType;
	displayOrder;

	constructor({id, brand, number, name, dealerType, displayOrder}) {
		super({id: id});

		this.brand = brand;
		this.number = number;
		this.name = name;
		this.dealerType = dealerType;
		this.displayOrder = displayOrder;
	}

	static fromJson(map) {
		return new Category({
			id: map.id,
			brand: map.brand,
			number: map.number,
			name: map.name,
			dealerType: map.dealerType,
			displayOrder: map.displayOrder
		});
	}

	toJson() {
		return {
			id: this.id,
			brand: this.brand,
			number: this.number,
			name: this.name,
			dealerType: this.dealerType,
			displayOrder: this.displayOrder
		}
	}
}

export default Category;