import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Brand from "../models/brand";
import {resetAction} from "../../utils/common-actions";

const axios = require('axios');

const getAllBrands = createAsyncThunk(
	'brands/getAllBrands',
	async (arg, thunkAPI) => {
		try {
			const response = await axios.get('/brands');
			return response.data.sort().map((itm) => {
				return Brand.fromJson(itm);
			}).sort((a,b) => a.name.localeCompare(b.name));
		} catch (e) {
			return [];
		}
	}
);

export { getAllBrands };

const initialState = {
	brands: []
};

export const brandsSlice = createSlice({
	name: 'brands',
	initialState: initialState,
	reducers: {
		setBrands: (state, action) => {
			state.brands = action.payload;
		}
	},
	extraReducers: {
		[getAllBrands.fulfilled]: (state, action) => {
			state.brands = action.payload;
		},
		[resetAction]: (state, action) => {
			return initialState;
		}
	}
});

export const {setBrands} = brandsSlice.actions;

export default brandsSlice.reducer;