import {
	Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
	IconButton,
	makeStyles, SvgIcon, TextField, Typography
} from "@material-ui/core";
import * as Icons from '../icons';
import {Autocomplete} from '@material-ui/lab';
import {XGrid} from "@material-ui/x-grid";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {autocompleteRenderInput, useAutocomplete, useDefaults} from "../utils/autocomplete";
import {styles} from "../utils/styles";
import {useHistory} from "react-router";
import {useEffect, useState} from "react";
import {getUsersForManagement, removeUser, setCompanyFilter} from "../data/slices/users";
import {deleteSingleModel, searchCompanies} from "../utils/common-requests";
import {getAllBrands} from "../data/slices/brands";
import {getAllCategories} from "../data/slices/categories";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
	...styles,
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	filterRow: {
		marginBottom: 16
	},
	deleteButton: {
		color: "#F44336"
	},
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	}
}));

function GetActionsCell({gridCellParams}) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const {enqueueSnackbar} = useSnackbar();
	const user = gridCellParams.row;
	const currentUser = useSelector((state) => state.users.currentUser);
	const roles = useSelector((state) => state.users.roles);
	const currentUserRole = roles.find((role) => role.title === currentUser.userRole);
	const rowUserRole = roles.find((role) => role.title === user.userRole);
	const rankAllowed = currentUserRole.internal ? currentUserRole.rank > rowUserRole.rank : currentUserRole.rank >= rowUserRole.rank;
	const isCurrentUser = currentUser.id === user.id;
	const shouldDisplay = rankAllowed || isCurrentUser;
	const [dialogOpen, setDialogOpen] = useState(false);
	const [deleting, setDeleting] = useState(false);

	async function confirmDelete() {
		if (deleting) {
			return;
		}
		setDeleting(true);
		try {
			await deleteSingleModel('users', user.id);
			dispatch(removeUser(user));
			setDeleting(false);
			setDialogOpen(false);
		} catch (e) {
			enqueueSnackbar("Delete user failed!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
			setDeleting(false);
		}
	}

	return (
		shouldDisplay &&
		<>
			<div className={clsx(classes.row, classes.alignEnd)}>
				<IconButton onClick={() => history.push(`/manageUsers/editUser/${gridCellParams.row.id}`)}>
					<SvgIcon component={Icons.Edit} viewBox="-2.5 -2 24 24"/>
				</IconButton>
				{user?.id !== currentUser?.id && <IconButton onClick={() => setDialogOpen(true)}>
					<SvgIcon className={classes.deleteButton} component={Icons.Trash} viewBox="-4.5 -2 24 24"/>
				</IconButton>}
			</div>
			<Dialog open={dialogOpen} disableBackdropClick={true} disableEscapeKeyDown={true}>
				<DialogTitle>Delete User</DialogTitle>
				<DialogContent>
					<Typography>Are you sure you want to delete {user.fullName} at {user.companyName ?
						user.companyName.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : "MAC Group"}?</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)}>Cancel</Button>
					<div className={classes.relativePosition}>
						<Button className={classes.deleteButton} onClick={confirmDelete} disabled={deleting}>Delete</Button>
						{deleting && <CircularProgress size={24} className={classes.buttonProgress} />}
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
}

const columns = [
	{
		field: 'fullName',
		headerName: 'Name',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1,
		valueGetter: (gridValueGetterParams) => gridValueGetterParams.row.fullName
	},
	{
		field: 'companyName',
		headerName: 'Dealer',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1,
		renderCell: (gridCellParams) => <span
			style={{textTransform: "capitalize"}}>{gridCellParams.row.companyName ? gridCellParams.row.companyName.toLowerCase() : "MAC Group"}</span>
	},
	{
		field: 'email',
		headerName: 'Email',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1
	},
	{
		field: 'userRole',
		headerName: 'Role',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1
	},
	{
		field: 'actions',
		headerName: " ",
		align: 'right',
		width: 125,
		filterable: false,
		resizable: false,
		sortable: false,
		renderCell: (gridCellParams) => <GetActionsCell gridCellParams={gridCellParams}/>
	}
];

function ManageUsers() {
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.users.currentUser);
	const classes = useStyles();
	const autocompleteDefaults = useDefaults();
	const [setSearchTerm, searchResults, isLoading] = useAutocomplete(searchCompanies);
	const [filterTerm, setFilterTerm] = useState('');
	const [resultsLoading, setResultsLoading] = useState(true);
	const filterCompany = useSelector((state) => state.users.companyFilter);
	const users = useSelector((state) => {
		if (!filterCompany && !filterTerm && currentUser.userRole !== "Administrator") {
			return [];
		}
		return state.users.users.filter((user) => {
			const companyMatch = filterCompany ? (user.company === filterCompany.recNo) : true;
			let termMatch = true;

			if (filterTerm && filterTerm !== "*") {
				const firstNameFound = user.firstName.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
				const lastNameFound = user.lastName.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
				const emailFound = user.email.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
				const roleFound = user.userRole.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;

				termMatch = firstNameFound || lastNameFound || emailFound || roleFound;
			}

			return termMatch && companyMatch;
		});
	});
	const history = useHistory();

	useEffect(() => {
		// noinspection JSCheckFunctionSignatures
		(async function () {
			let getUsersAction = dispatch(getUsersForManagement());
			let getBrandsAction = dispatch(getAllBrands());
			let getCategoriesAction = dispatch(getAllCategories());

			await getUsersAction;
			await getBrandsAction;
			await getCategoriesAction;
			setResultsLoading(false);
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={clsx(classes.row, classes.filterRow)}>
				{currentUser && ["Super Administrator", "Sales Manager", "Salesman"].includes(currentUser.userRole) &&
				<>
					<Autocomplete
						{...autocompleteDefaults}
						className="flex25"
						classes={{
							root: classes.backgroundWhite
						}}
						getOptionLabel={(x) => `${x.name} - ${x.id}`}
						filterOptions={(options, _) => options}
						loading={isLoading}
						options={searchResults}
						onInputChange={(e, value, reason) => setSearchTerm(value)}
						value={filterCompany}
						onChange={(e, v, r) => dispatch(setCompanyFilter(v))}
						renderInput={autocompleteRenderInput({
							label: "Dealer",
							isLoading: isLoading
						})}
					/>
					<span className={classes.noShrink} style={{width: 12}}/>
				</>
				}
				<TextField
					className="flex25"
					classes={{
						root: classes.backgroundWhite
					}}
					name="search"
					variant="outlined"
					label="User Search"
					onChange={(e) => setFilterTerm(e.target.value)}
				/>
				<span className="flex"/>
				<div className={clsx(classes.column, classes.alignCenter, classes.noShrink)}>
					<Button color="primary" variant="contained" size="large"
									onClick={() => history.push('/manageUsers/createUser')}>
						Create User
					</Button>
				</div>
			</div>
			<XGrid
				classes={{
					root: classes.backgroundWhite
				}}
				columns={columns}
				rows={users}
				disableColumnSelector={true}
				disableMultipleSelection={true}
				disableSelectionOnClick={true}
				hideFooter={true}
				loading={resultsLoading}>
			</XGrid>
		</>
	);
}

export default ManageUsers;