import * as React from "react";

const SvgEmail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 20"
    style={{
      enableBackground: "new 0 0 30 20",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M15 10.5 2.1 0h25.8L15 10.5zm3.3.4L15.8 13c-.4.4-1.1.4-1.5 0l-2.6-2.1L1.5 20h26.9l-10.1-9.1zM0 1.4v16.7l9.8-8.7-9.8-8zm20.2 8 9.8 8.7V1.4l-9.8 8z"
    />
  </svg>
);

export default SvgEmail;
