import * as React from "react";

function SvgHome(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.5 20" {...props}>
      <path
        d="M18.213 8L9.813.2c-.3-.3-.8-.3-1.1 0L.313 8c-.3.3-.4.8 0 1.2.2.2.4.3.6.3h1.3v9.7c0 .5.4.8.8.8h4v-3.9c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2V20h4c.5 0 .8-.4.8-.8V9.5h1.3c.5 0 .8-.4.8-.8.2-.3.1-.5-.1-.7zm-9.2 1c-1.1 0-1.9-.9-1.9-1.9 0-1.1.9-1.9 1.9-1.9 1.1 0 1.9.9 1.9 1.9 0 1-.8 1.9-1.9 1.9z"
      />
    </svg>
  );
}

export default SvgHome;
