import * as React from "react";

function Reward(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.5 40" {...props}>
      <path d="M23,7h6v2.7c0,4-3.2,7.3-7.3,7.3h0"/>
      <path d="M9,7H3v2.7c0,4,3.2,7.3,7.3,7.3h0"/>
      <path d="M22,29H10v-3c0-1.1,0.9-2,2-2h8c1.1,0,2,0.9,2,2V29z"/>
      <path d="M18,24v-4.3c2.9-0.9,5-3.5,5-6.7V5H9v8c0,3.2,2.1,5.8,5,6.7V24"/>
    </svg>
  );
}

export default Reward;