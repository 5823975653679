import * as React from "react";

const SvgPvedu = (props) => (
  <svg
    id="PVEDU_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    xmlSpace="preserve"
    {...props}
  >
    <path
      className="PVEDU_svg__st0"
      d="M8.2 8.3h9.7v3.3H12V18h4.4v3.3H12v7.1h5.9v3.3H8.2zM23 11.7v16.6h.5c2.1 0 3.6-2.1 3.6-8.3 0-6.2-1.5-8.3-3.6-8.3H23zm-3.9-3.4h4.2c4.5 0 7.4 3.5 7.4 11.8s-3 11.7-7.5 11.7h-4.1V8.3zM32.1 26.3v-18H36v18.2c0 1.4.7 2.2 1.7 2.2 1.1 0 1.8-.8 1.8-2.2V8.3h3.8v18.1c0 3.6-2.2 5.7-5.6 5.7-3.4-.1-5.6-2.2-5.6-5.8M3.5 8.3v-.5c0-2.3 1.8-4.2 4.1-4.2h.6V0h-.6C3.4 0 0 3.5 0 7.8v.5h3.5zM3.5 31.7v.5c0 2.3 1.8 4.2 4.1 4.2h.6V40h-.6C3.4 40 0 36.5 0 32.2v-.5h3.5zM47.9 8.3v-.5c0-2.3-1.8-4.2-4.1-4.2h-.6V0h.6c4.2 0 7.6 3.5 7.6 7.8v.5h-3.5zM47.9 31.7v.5c0 2.3-1.8 4.2-4.1 4.2h-.6V40h.6c4.2 0 7.6-3.5 7.6-7.8v-.5h-3.5z"
    />
  </svg>
);

export default SvgPvedu;
