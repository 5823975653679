import * as React from "react";

function SvgSearch(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M19.5 17.4L15 12.9c-.3-.3-.7-.5-1.1-.5 1-1.3 1.6-3 1.6-4.7 0-2.1-.8-4-2.3-5.5C11.8.8 9.8 0 7.8 0c-2.1 0-4 .8-5.5 2.3-3 3-3 7.9 0 11 1.5 1.5 3.4 2.3 5.5 2.3 1.7 0 3.3-.6 4.7-1.6 0 .4.2.8.5 1.1l4.5 4.5c.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5.3-.3.5-.7.5-1.1-.2-.5-.4-.9-.7-1.1zM12 12c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8C1 9.6 1 5.8 3.4 3.4c1.1-1.1 2.7-1.8 4.3-1.8 1.6 0 3.1.6 4.3 1.8 1.1 1.1 1.8 2.7 1.8 4.3 0 1.7-.6 3.2-1.8 4.3z"
      />
    </svg>
  );
}

export default SvgSearch;
