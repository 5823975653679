import * as React from "react";

function SvgJupioFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M20 9.975c0-5.5-4.5-10-10-10s-10 4.4-10 10c0 5.5 4.5 10 10 10s10-4.5 10-10m-4.1 0c0 .9-.7 1.6-1.6 1.6h-1.1c-.9 0-1.6.7-1.6 1.6v1.1c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6v-1.1c0-.9-.7-1.6-1.6-1.6H5.6c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6h1.1c.9 0 1.6-.7 1.6-1.6v-1.1c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6v1.1c0 .9.7 1.6 1.6 1.6h1.1c.9 0 1.7.7 1.7 1.6z"
      />
    </svg>
  );
}

export default SvgJupioFavicon;
