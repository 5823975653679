import * as React from "react";

function SvgShow(props) {
  return (
    <svg
      id="Show_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 27.5 17.5"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M27.2 8c-.2-.4-6-8-13.5-8S.5 7.6.3 8c-.4.5-.4 1 0 1.5.2.4 6 8 13.5 8s13.2-7.6 13.5-8c.3-.5.3-1-.1-1.5zm-13.4 7c-3.5 0-6.2-2.8-6.2-6.2s2.7-6.2 6.2-6.2S20 5.2 20 8.8 17.2 15 13.8 15z"
      />
      <circle cx={13.8} cy={8.8} r={3.1} />
    </svg>
  );
}

export default SvgShow;
