import * as React from "react";

function SvgBroncolorFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 20" {...props}>
      <path d="M11 20h14.5V0H11v20zM0 20h9.2V0H0v20z" />
    </svg>
  );
}

export default SvgBroncolorFavicon;
