import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import { Provider } from 'react-redux'
import store from "./data/store";
import { LicenseInfo } from '@material-ui/x-grid';
import {SnackbarProvider} from "notistack";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {wotfardBold, wotfardExtraLight, wotfardLight, wotfardMedium, wotfardRegular, wotfardSemiBold, wotfardThin} from './fonts/fonts';
import {CssBaseline} from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://b35d3d25a6544145b65dfcf3e39e4593@o903622.ingest.sentry.io/5985068",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
  normalizeDepth: 50
});

LicenseInfo.setLicenseKey(
  'c7707e74fcae866ddd7fb596adcc5501T1JERVI6MjMxNTEsRVhQSVJZPTE2NDg3MzY3NTAwMDAsS0VZVkVSU0lPTj0x',
);

const theme = createMuiTheme({
  typography: {
    fontFamily: ['WotfardRegular', 'Roboto', 'Helvetica', 'Arial', '"Open Sans"'].join(',')
  },
  palette: {
    primary: {
      main: "#246DFF"
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [wotfardBold, wotfardExtraLight, wotfardLight, wotfardMedium, wotfardRegular, wotfardSemiBold, wotfardThin]
      },
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <CssBaseline/>
                <App />
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
        </ThemeProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
