import clsx from "clsx";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CircularProgress, IconButton, InputAdornment, makeStyles, SvgIcon,
	TextField, Typography
} from "@material-ui/core";
import loginLogo from '../assets/MAC-Group_Logo.svg';
import {styles} from "../utils/styles";
import * as yup from "yup";
import {useEffect, useState} from "react";
import useSmartForm from "../utils/smart-form";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router";
import axios from "axios";
import * as Icons from "../icons";
import useQuery from "../utils/query-params";

const useStyles = makeStyles((theme) => ({
	...styles,
	loginCard: {
		width: 550
	},
	forgotPasswordText: {
		color: theme.palette.primary.main
	},
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	logo: {
		height: 'auto',
		width: 300
	}
}));


const resetPasswordSchema = yup.object().shape({
	password: yup.string().required("Required").min(8, "Password must be at least 8 characters"),
	confirmPassword: yup.string().required("Required").min(8, "Password must be at least 8 characters").oneOf([yup.ref('password')], "Passwords must match")
});

function ResetPassword() {
	const classes = useStyles();
	const history = useHistory();
	const queryParams = useQuery();
	const token = queryParams.get('token');
	const [initialLoading, setInitialLoading] = useState(true);
	const [tokenValid, setTokenValid] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const {register, handleSubmit, formState: {errors}} = useSmartForm({schema: resetPasswordSchema});
	const {ref: passwordRef, ...passwordRegister} = register('password');
	const {ref: confirmPasswordRef, ...confirmPasswordRegister} = register('confirmPassword');
	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		(async function () {
			const response = await axios.get('/validateReset', {
				params: {
					token: token
				}
			});

			if (response.data.valid) {
				setTokenValid(true);
			}

			setInitialLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function submitResetPassword(data) {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		try {
			const response = await axios.post('/resetPassword', {
				password: data.password,
				token: token
			});
			enqueueSnackbar(response.data.message, {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: response.data.success ? "success" : "error"
			});
			if (response.data.success) {
				history.push("/login");
			} else {
				setIsLoading(false);
			}
		} catch (e) {
			enqueueSnackbar("Something went wrong!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
			setIsLoading(false);
		}
	}

	if (initialLoading) {
		return (
			<div className={clsx(classes.column, classes.alignCenterCenter, classes.fullHeight)}>
				<Card className={classes.loginCard} raised={true}>
					<CardContent className={classes.column}>
						<div className={clsx(classes.row, classes.alignCenter)}>
							<img className={classes.logo} src={loginLogo} alt="Logo"/>
						</div>
						<div className={clsx(classes.row, classes.alignCenterCenter)}>
							<CircularProgress/>
						</div>
					</CardContent>
				</Card>
			</div>
		);
	} else if (tokenValid) {
		return (
			<div className={clsx(classes.column, classes.alignCenterCenter, classes.fullHeight)}>
				<Card className={classes.loginCard} raised={true}>
					<CardContent className={classes.column}>
						<div className={clsx(classes.row, classes.alignCenter)}>
							<img src={loginLogo} alt="Logo"/>
						</div>
						<TextField
							{...passwordRegister} variant="outlined" label="Password" autoComplete="password"
							type={showPassword ? "text" : "password"} required={true} margin="normal" inputRef={passwordRef}
							error={!!errors.password} helperText={errors.password?.message}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowPassword(!showPassword)}
									>
										{showPassword ? <SvgIcon component={Icons.Hide} viewBox="0 0 27 20"/> : <SvgIcon component={Icons.Show} viewBox="0 0 27.5 17.5"/>}
									</IconButton>
								</InputAdornment>
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handleSubmit(submitResetPassword)();
								}
							}}
						/>
						<TextField
							{...confirmPasswordRegister} variant="outlined" label="Confirm Password" autoComplete="password"
							type={showPassword ? "text" : "password"} required={true} margin="normal" inputRef={confirmPasswordRef}
							error={!!errors.confirmPassword} helperText={errors.confirmPassword?.message}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowPassword(!showPassword)}
									>
										{showPassword ? <SvgIcon component={Icons.Hide} viewBox="0 0 27 20"/> : <SvgIcon component={Icons.Show} viewBox="0 0 27.5 17.5"/>}
									</IconButton>
								</InputAdornment>
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handleSubmit(submitResetPassword)();
								}
							}}
						/>
					</CardContent>
					<CardActions className={clsx(classes.row, classes.alignEnd)}>
						<Button variant="outlined" onClick={() => history.push('/login')} disabled={isLoading}>
							<span className={classes.forgotPasswordText}>Cancel</span>
						</Button>
						<div className={classes.relativePosition}>
							<Button variant="contained" color="primary" onClick={handleSubmit(submitResetPassword)}
											disabled={isLoading}>Reset</Button>
							{isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
						</div>
					</CardActions>
				</Card>
			</div>
		);
	} else {
		return (
			<div className={clsx(classes.column, classes.alignCenterCenter, classes.fullHeight)}>
				<Card className={classes.loginCard} raised={true}>
					<CardContent className={classes.column}>
						<div className={clsx(classes.row, classes.alignCenter)}>
							<img src={loginLogo} alt="Logo"/>
						</div>
						<Typography align="center" variant="subtitle2">
							This password reset link is no longer valid. Please request a new password reset link!
						</Typography>
					</CardContent>
					<CardActions className={clsx(classes.row, classes.alignEnd)}>
						<Button variant="outlined" onClick={() => history.push('/login')}>
							<span className={classes.forgotPasswordText}>Back to Login</span>
						</Button>
					</CardActions>
				</Card>
			</div>
		);
	}
}

export default ResetPassword;