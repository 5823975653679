import {configureStore} from "@reduxjs/toolkit";
import usersReducer from "./slices/users";
import brandsReducer from "./slices/brands";
import categoriesReducer from "./slices/categories";
import utilsReducer from './slices/utils';
import settingsReducer from './slices/settings';
import placeOrderReducer from './slices/place-order';

const store = configureStore({
	reducer: {
		users: usersReducer,
		brands: brandsReducer,
		categories: categoriesReducer,
		utils: utilsReducer,
		settings: settingsReducer,
		placeOrder: placeOrderReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});

export default store;