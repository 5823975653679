import * as React from "react";

function SvgKupoFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 20" {...props}>
      <path
        d="M25 0h-5.3l-6.9 5.3 2-5.3H7.5L0 20h7.3l2-5.2c-.1 2.8 2.1 5.1 4.9 5.2 2 0 3.8-1.3 4.5-3.2l1.7-4.5v-.2c0-.3-.3-.6-.6-.6h-3.4l6.3-5.4L25 0z"
      />
    </svg>
  );
}

export default SvgKupoFavicon;
