import * as React from "react";

function SvgTenbaFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" {...props}>
      <path
        d="M8 0L0 2.4v11.8L8 20l8-5.8V2.4L8 0zM1.5 13.4V3.6L8 1.7l6.5 1.9v9.8L8 18.1l-6.5-4.7zM5.1 6v1.6h2.1v6.2h1.5V7.6h2.1V6H5.1z"
      />
    </svg>
  );
}

export default SvgTenbaFavicon;
