import * as React from "react";

function SvgCalibriteFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M3.1 2.7C3.9 2 4.7 1.5 5.5 1v18c-.9-.4-1.7-1-2.4-1.7V2.7M6.9.5v19.1c.8.3 1.6.4 2.4.5V0c-.8.1-1.6.2-2.4.5M0 10c0 2 .6 4 1.8 5.7V4.3C.6 6 0 8 0 10M10.7 0v4.3c3.1.4 5.3 3.3 4.9 6.4-.3 2.5-2.3 4.6-4.9 4.9V20c5.5-.4 9.7-5.2 9.3-10.7-.4-5-4.3-9-9.3-9.3z"
      />
    </svg>
  );
}

export default SvgCalibriteFavicon;
