import {Transition} from 'react-transition-group';
import {SvgIcon} from "@material-ui/core";

const DURATION = 100;

const defaultStyle1 = {
	transition: `opacity ${DURATION}ms linear, transform ${DURATION}ms linear`
};

const transitionStyles1 = {
	entering: {opacity: 0, transform: "rotate(-90deg)"},
	entered: {opacity: 0, transform: "rotate(-90deg)"},
	exiting: {opacity: 1},
	exited: {opacity: 1}
}

const defaultStyle2 = {
	transition: `opacity ${DURATION}ms linear, transform ${DURATION}ms linear`,
	marginLeft: -24
};

const transitionStyles2 = {
	entering: {opacity: 1},
	entered: {opacity: 1},
	exiting: {opacity: 0, transform: "rotate(90deg)"},
	exited: {opacity: 0, transform: "rotate(90deg)"}
}

function RotateTransitionIcons(props) {
	const {inProp, Icon1, Icon1Props, Icon2, Icon2Props} = props;

	return (
		<Transition in={inProp} timeout={DURATION}>
			{(state) => (
				[
					<SvgIcon
						key="icon1"
						style={{
							...defaultStyle1,
							...transitionStyles1[state]
						}}
						{...Icon1Props}
						component={Icon1}
					/>,
					<SvgIcon
						key="icon2"
						style={{
							...defaultStyle2,
							...transitionStyles2[state]
						}}
						{...Icon2Props}
						component={Icon2}
					/>
				]
			)}
		</Transition>
	);
}

export {RotateTransitionIcons};