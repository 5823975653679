import AutoNumeric from 'autonumeric';
import {useEffect, useRef} from "react";
import {TextField, InputAdornment, makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  rightAlignInput: {
    '& input': {
      textAlign: 'right',
    },
  },
});

function CurrencyTextField ({autoNumericOptions, ...restProps}) {
  const internalTextFieldRef = useRef(null)
  const autoNumeric = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    autoNumeric.current = new AutoNumeric(internalTextFieldRef.current, (restProps?.value) || 0, {
      modifyValueOnWheel: false,
      outputFormat: 'number',
      negativePositiveSignPlacement: 'p',
      ...autoNumericOptions,
      currencySymbol: ''
    });

    return () => autoNumeric.current?.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (restProps.value !== undefined && internalTextFieldRef.current && document.activeElement !== internalTextFieldRef.current) {
    autoNumeric.current?.set(restProps.value);
  }

  const adornmentPosition = autoNumericOptions?.currencySymbolPlacement ? (autoNumericOptions?.currencySymbolPlacement === 's' ? 'end' : 'start') : 'start';

  return (
    <TextField
      inputRef={internalTextFieldRef}
      size='small'
      variant='outlined'
      {...restProps}
      className={clsx(classes.rightAlignInput, restProps?.className || '')}
      value={undefined}
      InputProps={restProps?.InputProps || {
        [`${adornmentPosition}Adornment`]: autoNumericOptions?.currencySymbol !== '' ? (
          <InputAdornment position={adornmentPosition}>
            {autoNumericOptions?.currencySymbol || '$'}
          </InputAdornment>
        ) : null
      }}
      InputLabelProps={{
        shrink: true
      }}
      onChange={(e) => {
        const valFromInput = e.target.value;
        const isString = !!autoNumericOptions?.outputFormat && autoNumericOptions.outputFormat === 'string'
        const isNullOrUndefined = valFromInput === undefined || valFromInput === null;
        // noinspection JSObjectNullOrUndefined
        const formattedVal = isNullOrUndefined ?
          '' :
          (isString ?
            valFromInput.toString() :
            (isNaN(parseFloat(valFromInput.replace(/([^\d.-])/g, ''))) ? '' : parseFloat(valFromInput.replace(/([^\d.-])/g, ''))));

        if (restProps?.onChange) {
          restProps.onChange(e, formattedVal);
        }
      }}
      onBlur={(e) => {
        if (!e.target.value) {
          const stringValue = (0).toFixed(typeof autoNumericOptions?.decimalPlaces !== "number" ? 2 : autoNumericOptions.decimalPlaces);
          autoNumeric.current?.set(stringValue);
        }

        const valAsNumber = isNaN(parseFloat(e.target.value.replace(/([^\d.-])/g, ''))) ?
          0.0 :
          parseFloat(e.target.value.replace(/([^\d.-])/g, ''));

        if (restProps?.onBlur) {
          restProps.onBlur(e, valAsNumber);
        }
      }}
    />
  )
}

export default CurrencyTextField;