import * as React from "react";

function SvgHide(props) {
  return (
    <svg
      id="Hide_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 27.5 20"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M27.2 9c-.2-.4-6-8-13.5-8-1.9 0-3.6.5-5.2 1.2l2.1 2.1c.9-.5 2-.8 3.1-.8 3.5 0 6.2 2.8 6.2 6.3 0 1.1-.3 2.2-.8 3.1l2.7 2.8c3.1-2.2 5.1-4.9 5.3-5.1.5-.6.5-1.1.1-1.6z"
      />
      <path
        d="M22.5 17.9l-6.3-6.3c.4-.5.6-1.2.6-1.9 0-1.7-1.4-3.1-3.1-3.1-.7 0-1.4.2-1.9.6L5 .4c-.5-.5-1.2-.5-1.7 0s-.5 1.2 0 1.7l2 2C2.3 6.3.4 8.8.3 9c-.4.5-.4 1 0 1.5.2.4 6 8 13.5 8 1.7 0 3.4-.4 4.9-1l2.2 2.2c.2.2.5.4.9.4s.6-.1.9-.4c.3-.5.3-1.3-.2-1.8zM13.8 16c-3.5 0-6.2-2.8-6.2-6.3 0-1 .2-1.9.6-2.8l2.5 2.5v.3c0 1.7 1.4 3.1 3.1 3.1h.3l2.5 2.5c-.9.5-1.8.7-2.8.7z"
      />
    </svg>
  );
}

export default SvgHide;
