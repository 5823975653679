import clsx from "clsx";
import {Button, Card, CardContent, IconButton, makeStyles, Tooltip, Typography} from "@material-ui/core";
import {styles} from "../utils/styles";
import {useSelector} from "react-redux";
import * as React from "react";
import {useState} from "react";
import useSmartForm from "../utils/smart-form";
import * as yup from "yup";
import moment from "moment";
import FormDatePicker from "../components/form-date-picker";
import Spacer from "../components/spacer";
import Moment from "react-moment";
import axios from "axios";
import {useSnackbar} from "notistack";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Receipt} from "@material-ui/icons";
import {formatCurrency, serializeDate} from "../utils/utilities";

const useStyles = makeStyles((theme) => ({
	...styles,
	header: {
		margin: 12,
		height: 40
	},
	headerInput: {
		boxSizing: 'content-box'
	},
	noTopMargin: {
		marginTop: 0
	},
	radioGroup: {
		marginTop: -4,
		width: 250
	},
	cursorPointer: {
		'& .MuiDataGrid-row': {
			cursor: 'pointer'
		}
	},
	dialogContent: {
		width: 1250
	},
	tableColumnHeaders: {
		color: "#246dff",
		fontFamily: "'WotfardMedium', sans-serif",
		fontSize: 14
	},
	tableEvenRow: {
		backgroundColor: "#FFF"
	},
	tableOddRow: {
		backgroundColor: "#F3F3F4"
	},
	filterTextContainer: {
		marginTop: -4
	},
	filterText: {
		marginTop: 6,
		marginLeft: 10
	},
	filterInputsMargin: {
		paddingBottom: 12
	},
	error: {
		color: theme.palette.error.main
	}
}));

const rewardsHistoryColumns = [
	{
		field: 'invoiceDate',
		headerName: 'Invoice Date',
		headerClassName: 'grid-column-headers',
		type: 'date',
		flex: 1,
		renderCell: (gridCellParams) => (
			<Moment format="MM/DD/YYYY">
				{gridCellParams.value}
			</Moment>
		)
	},
	{
		field: 'invoiceNumber',
		headerName: 'Invoice #',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1
	},
	{
		field: 'name',
		headerName: 'Customer',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1
	},
	{
		field: 'prodId',
		headerName: 'Product',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1
	},
	{
		field: 'quantity',
		headerName: 'Quantity',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
	},
	{
		field: 'spiffDue',
		headerName: 'Spiff Due',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		valueFormatter: (params) => `$${formatCurrency(params.value)}`
	},
	{
		field: 'isApproved',
		headerName: 'Approved',
		headerClassName: 'grid-column-headers',
		type: 'boolean',
		flex: 1,
	},
	{
		field: 'paidDate',
		headerName: 'Paid Date',
		headerClassName: 'grid-column-headers',
		type: 'date',
		flex: 1,
		renderCell: (gridCellParams) => {
			if(!gridCellParams.value) return '';
			return (
				<Moment format="MM/DD/YYYY">
					{gridCellParams.value}
				</Moment>
			)

		}
	},
	{
		field: 'deniedReason',
		headerName: 'Comment',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1
	},
	{
		field: '',
		headerName: '',
		headerClassName: 'grid-column-headers',
		type: 'string',
		align: 'center',
		headerAlign: 'center',
		renderCell: (gridCellParams) => {
			return (
				<Tooltip title="View Invoice">
					<IconButton
						onClick={() => {
							const queryString = new URLSearchParams({
								invoiceNumber: gridCellParams.row.invoiceNumber,
								custId: gridCellParams.row.custId,
							}).toString();

							window.open(
								`https://portal.macgroupus.com/api/rewardsInvoice?${queryString}`
								// `http://localhost:8080/rewardsInvoice?${queryString}`
								,
								'_blank'
							)

						}}
					>
						<Receipt />
					</IconButton>
				</Tooltip>
			)
		}
	}
];

const searchSchema = yup.object().shape({
	startDate: yup.date('Must be a valid date').typeError('Must be a valid date').nullable(),
	endDate: yup.date('Must be a valid date').typeError('Must be a valid date').nullable()
		.test('endDate', "End date must be on or after start date", (value, schema) => {
			if(!schema.parent.startDate || !value) return true;
			return moment(value).isSameOrAfter(schema.parent.startDate);
		})
});

function RewardsHistory() {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [rewardsHistory, setRewardsHistory] = useState([]);
	const currentUser = useSelector((state) => state.users.currentUser);

	const { handleSubmit, control, formState: {errors} } = useSmartForm({
		schema: searchSchema,
		defaultValues: {
			"startDate": null,
			"endDate": null
		}
	});


	async function getRewardsHistory(data) {
		setIsLoading(true);
		const formattedData = {
			startDate: serializeDate(data.startDate) || '',
			endDate: serializeDate(data.endDate) || '',
			userId: currentUser.id
		}
		const response = await axios.get('/rewardsHistory', {params: formattedData});
		if(response.data.succeeded){
			setRewardsHistory(response.data.history);
		}else{
			enqueueSnackbar(response.data.message || "An error occurred", {variant: 'error'});
		}
		setIsLoading(false);
	}


	return (
		<>
			<Card
				raised={true}
				className={clsx(classes.column, "flex")}
			>
				<CardContent className={clsx(classes.column, "flex")}>
					<div className={clsx(classes.row, classes.alignSpaceBetween)}>
						<Typography variant="h6">
							Rewards History
						</Typography>
					</div>
					<br/>
					<div className={clsx(classes.row)}>
						<FormDatePicker
							formProps={{name: "startDate", control: control}}
							label="Start Date"
							error={errors.startDate}
							helperText={errors.startDate?.message}
							size="small"
						/>
						<Spacer width={20} />
						<FormDatePicker
							formProps={{name: "endDate", control: control}}
							label="End Date"
							error={errors.endDate}
							helperText={errors.endDate?.message}
							size="small"
						/>
						<Spacer width={20} />
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmit(getRewardsHistory)}
						>
							Search
						</Button>
					</div>
					<Spacer height={20} />
					<div className={"flex"}>
						<DataGridPro
							classes={{
								root: 'NoSelectAll'
							}}
							columns={rewardsHistoryColumns}
							rows={rewardsHistory}
							disableColumnSelector={true}
							disableMultipleSelection={true}
							disableSelectionOnClick={true}
							loading={isLoading}
						>
						</DataGridPro>
					</div>
				</CardContent>
			</Card>
		</>
	)
}

export default RewardsHistory;