import {TextField} from "@material-ui/core";
import { useController } from '../utils/use-form';
import MaskedInput from 'react-text-mask';

function TextMask({ inputRef, ...other }) {
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
			guide={false}
			showMask
		/>
	)
}

function FormSocialSecurityInput(props) {
		const {formProps, ...restProps} = props;
	const {
		field,
		fieldState
	} = useController({
		name: formProps.name,
		control: formProps.control
	});

	return (
		<TextField
			error={fieldState.invalid}
			helperText={fieldState.error?.message}
			{...restProps}
			inputRef={field.ref}
			onChange={(e) => {
				field.onChange(e.target.value);
				if (restProps.onChange) {
					restProps.onChange(e);
				}
			}}
			onBlur={(e) => {
				if (restProps.onBlur) {
					restProps.onBlur(e);
				}
				field.onBlur();
			}}
			value={field.value}
			InputProps={{
				inputComponent: TextMask
			}}
		/>
	);
}

export default FormSocialSecurityInput;