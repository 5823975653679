import {TextField} from "@material-ui/core";
import {useController} from "../utils/use-form";

function FormTextField(props) {
	const {formProps, ...restProps} = props;
	const {field, fieldState} = useController({
		name: formProps.name,
		control: formProps.control
	});

	return (
		<TextField
			error={fieldState.invalid}
			helperText={fieldState.error?.message}
			{...restProps}
			inputRef={field.ref}
			onChange={(e) => {
				field.onChange(e.target.value);
				if (restProps.onChange) {
					restProps.onChange(e);
				}
			}}
			onBlur={(e) => {
				if (restProps.onBlur) {
					restProps.onBlur(e);
				}
				field.onBlur();
			}}
			value={field.value}
		/>
	);
}

export default FormTextField;