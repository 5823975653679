import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getSettings} from "../data/slices/settings";
import {Fab, makeStyles, SvgIcon, useTheme, Zoom} from "@material-ui/core";
import * as Icons from "../icons";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 100
	}
}));

const axios = require('axios');

function ManageAnnouncement() {
	const [isLoading, setIsLoading] = useState(true);
	const [announcement, setLocalAnnouncement] = useState("");
	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();

	const transitionDuration = {
		enter: theme.transitions.duration.enteringScreen,
		exit: theme.transitions.duration.leavingScreen,
	};

	useEffect(() => {
		(async function () {
			let returnVal = await dispatch(getSettings());
			setLocalAnnouncement(returnVal.payload.announcement);
			setIsLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleChange(content) {
		setLocalAnnouncement(content)
	}

	async function saveAnnouncement() {
		if (isLoading) {
			return;
		}

		try {
			setIsLoading(true);
			await axios.put(`/settings/updateAnnouncement`, {
				announcement
			});
			enqueueSnackbar("Updated successfully!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "success"
			});
			setIsLoading(false);
		} catch (e) {
			enqueueSnackbar("There was a problem saving the announcement!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
		}
	}

	return (
		<>
			<SunEditor
				className="flex"
				height="100%"
				placeholder="Set Announcement Here..."
				autoFocus={true}
				onChange={handleChange}
				setContents={announcement}
				disable={isLoading}
				enableToolbar={!isLoading}
				setOptions={{
					defaultTag: "div",
					resizingBar: false,
					charCounter: true,
					buttonList: [
						["undo", "redo"],
						['font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
						["bold", "underline", "italic", "strike", "subscript", "superscript"],
						"/",
						["fontColor", "hiliteColor", "textStyle"],
						["outdent", "indent"],
						["align", "horizontalRule", "list", "lineHeight", "table"],
						["link", "image", "video"],
						["fullScreen", "showBlocks", "codeView"],
						["preview"]
					]
				}}
			/>
			<Zoom
				in={true}
				timeout={transitionDuration}
				style={{
					transitionDelay: `${transitionDuration.exit}ms`,
				}}
				unmountOnExit
			>
				<Fab
					className={classes.fab}
					color="primary"
					onClick={saveAnnouncement}
					disabled={isLoading}
				>
					<SvgIcon component={Icons.Save} viewBox="-2 -2 24 24"/>
				</Fab>
			</Zoom>
		</>
	)
}

export default ManageAnnouncement;