import * as React from "react";

function SvgElinchromFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M0 19.961h20v-20H0v20zm10.7-18.5h7.8v17.1h-7.8v-2.3h5.6v-5.2h-5.6v-2.2h5.6v-5.2h-5.6v-2.2zm-9.2 0h7.8v2.2H3.7v5.2h5.6v2.2H3.7v5.2h5.6v2.2H1.5v-17zm9.2 3.7h4.1v2.2h-4.1v-2.2zm-5.5 0h4.1v2.2H5.2v-2.2zm5.5 7.4h4.1v2.2h-4.1v-2.2zm-5.5 0h4.1v2.2H5.2v-2.2z"
      />
    </svg>
  );
}

export default SvgElinchromFavicon;
