import * as React from "react";

function SvgShoppingCart(props) {
  return (
    <svg
      id="Shopping-Cart_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 22.5 20"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Shopping-Cart_svg__Group_306" transform="translate(-643.07 -343)">
        <path
          id="Shopping-Cart_svg__Path_91"
          d="M655.8 347c0-.4.1-.9.2-1.3h-8.3l-.3-1.1c-.3-.9-1.1-1.6-2.1-1.6h-1.5c-.4 0-.8.4-.8.8s.4.8.8.8h1.5c.2 0 .4.2.5.4l.4 1.6 1.7 8.1-1 1.9c-.2.4-.2.9 0 1.4.3.4.7.7 1.2.7h11.2c.4 0 .8-.4.8-.8s-.4-.8-.8-.8h-10.8l.8-1.4h10.5c.4 0 .7-.3.8-.6l.5-2.7c-2.9-.1-5.3-2.5-5.3-5.4z"
        />
        <path
          id="Shopping-Cart_svg__Path_92"
          d="M656.9 359.8c-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6z"
        />
        <path
          id="Shopping-Cart_svg__Path_93"
          d="M651 359.8c-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6z"
        />
        <circle
          id="Shopping-Cart_svg__Ellipse_4"
          cx={661.6}
          cy={347}
          r={4}
        />
      </g>
    </svg>
  );
}

export default SvgShoppingCart;
