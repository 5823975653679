import * as React from "react";

function SvgAdd(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M18.8 8.8h-7.5V1.2C11.2.5 10.7 0 10 0S8.8.5 8.8 1.2v7.5H1.2C.5 8.8 0 9.3 0 10s.5 1.2 1.2 1.2h7.5v7.5c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2v-7.5h7.5c.7 0 1.2-.5 1.2-1.2s-.3-1.2-1-1.2z"
      />
    </svg>
  );
}

export default SvgAdd;
