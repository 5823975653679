import * as React from "react";

function SvgSekonicFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 20" {...props}>
      <path
        d="M12.407 4.503c-1-.7-2.1-1.1-3.3-1.3-2.4-.1-3 1.7-2 2.9.7 1 1.9 2.2 2.9 3.7 1.1 1.3 1.6 3 1.6 4.7 0 3.7-2.9 5.5-6.1 5.5-2.1 0-4.1-1-5.4-2.5l.5-3.5c.9.9 1.9 1.6 3 2.1 2.2.8 4-.4 3.8-2-.2-1.4-1.6-2.8-2.8-4.2-1-1.2-2-2.3-2.2-3.9-.3-3.8 3-6 6.3-6 1.6-.1 3.2.6 4.4 1.7l-.7 2.8z"
      />
    </svg>
  );
}

export default SvgSekonicFavicon;
