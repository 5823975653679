import Model from "../model";

class Brand extends Model {
	number;
	name;
	displayOrder;
	pageUrl;

	constructor({id, number, name, displayOrder, pageUrl}) {
		super({id: id});

		this.number = number;
		this.name = name;
		this.displayOrder = displayOrder;
		this.pageUrl = pageUrl;
	}

	static fromJson(map) {
		return new Brand({
			id: map.id,
			number: map.number,
			name: map.name,
			displayOrder: map.displayOrder,
			pageUrl: map.pageUrl
		});
	}

	toJson() {
		return {
			id: this.id,
			number: this.number,
			name: this.name,
			displayOrder: this.displayOrder,
			pageUrl: this.pageUrl
		}
	}
}

export default Brand;