import * as React from "react";

function SvgEdit(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" {...props}>
      <path
        d="M18.8 5.5l-2.3 2.4-5.2-5.3L13.6.2c.1-.1.3-.2.5-.2s.4.1.5.2l4.2 4.3c.3.3.3.7 0 1zm-.5 13.1H6.6l9-9.6-5.2-5.3L.2 14.5c-.1.1-.2.3-.2.5v4.3c0 .4.3.7.7.7h17.6c.4 0 .7-.3.7-.7s-.3-.7-.7-.7z"
      />
    </svg>
  );
}

export default SvgEdit;
