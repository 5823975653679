import clsx from "clsx";
import {
	Button, CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	makeStyles,
	Typography
} from "@material-ui/core";
import { styles } from "../utils/styles";
import { XGrid } from "@material-ui/x-grid";
import { useEffect, useState } from "react";
import { clearShoppingCart, getShoppingCart, setShoppingCartLoading } from "../data/slices/place-order";
import { useDispatch, useSelector } from "react-redux";
import getShoppingCartColumns from "../components/shopping-cart-row";
import { useHistory } from "react-router";
import NumberFormat from "react-number-format";
import * as React from "react";
import FormTextField from "../components/form-text-field";
import useSmartForm from "../utils/smart-form";
import * as yup from "yup";
import axios from "axios";
import { useSnackbar } from "notistack";
import Spacer from "../components/spacer";

const useStyles = makeStyles((theme) => ({
	...styles,
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	firstRowMargin: {
		marginTop: 36,
		marginBottom: 36
	},
	footerPadding: {
		padding: 8
	},
	headerFontSize: {
		fontSize: 28
	},
	errorButton: {
		backgroundColor: theme.palette.error.main,
		color: 'white',
		'&:hover': {
			backgroundColor: theme.palette.error.dark
		}
	},
	redText: {
		color: theme.palette.error.main
	}
}));


function PlaceOrderShoppingCart() {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isPromptingClear, setIsPromptingClear] = useState(false);
	const [localColumns, setLocalColumns] = useState(getShoppingCartColumns())
	const [isPromptingQuote, setIsPromptingQuote] = useState(false);
	const cartLoading = useSelector((state) => state.placeOrder.shoppingCartLoading);
	const shoppingCartItems = useSelector((state) => state.placeOrder.shoppingCart);
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);
	const currentUser = useSelector((state) => state.users.currentUser);
	const userRoles = useSelector((state) => state.users.roles);
	const currentUserRole = userRoles.find((role) => role.title === currentUser.userRole);
	const isInternal = currentUserRole.internal;

	useEffect(() => {
		(async function () {
			dispatch(setShoppingCartLoading(true));
			await dispatch(getShoppingCart());
			dispatch(setShoppingCartLoading(false));
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={clsx(classes.column, "flex")}>
				<div className={clsx(classes.row, classes.alignSpaceBetweenCenter, classes.firstRowMargin)}>
					<Typography className={classes.headerFontSize} variant="h4">
						&nbsp;
						&nbsp;
						Shopping Cart
						{orderingFor && <span> - <span
							className={classes.capitalize}>{orderingFor.name.toLowerCase()}</span> - {orderingFor.id}</span>}
					</Typography>
					<div>
						<Button variant="contained" className={classes.errorButton} onClick={() => setIsPromptingClear(true)}>
							Clear Cart
						</Button>
						<span style={{ minWidth: 8, display: 'inline-block' }}/>
						<Button variant="contained" color="primary" onClick={() => history.push('/placeOrder/quick')}>
							Quick Order
						</Button>
						<span style={{ minWidth: 8, display: 'inline-block' }}/>
						{isInternal &&
							<Button variant="contained" color="primary" onClick={() => setIsPromptingQuote(true)}>
								Create Quote
							</Button>
						}
						<span style={{ minWidth: 8, display: 'inline-block' }}/>
						<Button variant="contained" color="primary" onClick={() => history.push('/placeOrder/checkout')}>
							Checkout
						</Button>
					</div>
				</div>
				<div className={"flex"}>
					<XGrid
						classes={{
							root: classes.backgroundWhite,
						}}
						columns={localColumns}
						rows={cartLoading ? [] : shoppingCartItems}
						disableColumnSelector={true}
						disableMultipleSelection={true}
						disableSelectionOnClick={true}
						loading={cartLoading}
						components={{
							Footer: () => (
								<div>
									<Divider/>
									<div className={clsx(classes.row, classes.alignSpaceAround, classes.footerPadding)}>
										<div className={clsx(classes.column, classes.alignCenterCenter)}>
											<Typography variant="subtitle2">Unique SKUs: </Typography>
											<NumberFormat
												value={shoppingCartItems?.length ?? 0}
												decimalScale={0}
												fixedDecimalScale={true}
												thousandSeparator=','
												displayType='text'
												renderText={(formattedValue, _) => (
													<Typography variant="body2">
														{formattedValue}
													</Typography>
												)}
											/>
										</div>
										<div className={clsx(classes.column, classes.alignCenterCenter)}>
											<Typography variant="subtitle2">Total Pieces: </Typography>
											<NumberFormat
												value={shoppingCartItems?.reduce((prev, curr) => prev += curr.quantity, 0) ?? 0}
												decimalScale={0}
												fixedDecimalScale={true}
												thousandSeparator=','
												displayType='text'
												renderText={(formattedValue, _) => (
													<Typography variant="body2">
														{formattedValue}
													</Typography>
												)}
											/>
										</div>
										<div className={clsx(classes.column, classes.alignCenterCenter)}>
											<Typography variant="subtitle2">Total Amount: </Typography>
											<NumberFormat
												value={shoppingCartItems?.reduce((prev, curr) => {
													return prev += parseFloat((curr.quantity * curr.actualPrice).toFixed(2));
												}, 0.0) ?? 0}
												decimalScale={2}
												fixedDecimalScale={true}
												thousandSeparator=','
												prefix='$'
												displayType='text'
												renderText={(formattedValue, _) => (
													<Typography variant="body2">
														{formattedValue}
													</Typography>
												)}
											/>
										</div>
									</div>
								</div>
							)
						}}
					>
					</XGrid>
				</div>
			</div>
			<Dialog open={isPromptingClear}>
				<DialogContent>
					<Typography>Are you sure you want to clear your shopping cart?</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setIsPromptingClear(false)}>
						No
					</Button>
					<Button
						className={classes.redText}
						onClick={async () => {
							setIsPromptingClear(false);
							dispatch(setShoppingCartLoading(true));
							await dispatch(clearShoppingCart());
							await dispatch(getShoppingCart());
							dispatch(setShoppingCartLoading(false));
						}}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			{isPromptingQuote &&
				<ShoppingCartQuote
					onClose={() => setIsPromptingQuote(false)}
				/>
			}
		</>
	);
}

const quoteSchema = yup.object().shape({
	email: yup.string().email("Please enter a valid email").required("Email is required")
});

function ShoppingCartQuote({ onClose }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [isOpen, setIsOpen] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const currentUser = useSelector((state) => state.users.currentUser);
	const vicariousCompany = useSelector((state) => state.placeOrder.orderingFor?.id || null);

	const { handleSubmit, control } = useSmartForm({
		schema: quoteSchema,
		defaultValues: {
			email: currentUser?.email || ''
		}
	});

	function localClose() {
		setIsOpen(false);
	}

	async function submit(data) {
		try {
			setIsLoading(true);

			const response = await axios.post('/shoppingCartQuote', {
				email: data.email,
				vicariousCompany: vicariousCompany
			});

			enqueueSnackbar(response.data.message, {
				variant: response.data.succeeded ? 'success' : 'error'
			});

			if (response.data.succeeded) {
				localClose();
			}
		} catch (e) {
			setIsLoading(false);

			enqueueSnackbar('Something went wrong', {
				variant: 'error'
			});
		}
	}

	return (
		<Dialog open={isOpen} TransitionProps={{ onExited: () => onClose() }} fullWidth={true} maxWidth='xs'>
			<DialogTitle>
				Send Shopping Cart Quote
			</DialogTitle>
			<DialogContent
				className={clsx(classes.column)}
			>
				<FormTextField
					formProps={{
						name: 'email',
						control
					}}
					variant="outlined"
					label="Email Address"
					disabled={isLoading}
				/>
				<Spacer vertical={true} />
			</DialogContent>
			<DialogActions className={clsx(classes.row, classes.alignEnd)}>
				<Button
					onClick={() => localClose()}
				>
					Cancel
				</Button>
				<div className={classes.relativePosition}>
					<Button color="primary" onClick={handleSubmit(submit)} disabled={isLoading}>Send</Button>
					{isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</DialogActions>
		</Dialog>
	)
}

export default PlaceOrderShoppingCart;