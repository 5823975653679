import WotfardBold from "./wotfard-bold-webfont.woff2";
import WotfardExtraLight from "./wotfard-extralight-webfont.woff2";
import WotfardLight from "./wotfard-light-webfont.woff2";
import WotfardMedium from "./wotfard-medium-webfont.woff2";
import WotfardRegular from "./wotfard-regular-webfont.woff2";
import WotfardSemiBold from "./wotfard-semibold-webfont.woff2";
import WotfardThin from "./wotfard-thin-webfont.woff2";

const wotfardBold = {
	fontFamily: 'WotfardBold',
	fontStyle: 'bold',
	fontDisplay: 'block',
	fontWeight: '400',
	src: `
   url(${WotfardBold}) format('woff2')
  `,
	unicodeRange:
		'U+0020-00FF'
}

const wotfardExtraLight = {
	fontFamily: 'WotfardExtraLight',
	fontStyle: 'extra-light',
	fontDisplay: 'block',
	fontWeight: '400',
	src: `
   url(${WotfardExtraLight}) format('woff2')
  `,
	unicodeRange:
		'U+0020-00FF'
}

const wotfardLight = {
	fontFamily: 'WotfardLight',
	fontStyle: 'light',
	fontDisplay: 'block',
	fontWeight: '400',
	src: `
   url(${WotfardLight}) format('woff2')
  `,
	unicodeRange:
		'U+0020-00FF'
}

const wotfardMedium = {
	fontFamily: 'WotfardMedium',
	fontStyle: 'medium',
	fontDisplay: 'block',
	fontWeight: '400',
	src: `
   url(${WotfardMedium}) format('woff2')
  `,
	unicodeRange:
		'U+0020-00FF'
}

const wotfardRegular = {
	fontFamily: 'WotfardRegular',
	fontStyle: 'regular',
	fontDisplay: 'block',
	fontWeight: '400',
	src: `
   url(${WotfardRegular}) format('woff2')
  `,
	unicodeRange:
		'U+0020-00FF'
}

const wotfardSemiBold = {
	fontFamily: 'WotfardBold',
	fontStyle: 'semi-bold',
	fontDisplay: 'block',
	fontWeight: '400',
	src: `
   url(${WotfardSemiBold}) format('woff2')
  `,
	unicodeRange:
		'U+0020-00FF'
}

const wotfardThin = {
	fontFamily: 'WotfardThin',
	fontStyle: 'thin',
	fontDisplay: 'block',
	fontWeight: '400',
	src: `
   url(${WotfardThin}) format('woff2')
  `,
	unicodeRange:
		'U+0020-00FF'
}

export {wotfardBold, wotfardExtraLight, wotfardLight, wotfardMedium, wotfardRegular, wotfardSemiBold, wotfardThin};