import * as React from "react";

function SvgRotatrimFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 20" {...props}>
      <path
        d="M3.522-.014h10.9s3.6 0 4.6 2.4c.9 2.1.3 5-.3 6.5s-1.4 3.6-3.8 4.3c0 0 .5 1.5 2.9 1.3l-.6 5.5s-6.2.6-9.5-3.7l-.5 3.7h-7l2.2-14.2h-2.4l3.5-5.8m5.2 9.1c.8-.2 2.6-.1 2.9-1.9s-2.3-1.6-2.3-1.6l-.6 3.5z"
      />
    </svg>
  );
}

export default SvgRotatrimFavicon;
