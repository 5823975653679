// noinspection DuplicatedCode

import {useCallback, useEffect, useState} from "react";
import debounce from "./debounce";
import {CircularProgress, TextField} from "@material-ui/core";
import FormTextField from "../components/form-text-field";

function useAutocomplete(query, modelType) {
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	let debouncedSearch = useCallback(debounce((searchTerm) => {
			(async () => {
				setIsLoading(true);
				if (!searchTerm) {
					setSearchResults([]);
				} else {
					const results = await query(searchTerm);
					if (modelType) {
						setSearchResults(results.data ? results.data.map((itm) => {
							return new modelType(itm);
						}) : []);
					} else {
						setSearchResults(results.data);
					}
				}
				setIsLoading(false);
			})();
		}, 500),
		[]);

	useEffect(() => {
		debouncedSearch(searchTerm);
	}, [searchTerm, debouncedSearch])

	return [setSearchTerm, searchResults, isLoading];
}

function useDefaults() {
	return {
		autoHighlight: true,
		clearOnBlur: true,
		clearOnEscape: true,
		openOnFocus: true,
		selectOnFocus: true
	}
}

function autocompleteRenderInput({label, autoFocus, isLoading, margin, name, error, helperText, required, placeholder, onBlur, register, disabled}) {
	const {ref: fieldRef, ...fieldRegister} = register ? register(name) : {};

	return (params) => (
		(
			<TextField
				{...fieldRegister}
				{...params}
				label={label}
				variant="outlined"
				autoFocus={autoFocus}
				margin={margin ? margin : undefined}
				inputRef={fieldRef}
				error={error}
				helperText={helperText}
				required={required}
				placeholder={placeholder}
				onBlur={(e) => {
					if (fieldRegister.onBlur) {
						fieldRegister.onBlur(e);
					}
					if (onBlur) {
						onBlur(e);
					}
				}}
				InputProps={{
					...params.InputProps,
					endAdornment: !disabled ? (
						<>
							{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
							{params.InputProps.endAdornment}
						</>
					) : null,
				}}
			/>
		)
	);
}

function formAutocompleteRenderInput({label, autoFocus, isLoading, margin, name, error, helperText, required, placeholder, onBlur, disabled, control, FormHelperTextProps}) {

	return (params) => (
		(
			<FormTextField
				{...params}
				formProps={{
					name,
					control
				}}
				FormHelperTextProps={FormHelperTextProps}
				label={label}
				variant="outlined"
				autoFocus={autoFocus}
				disabled={disabled ? disabled : false}
				margin={margin ? margin : undefined}
				error={error}
				helperText={helperText}
				required={required}
				placeholder={placeholder}
				onBlur={(e) => {
					if (onBlur) {
						onBlur(e);
					}
				}}
				InputProps={{
					...params.InputProps,
					endAdornment: !disabled ? (
						<>
							{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
							{params.InputProps.endAdornment}
						</>
					) : null,
				}}
			/>
		)
	);
}

export { autocompleteRenderInput, formAutocompleteRenderInput, useAutocomplete, useDefaults};