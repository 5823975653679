import * as React from "react";

function SvgLogout(props) {
  return (
    <svg
      id="Logout_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 20"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Logout_svg__Group_166" transform="translate(-16.1 -21.4)">
        <path
          id="Logout_svg__Path_85"
          d="M34.7 25.8c-.2-.2-.6-.2-.8 0-.1.1-.1.2-.1.4v3.4h-6.9c-.4 0-.6.3-.6.6v2.3c0 .3.3.6.6.6h6.9v3.4c0 .3.2.5.5.6.1 0 .3 0 .4-.1l5.3-5.2c.2-.2.2-.6 0-.8l-5.3-5.2z"
        />
        <path
          id="Logout_svg__Path_86"
          d="M30.2 37.8h-9.3c-.3 0-.6-.2-.6-.5V25.5c0-.3.3-.5.6-.5h9.3c.3 0 .6-.2.6-.6V22c0-.3-.3-.6-.6-.6H17.4c-.7 0-1.2.5-1.3 1.1v17.8c.1.7.6 1.2 1.3 1.1h12.8c.3 0 .6-.2.6-.6v-2.5c0-.3-.3-.5-.6-.5z"
        />
      </g>
    </svg>
  );
}

export default SvgLogout;
