import * as yup from "yup";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {useState} from "react";
import useSmartForm from "../utils/smart-form";
import {
	Button,
	Dialog, DialogActions, DialogContent,
	makeStyles, SvgIcon, Tooltip,
	Typography
} from "@material-ui/core";
import {getStatusComparator} from "../utils/table-sort";
import NumberFormat from "react-number-format";
import HtmlTooltip from "./html-tooltip";
import {styles} from "../utils/styles";
import FormTextField from "./form-text-field";
import {asyncSetQuantityFromProductList} from "../data/slices/place-order";
import SpecialOrderLabel from "./special-order-label";
import Moment from "react-moment";
import clsx from "clsx";
import * as Icons from "../icons";
import store from "../data/store";
import { Money } from "@material-ui/icons";
import Spacer from "./spacer";
import * as React from "react";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
	...styles,
	textRight: {
		textAlign: "right"
	},
	redColor: {
		color: "#F44336"
	},
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	iconColor: {
		color: 'rgba(0, 0, 0, 0.54)'
	},
	warn: {
		color: theme.palette.warning.main
	}
}));

const quantityChangeSchema = yup.object().shape({
	quantity: yup.number().required("Quantity is required").moreThan(-1, "Quantity must be 0 or greater").integer("Quantity must be a whole number").typeError("Quantity must be a number")
});

function GetQuantityCell({gridCellParams, fromSpecial}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const productItem = gridCellParams.row;
	const {enqueueSnackbar} = useSnackbar();
	const userRole = store.getState().users.currentUser.userRole;
	const [quantitySaving, setQuantitySaving] = useState(false);
	const [hasSpecialPopup, setHasSpecialPopup] = useState(false);
	const {handleSubmit, formState: {errors, ...formState}, reset, control, getValues} = useSmartForm({
		schema: quantityChangeSchema,
		defaultValues: {
			"quantity": productItem.cartQuantity
		}
	});

	const updateQuantity = async (data) => {
		if (quantitySaving) {
			return;
		}

		setQuantitySaving(true);
		try {
			await dispatch(asyncSetQuantityFromProductList({prodId: productItem.prodId, quantity: data.quantity, fromSpecial: fromSpecial}));
			reset({quantity: data.quantity}, {
				keepErrors: false,
				keepDirty: false,
				keepValues: true,
				keepDefaultValues: false,
				keepIsSubmitted: false,
				keepTouched: false,
				keepIsValid: false,
				keepSubmitCount: false
			});
		} catch (e) {
			enqueueSnackbar("Quantity update failed!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
		} finally {
			setQuantitySaving(false);
		}
	}

	return (
		<div>
			<Spacer vertical={true} height={7.5} />
			<FormTextField
				formProps={{name: 'quantity', control: control}}
				className={"flex25"}
				variant="outlined"
				onBlur={(e) => {
					if (parseInt(getValues('quantity')) > parseInt(productItem.available) && (productItem.code === "D" || fromSpecial)) {
						const message = fromSpecial ?
							"This uses special pricing. Quantity ordered cannot exceed available for this product." :
							"This is a discontinued product. Quantity ordered cannot exceed available for this product.";

						enqueueSnackbar(message, {
							anchorOrigin: {
								horizontal: "center",
								vertical: "bottom"
							},
							variant: "warning"
						});

						reset({}, {
							keepErrors: false,
							keepDirty: false,
							keepDefaultValues: true,
							keepIsSubmitted: false,
							keepTouched: false,
							keepIsValid: false,
							keepSubmitCount: false
						});
					} else if (formState.isValid && formState.isDirty) {
						if(productItem.hasSpecial && !fromSpecial && userRole !== "EPP"){
							setHasSpecialPopup(true);
						}else{
							handleSubmit(updateQuantity)();
						}
					} else if (formState.isDirty) {
						reset({}, {
							keepErrors: false,
							keepDirty: false,
							keepDefaultValues: true,
							keepIsSubmitted: false,
							keepTouched: false,
							keepIsValid: false,
							keepSubmitCount: false
						});
					}
				}}
				onFocus={(e) => e.target.select()}
				inputProps={{
					className: classes.textRight
				}}
				helperText={null}
				disabled={quantitySaving}
			/>
			<Dialog open={hasSpecialPopup}>
				<DialogContent>
					This item is available in the Special Opportunity section.
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						variant="text"
						onClick={(e) => {
							handleSubmit(updateQuantity)();
							setHasSpecialPopup(false)
						}}
					>
						Continue Adding to Cart
					</Button>
					<Button
						style={{color: '#49c914'}}
						variant="text"
						onClick={(e) => {
							history.push(`/placeOrder/category/${productItem.category}?special=true&prodId=${productItem.id}`);
							setHasSpecialPopup(false);
						}}
					>
						View Special Opportunity
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function DescriptionHeader() {
	const classes = useStyles();

	return (
		<div className={clsx(classes.row, classes.alignStartCenter)}>
			<div className="MuiDataGrid-colCellTitle">Description</div>
			&nbsp;
			<Tooltip title="Hover over descriptions to see full descriptions" placement="top">
				<div className={clsx(classes.column, classes.alignCenterStart)}>
					<SvgIcon className={classes.iconColor} component={Icons.Information} viewBox="-2 -2 24 24"/>
				</div>
			</Tooltip>
		</div>
	);
}

const productRows = [
	// {
	// 	field: 'status',
	// 	headerName: 'Est. Availability',
	// 	headerClassName: 'grid-column-headers',
	// 	headerAlign: 'center',
	// 	sortComparator: getStatusComparator,
	// 	type: 'string',
	// 	align: 'center',
	// 	flex: 1,
	// 	renderCell: (gridCellParams) => {
	// 		const available = gridCellParams.getValue(gridCellParams.row.id, 'available');
	// 		const cartQuantity = gridCellParams.getValue(gridCellParams.row.id, 'cartQuantity');
	// 		const netAvailable = available - cartQuantity;
	//
	// 		if (gridCellParams.row.code === "S") {
	// 			return "Open Box";
	// 		} else if (netAvailable >= 0 && available !== 0) {
	// 			// return <InStockCheckbox />;
	// 			return "Now";
	// 		} else if (netAvailable < 0 || available === 0) {
	// 			if (gridCellParams.row.code === "O") {
	// 				return <SpecialOrderLabel/>;
	// 			} else if (gridCellParams.row.dueInDate) {
	// 				return (
	// 					<>
	// 						<Moment format="MM/DD/YYYY">
	// 							{gridCellParams.row.dueInDate.substr(0, gridCellParams.row.dueInDate.length - 1)}
	// 						</Moment>
	// 					</>
	// 				);
	// 			} else {
	// 				return "TBD";
	// 			}
	// 		} else {
	// 			return "TBD";
	// 		}
	// 	}
	// },
	{
		field: 'available',
		headerName: 'Available',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				value={gridCellParams.row.available < 0 ? 0 : gridCellParams.row.available}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'cartQuantity',
		headerName: 'Quantity',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<GetQuantityCell gridCellParams={gridCellParams} fromSpecial={0}/>
	},
	{
		field: 'now',
		headerName: 'Now',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) => {
			const available = gridCellParams.getValue(gridCellParams.row.id, 'available');
			const cartQuantity = gridCellParams.getValue(gridCellParams.row.id, 'cartQuantity');
			const backOrder =  cartQuantity - available;
			const now = backOrder < 0 ? cartQuantity : available;

			return <NumberFormat
				thousandSeparator={true}
				value={now}
				defaultValue={0}
				displayType="text"
			/>
		}

	},
	{
		field: 'backOrdered',
		headerName: 'Back Order',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) => {
			const available = gridCellParams.getValue(gridCellParams.row.id, 'available');
			const cartQuantity = gridCellParams.getValue(gridCellParams.row.id, 'cartQuantity');
			const backOrder =  cartQuantity - available;

			return <NumberFormat
				thousandSeparator={true}
				value={cartQuantity === 0 ? 0 : (backOrder < 0 ? 0 : backOrder)}
				defaultValue={0}
				displayType="text"
			/>

		}
	},
	{
		field: 'prodId',
		headerName: 'Product ID',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		type: 'string',
		flex: 1
	},
	{
		field: 'description',
		headerName: 'Description',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		renderHeader: (gridColumnHeaderParams) => {
			return <DescriptionHeader/>;
		},
		type: 'string',
		flex: 2,
		renderCell: (gridCellParams) => (
			<HtmlTooltip
				arrow={true}
				placement="top"
				title={
					<>
						<Typography color="inherit">Full Product Description</Typography>
						{gridCellParams.row.extraDescription ? gridCellParams.row.extraDescription : gridCellParams.row.description}
					</>
				}
			>
				<span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{gridCellParams.row.extraDescription ? gridCellParams.row.extraDescription : gridCellParams.row.description}</span>
			</HtmlTooltip>
		)
	},
	{
		field: 'dealerNet',
		headerName: 'Dealer Net',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.dealerNet}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'actualPrice',
		headerName: 'Your Cost',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.actualPrice}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'extended',
		headerName: 'Extended',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.actualPrice * gridCellParams.row.cartQuantity}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'actions',
		headerName: 'Opportunity',
		headerClassName: 'grid-column-headers',
		type: 'string',
		align: 'center',
		flex: 1,
		headerAlign: 'center',
		renderCell: (gridCellParams) => {
			if(gridCellParams.row.hasSpecial) {
				return (
				<Tooltip title="Special Opportunity pricing available">
					<div>
						<Spacer height={14} vertical={true} />
						<SvgIcon component={Icons.SpecialOpportunities} viewBox="-2 -2 24 24" style={{ color: '#49c914' }}/>
					</div>
				</Tooltip>
				)
			}else{
				return <span/>
			}
		}
	}
];

export function getProductRows() {
	const userRole = store.getState().users.currentUser.userRole;

	if (userRole === "EPP") {
		let retColumns = productRows.filter((itm) => {
			return itm.field !== "available";
		});

		retColumns.splice(-1, 1)

		retColumns = retColumns.map((itm) => {
			if (itm.field === "dealerNet") {
				return {
					field: 'dealerNet',
					headerName: 'MAP/Street',
					headerClassName: 'grid-column-headers',
					type: 'number',
					flex: 1,
					valueGetter: (gridValueGetterParams) => {
						if (gridValueGetterParams.row.map > 0) {
							return gridValueGetterParams.row.map;
						} else if (gridValueGetterParams.row.street > 0) {
							return gridValueGetterParams.row.street;
						} else {
							return "N/A";
						}
					},
					renderCell: (gridCellParams) => {
						if (gridCellParams.value !== "N/A") {
							return <NumberFormat
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix="$"
								value={gridCellParams.value}
								defaultValue={0}
								displayType="text"
							/>
						} else {
							return gridCellParams.value;
						}
					}
				}
			} else {
				return itm;
			}
		})

		return retColumns;
	} else {
		return productRows;
	}
}

const specialProductRows = [
	// {
	// 	field: 'status',
	// 	headerName: 'Est. Availability',
	// 	headerClassName: 'grid-column-headers',
	// 	headerAlign: 'center',
	// 	sortComparator: getStatusComparator,
	// 	type: 'string',
	// 	align: 'center',
	// 	flex: 1,
	// 	renderCell: (gridCellParams) => {
	// 		const available = gridCellParams.getValue(gridCellParams.row.id, 'available');
	// 		const cartQuantity = gridCellParams.getValue(gridCellParams.row.id, 'cartQuantity');
	// 		const netAvailable = available - cartQuantity;
	//
	// 		if (gridCellParams.row.code === "S") {
	// 			return "Open Box";
	// 		} else if (netAvailable >= 0 && available !== 0) {
	// 			// return <InStockCheckbox />;
	// 			return "Now";
	// 		} else if (netAvailable < 0 || available === 0) {
	// 			if (gridCellParams.row.code === "O") {
	// 				return <SpecialOrderLabel/>;
	// 			} else if (gridCellParams.row.dueInDate) {
	// 				return (
	// 					<>
	// 						<Moment format="MM/DD/YYYY">
	// 							{gridCellParams.row.dueInDate.substr(0, gridCellParams.row.dueInDate.length - 1)}
	// 						</Moment>
	// 					</>
	// 				);
	// 			} else {
	// 				return "TBD";
	// 			}
	// 		} else {
	// 			return "TBD";
	// 		}
	// 	}
	// },
	{
		field: 'cartQuantity',
		headerName: 'Quantity',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<GetQuantityCell gridCellParams={gridCellParams} fromSpecial={1}/>
	},
	{
		field: 'available',
		headerName: 'Available',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				value={gridCellParams.row.available < 0 ? 0 : gridCellParams.row.available}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'prodId',
		headerName: 'Product ID',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		type: 'string',
		flex: 1
	},
	{
		field: 'description',
		headerName: 'Description',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		renderHeader: (gridColumnHeaderParams) => {
			return <DescriptionHeader/>;
		},
		type: 'string',
		flex: 2,
		renderCell: (gridCellParams) => (
			<HtmlTooltip
				arrow={true}
				placement="top"
				title={
					<>
						<Typography color="inherit">Full Product Description</Typography>
						{gridCellParams.row.extraDescription ? gridCellParams.row.extraDescription : gridCellParams.row.description}
					</>
				}
			>
				<span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{gridCellParams.row.extraDescription ? gridCellParams.row.extraDescription : gridCellParams.row.description}</span>
			</HtmlTooltip>
		)
	},
	{
		field: 'dealerNet',
		headerName: 'Dealer Net',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.dealerNet}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'actualPrice',
		headerName: 'Special Price',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.actualPrice}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'extended',
		headerName: 'Extended',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.actualPrice * gridCellParams.row.cartQuantity}
				defaultValue={0}
				displayType="text"
			/>
	}
];

export function getSpecialProductRows() {
	const userRole = store.getState().users.currentUser.userRole;

	if (userRole === "EPP") {
		let retColumns = specialProductRows.filter((itm) => {
			return itm.field !== "available";
		});

		retColumns = retColumns.map((itm) => {
			if (itm.field === "dealerNet") {
				return {
					field: 'dealerNet',
					headerName: 'MAP/Street',
					headerClassName: 'grid-column-headers',
					type: 'number',
					flex: 1,
					valueGetter: (gridValueGetterParams) => {
						if (gridValueGetterParams.row.map > 0) {
							return gridValueGetterParams.row.map;
						} else if (gridValueGetterParams.row.street > 0) {
							return gridValueGetterParams.row.street;
						} else {
							return "N/A";
						}
					},
					renderCell: (gridCellParams) => {
						if (gridCellParams.value !== "N/A") {
							return <NumberFormat
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix="$"
								value={gridCellParams.value}
								defaultValue={0}
								displayType="text"
							/>
						} else {
							return gridCellParams.value;
						}
					}
				}
			} else {
				return itm;
			}
		})

		return retColumns;
	} else {
		return specialProductRows;
	}
}