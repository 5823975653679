import {createSlice} from "@reduxjs/toolkit";


const initialState = {};

export const utilsSlice = createSlice({
	name: 'utils',
	initialState: initialState,
	reducers: {

	}
});

// export const {} = utilsSlice.actions;

export default utilsSlice.reducer;