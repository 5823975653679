import * as React from "react";

function SvgOrderByBrand(props) {
  return (
    <svg
      id="Order-by-Brand_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 16 20"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Order-by-Brand_svg__Group_309" transform="translate(-16.667 -40)">
        <path
          id="Order-by-Brand_svg__Path_204"
          d="M29.7 40h-10c-1.7 0-3 1.3-3 3v14c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3V43c0-1.7-1.4-3-3-3zm-10 2h10c.6 0 1 .4 1 1v3h-12v-3c0-.6.4-1 1-1zm10 16h-10c-.6 0-1-.4-1-1v-9h12v9c0 .6-.5 1-1 1z"
        />
        <path
          id="Order-by-Brand_svg__Path_205"
          d="M27.7 50h-6c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.5-1-1-1z"
        />
        <path
          id="Order-by-Brand_svg__Path_206"
          d="M27.7 54h-6c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.5-1-1-1z"
        />
        <path
          id="Order-by-Brand_svg__Path_207"
          d="M21.7 45h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1s.4 1 1 1z"
        />
      </g>
    </svg>
  );
}

export default SvgOrderByBrand;
