import * as React from "react";

function SvgSwitchDealer(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 20" {...props}>
      <path
        d="M21.2 4.1h-3.3C17.9 1.6 16 0 13.6 0H9.4C7 0 5.1 1.6 5.1 4.1H1.8C.8 4.1 0 5 0 6v12.2c0 1 .8 1.8 1.8 1.8h19.4c1 0 1.8-.8 1.8-1.8V6c0-1-.8-1.9-1.8-1.9zM9.1 2.2h4.8c.8 0 1.4.7 1.4 1.6v.3H7.7v-.3c0-.9.6-1.6 1.4-1.6zM6.6 13.6l-.8-.9c-.1-.1-.1-.3 0-.5l3.7-3.9-1.3-1.2c-.1-.1-.1-.1-.1-.2 0-.2.1-.3.3-.3h3.8c.1 0 .3.2.3.3v4c0 .1-.1.2-.1.2-.1.1-.3.1-.4 0l-1.2-1.3L7 13.6c-.1.1-.3.1-.4 0zm10.7-1.5L13.5 16l1.2 1.3c.1.1.1.1.1.2 0 .2-.1.3-.3.3h-3.8c-.1 0-.3-.2-.3-.3v-4c0-.1.1-.2.1-.2.1-.1.3-.1.4 0l1.2 1.3 3.7-3.9c.1-.1.3-.1.4 0l.8.9c.4.2.4.4.3.5z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgSwitchDealer;
