import * as React from "react";

function SvgPricesAndProductInformation(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.5 40" {...props}>
      <path
        d="M14.8 40c2.5-.7 15.8-14.1 17.2-17.2 1.4-3 2.4-12.1-.2-14.6-1.1-1.1-3.3-1.5-5.8-1.6.1-2.8 2.5-5 5.4-4.9 1.6.1 3.2.9 4.1 2.3 1.2 1.8 2.1 5.3-.5 12-.2.5.1 1 .5 1.1.4.2.9-.1 1.1-.5 3-7.3 1.9-11.4.4-13.5-1.3-1.9-3.4-3-5.6-3.1-4.1 0-7.1 3.2-7.1 7.5 0 2.1.7 4.1 2.1 5.7.3.4.9.4 1.3.1.4-.3.4-.9.1-1.3-.5-.5-.8-1-1.1-1.7 1.4-.3 2.8.6 3.1 2 .3 1.4-.6 2.8-2 3.1-1.3.3-2.6-.4-3-1.7-1.4-1.8-2.1-4-2.1-6.2v-.7c-1.9.2-3.7.6-5.5 1.3C14.2 9.4.7 22.7 0 25.2c-.3 1.1 2.7 5.3 6.1 8.7s7.6 6.4 8.7 6.1zm-6-13.3L8.5 25c-.1-.7.5-1.3 1.2-1.4.6 0 1.2.4 1.4 1l.2 1.7c.1.6.4 1.1.8 1.6.2.2.5.4.8.6.4.3 1 .3 1.5 0 .4-.4.5-1 .3-1.6L13.6 23c-.5-1.5 0-3.3 1.2-4.3 1.2-.9 2.9-1 4.2-.3.2.1.3.2.5.3l.7-.7c.6-.5 1.4-.4 1.8.2.4.5.4 1.2 0 1.7l-.7.7c.4.6.6 1.3.7 2l.2 1.7c.1.7-.5 1.3-1.2 1.4-.6 0-1.2-.4-1.4-1l-.2-1.7c-.2-.9-.8-1.7-1.6-2.2-.4-.3-1-.3-1.5 0-.4.4-.5 1-.3 1.6l1.1 3.9c.5 1.5 0 3.3-1.2 4.3-1.2.9-2.9 1-4.2.3-.2-.1-.3-.2-.5-.3l-.7.7c-.5.5-1.3.5-1.8 0s-.5-1.3 0-1.8l.7-.7c-.3-.7-.5-1.4-.6-2.1z"
      />
    </svg>
  );
}

export default SvgPricesAndProductInformation;
