import * as React from "react";

function SvgTrash(props) {
  return (
    <svg
      id="Trash_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 15 20"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M10.6 2.9V1.4C10.6.6 10 0 9.2 0H5.8C5 0 4.4.6 4.4 1.4v1.5C0 3.2 0 4.1 0 4.1v2h15v-2s0-.9-4.4-1.2zM6 1.6h3v1.2H6V1.6zM1 7.3L2.3 20h10.4L14 7.3H1zM4.4 18c-.4 0-.6-.3-.7-.6L3.3 10c0-.3.2-.6.6-.6.3 0 .6.2.7.6l.4 7.3c0 .4-.2.6-.6.7zm3.7-.7c0 .3-.3.6-.6.6s-.6-.3-.6-.6V10c0-.3.3-.6.6-.6s.6.3.6.6v7.3zm3.1.1c0 .3-.3.6-.6.6s-.6-.3-.6-.6l.4-7.3c0-.3.3-.6.7-.6.3 0 .6.3.6.6l-.5 7.3z"
      />
    </svg>
  );
}

export default SvgTrash;
