import * as React from "react";

function SvgBrandInformation(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm.3 4.4c.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4zm-.5 11.2c-1.4 0-1.8-.8-1.6-1.9l.7-4.4c-.5.1-1 .2-1.4.5.2-1 1.2-1.8 2.3-1.9 1.4 0 1.8.8 1.6 1.9l-.7 4.4c.5-.1 1-.2 1.4-.5-.2 1.1-1.1 1.9-2.3 1.9z"
      />
    </svg>
  );
}

export default SvgBrandInformation;
