import * as React from "react";

function SvgBurgerMenu(props) {
  return (
    <svg
      id="Burger-Menu_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 18 20"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M18 3c0 .3-.2.5-.5.5H.5C.2 3.5 0 3.3 0 3V.5C0 .2.2 0 .5 0h17c.3 0 .5.2.5.5V3zM18 11.2c0 .3-.2.5-.5.5H.5c-.3 0-.5-.2-.5-.5V8.8c0-.3.2-.5.5-.5h17c.3 0 .5.2.5.5v2.4zM18 19.5c0 .3-.2.5-.5.5H.5c-.3 0-.5-.2-.5-.5V17c0-.3.2-.5.5-.5h17c.3 0 .5.2.5.5v2.5z"
      />
    </svg>
  );
}

export default SvgBurgerMenu;