import * as React from "react";

function SvgOrderHistory(props) {
  return (
    <svg
      id="Order-History_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 22 20"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Order-History_svg__Group_305" transform="translate(0 -8.333)">
        <path
          id="Order-History_svg__Path_89"
          d="M11 12.3c-.6 0-1 .5-1 1v6.1c0 .6.5 1 1 1h4c.6 0 1-.5 1-1 0-.6-.5-1-1-1h-3v-5c0-.6-.5-1.1-1-1.1z"
        />
        <path
          id="Order-History_svg__Path_90"
          d="M19.1 11.3C15.2 7.4 8.9 7.4 5 11.3c-1.9 1.8-3 4.4-3 7H0l3 4 3-4H4c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8c-2.1 0-4.2-.8-5.7-2.3-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4 3.9 3.9 10.2 3.9 14.1 0s4-10.2.1-14.1z"
        />
      </g>
    </svg>
  );
}

export default SvgOrderHistory;
