import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Category from "../models/category";
import {resetAction} from "../../utils/common-actions";

const axios = require('axios');

const getAllCategories = createAsyncThunk(
	'categories/getAllCategories',
	async (arg, thunkAPI) => {
		try {
			const response = await axios.get('/categories');
			return response.data.map((itm) => {
				return Category.fromJson(itm);
			}).sort((a,b) => a.name.localeCompare(b.name));
		} catch (e) {
			return [];
		}
	}
);

export { getAllCategories };

const initialState = {
	categories: [],
	categoriesMap: {}
};

export const categoriesSlice = createSlice({
	name: 'categories',
	initialState: initialState,
	reducers: {
		setCategories: (state, action) => {
			state.categories = action.payload;
		}
	},
	extraReducers: {
		[getAllCategories.fulfilled]: (state, action) => {
			const mappedCategories = action.payload.reduce((p, c) => {
				p[c.number] = c;
				return p;
			}, {});

			state.categories = action.payload;
			state.categoriesMap = mappedCategories;
		},
		[resetAction]: (state, action) => {
			return initialState;
		}
	}
});

export const {setCategories} = categoriesSlice.actions;

export default categoriesSlice.reducer;