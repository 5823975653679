import {contentNoPaddingRef} from "../utils/utilities";
import Iframe from "react-iframe";
import clsx from "clsx";
import {makeStyles, Portal} from "@material-ui/core";
import {styles} from "../utils/styles";

const useStyles = makeStyles((theme) => ({
	...styles
}));

function ShippingAndDeliveryInfo() {
	const classes = useStyles();

	return (
		<Portal container={contentNoPaddingRef.current}>
			<Iframe
				url="https://cnc-api.zmags.com/view/lite/6154c434a3dd511e9634b9fc"
				className={clsx(classes.iFrame, "flex")}
			/>
		</Portal>
	);
}

export default ShippingAndDeliveryInfo;