import * as React from "react";

function SvgServiceSupport(props) {
	return (
		<svg
			id="Service-Support_svg__Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x={0}
			y={0}
			viewBox="0 0 31 20"
			xmlSpace="preserve"
			width="1em"
			height="1em"
			{...props}
		>
			<circle cx={5.2} cy={17} r={3} />
			<circle cx={23.6} cy={17} r={3} />
			<path
				d="M19.9 0H2.2C1 0 0 1 0 2.2v13.3c0 .7.3 1.3.7 1.7V17c0-2.5 2-4.4 4.4-4.4s4.4 2 4.4 4.4c0 .3 0 .5-.1.7h9.7c0-.2-.1-.5-.1-.7 0-1.9 1.2-3.6 3-4.2V2.2C22.1 1 21.2 0 19.9 0zm-8.6 12.1S6.4 9.6 6.4 6.4c0-1.4 1.2-2.5 2.5-2.5 1.6 0 2.3 1.2 2.3 1.2s.7-1.2 2.3-1.2c1.4 0 2.5 1.2 2.5 2.5.1 3.2-4.7 5.7-4.7 5.7zM23.6 4.4h3.7c1 0 1.9.4 2.6 1.1S31 7.2 31 8.1v.7h-3.7c-.4 0-.7.3-.7.7 0 .4.3.7.7.7H31v1.5h-2.2c-.4 0-.7.3-.7.7s.3.7.7.7H31v2.2c0 .6-.2 1.2-.6 1.6-.4.4-1 .7-1.6.7H28c0-.2.1-.5.1-.7 0-2.5-2-4.4-4.4-4.4V4.4z"
			/>
		</svg>
	);
}

export default SvgServiceSupport;
