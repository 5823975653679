import Model from "../model";

class Setting extends Model {
	announcement;

	constructor({id, announcement}) {
		super({id: id});

		this.announcement = announcement;
	}

	static fromJson(map) {
		return new Setting({
			id: map.id,
			announcement: map.announcement
		});
	}

	toJson() {
		return {
			id: this.id,
			announcement: this.announcement
		}
	}
}

export default Setting;