import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import User from "../models/user";
import {resetAction} from "../../utils/common-actions";

const axios = require('axios');

const getUsersForManagement = createAsyncThunk(
	'users/getUsersForManagement',
	async (arg, thunkAPI) => {
		try {
			const response = await axios.get('/getUsersForManagement');
			return response.data.map((itm) => {
				return User.fromJson(itm);
			});
		} catch (e) {
			return [];
		}
	}
);

const getUserVendorData = createAsyncThunk(
	'users/getUserVendorData',
	async ({ userId }, thunkAPI) => {
		try {
			const response = await axios.get('/getUserVendorData', {
				params: {
					userId
				}
			});
			return {
				vendorProfile: response.data.vendorProfile,
				achProfile: response.data.achProfile,
				emailProfile: response.data.emailProfile
			}
		} catch (e) {
			return null;
		}
	}
);

const createUserVendorData = createAsyncThunk(
	'users/createUserVendorData',
	async ({data}, thunkAPI) => {
		try {
			return await axios.post('/createUserVendorData', {
				...data
			});
		} catch (e) {
			return null;
		}
	}
);

const editUserVendorData = createAsyncThunk(
	'users/editUserVendorData',
	async ({data}, thunkAPI) => {
		try {
			return await axios.post('/editUserVendorData', {
				...data
			});
		} catch (e) {
			return null;
		}
	}
);

export { getUsersForManagement, getUserVendorData, createUserVendorData, editUserVendorData};

const initialState = {
	users: [],
	currentUser: null,
	currentUserToken: null,
	currentUserVendorProfile: null,
	currentUserVendorAchProfile: null,
	currentUserVendorEmailProfile: null,
	companyFilter: null,
	roles: [
		{id: 5, title: "Administrator", internal: false, rank: 600},
		{id: 4, title: "Brand Manager", internal: true, rank: 800},
		{id: 6, title: "Buyer", internal: false, rank: 500},
		{id: 8, title: "Contact", internal: false, rank: 0},
		{id: 9, title: "EPP", internal: false, rank: 0},
		{id: 2, title: "Sales Manager", internal: true, rank: 900},
		{id: 3, title: "Salesman", internal: true, rank: 700},
		{id: 1, title: "Super Administrator", internal: true, rank: 1000},
		{id: 7, title: "User", internal: false, rank: 400}
	]
};

export const usersSlice = createSlice({
	name: 'users',
	initialState: initialState,
	reducers: {
		setUsers: (state, action) => {
			state.searchResults = action.payload;
		},
		setCurrentUser: (state, action) => {
			state.currentUser = action.payload;
		},
		setCurrentUserToken: (state, action) => {
			state.currentUserToken = action.payload;
		},
		setRoles: (state, action) => {
			state.roles = action.payload;
		},
		addUser: (state, action) => {
			state.users = [...state.users, action.payload];
		},
		removeUser: (state, action) => {
			const indexToRemove = state.users.indexOf(action.payload);

			state.users.splice(indexToRemove, 1);
		},
		updateUser: (state, action) => {

		},
		setCompanyFilter: (state, action) => {
			state.companyFilter = action.payload;
		}
	},
	extraReducers: {
		[getUsersForManagement.fulfilled]: (state, action) => {
			state.users = action.payload;
		},
		[getUserVendorData.fulfilled]: (state, action) => {
			state.currentUserVendorProfile = action.payload.vendorProfile;
			state.currentUserVendorAchProfile = action.payload.achProfile;
			state.currentUserVendorEmailProfile = action.payload.emailProfile;
		},
		[createUserVendorData.fulfilled]: (state, action) => {
			const {succeeded, vendorProfile, achProfile, emailProfile} = action.payload.data;
			if(succeeded){
				state.currentUserVendorProfile = vendorProfile;
				state.currentUserVendorAchProfile = achProfile;
				state.currentUserVendorEmailProfile = emailProfile;
			}
		},
		[editUserVendorData.fulfilled]: (state, action) => {
			const {succeeded, vendorProfile, achProfile, emailProfile} = action.payload.data;
			if(succeeded){
				state.currentUserVendorProfile = vendorProfile;
				state.currentUserVendorAchProfile = achProfile;
				state.currentUserVendorEmailProfile = emailProfile;
			}
		},
		[resetAction]: (state, action) => {
			return initialState;
		}
	}
});

export const {setUsers, setCurrentUser, setCurrentUserToken, setRoles, addUser, removeUser, updateUser, setCompanyFilter} = usersSlice.actions;

export default usersSlice.reducer;