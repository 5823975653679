import * as React from "react";
const SvgWnwh = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        xmlSpace="preserve"
        {...props}
    >
        <path
            d="m16.1 5.9-3.2 8.7h-.3L10 8.4l-2.5 6.2h-.3L3.9 5.9h2l1.5 4.5 1.8-4.5h1.5l1.9 4.5 1.5-4.5h2zM20 0H0v20h20V0z"
        />
    </svg>
);
export default SvgWnwh;
