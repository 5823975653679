import clsx from "clsx";
import {
	Button,
	Card,
	CardContent, CircularProgress,
	Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
	FormControlLabel, FormLabel, IconButton,
	makeStyles,
	Radio, RadioGroup,
	Slide, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip,
	Typography
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { styles } from "../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useSmartForm from "../utils/smart-form";
import * as yup from "yup";
import moment from "moment";
import FormDatePicker from "../components/form-date-picker";
import Spacer from "../components/spacer";
import FormTextField from "../components/form-text-field";
import {
	filterHistory as filterHistoryThunk,
	getOrderDetails as getOrderDetailsThunk,
	getOrderTracking as getOrderTrackingDetailsThunk
} from "../data/slices/place-order";
import Moment from "react-moment";
import * as React from "react";
import TrackingNumberDisplay from "../components/tracking-number-display";
import * as Icons from "../icons";
import axios from "axios";
import store from "../data/store";
import { useSnackbar } from "notistack";
import { DataGridPro } from "@mui/x-data-grid-pro";

const useStyles = makeStyles((theme) => ({
	...styles,
	header: {
		margin: 12,
		height: 40
	},
	headerInput: {
		boxSizing: 'content-box'
	},
	noTopMargin: {
		marginTop: 0
	},
	radioGroup: {
		marginTop: -4,
		width: 250
	},
	cursorPointer: {
		'& .MuiDataGrid-row': {
			cursor: 'pointer'
		}
	},
	dialogContent: {
		width: 1250
	},
	tableColumnHeaders: {
		color: "#246dff",
		fontFamily: "'WotfardMedium', sans-serif",
		fontSize: 14
	},
	tableEvenRow: {
		backgroundColor: "#FFF"
	},
	tableOddRow: {
		backgroundColor: "#F3F3F4"
	},
	filterTextContainer: {
		marginTop: -4
	},
	filterText: {
		marginTop: 6,
		marginLeft: 10
	},
	filterInputsMargin: {
		paddingBottom: 12
	},
	error: {
		color: theme.palette.error.main
	}
}));

const orderHistoryColumns = [
	{
		field: 'orderDate',
		headerName: 'Order Date',
		headerClassName: 'grid-column-headers',
		type: 'date',
		flex: 1,
		renderCell: (gridCellParams) => (
			<Moment format="MM/DD/YYYY">
				{gridCellParams.value}
			</Moment>
		)
	},
	{
		field: 'orderNumber',
		headerName: 'Order Number',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1,
		valueGetter: (gridCellParams) => {
			const record = gridCellParams.row;
			return record['orderNumber'] + '-' + record['boNumber'];
		}
	},
	{
		field: 'invoiceNumber',
		headerName: 'Invoice Number',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1
	},
	{
		field: 'invoiceDate',
		headerName: 'Invoice Date',
		headerClassName: 'grid-column-headers',
		type: 'date',
		flex: 1,
		renderCell: (gridCellParams) => (
			gridCellParams.value ?
				<Moment format="MM/DD/YYYY">
					{gridCellParams.value}
				</Moment>
				: null
		)
	},
	{
		field: 'poNumber',
		headerName: 'PO Number',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1
	},
	{
		field: 'shipVia',
		headerName: 'Ship Via',
		headerClassName: 'grid-column-headers',
		type: 'string',
		flex: 1,
		valueFormatter: (gridValueFormatterParams) =>
			gridValueFormatterParams.value ?
				gridValueFormatterParams.value.substr(0, 1) === "." ?
					"" :
					gridValueFormatterParams.value :
				""
	},
	{
		field: 'total',
		headerName: 'Order Total',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) => (
			<NumberFormat
				thousandSeparator=","
				decimalScale={2}
				fixedDecimalScale={2}
				prefix="$"
				value={gridCellParams.value}
				displayType="text"
			/>
		)
	}
];

const checkoutSchema = yup.object().shape({
	startDate: yup.date('Must be a valid date').typeError('Must be a valid date').nullable().required('This is required'),
	endDate: yup.date('Must be a valid date').typeError('Must be a valid date').nullable().required('This is required').max(moment(`${moment().format('YYYY-MM-DD')} 24:00:00.000`).toDate(), `Must be ${moment().format('MM/DD/YYYY')} or less`)
});

const defaultState = {
	orderType: 'all',
	orderNumber: '',
	poNumber: '',
	startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
	endDate: moment().format('YYYY-MM-DD'),
	productId: '',
	page: 0
};


function PlaceOrderHistory() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [page, setPage] = useState(0);
	const [orderType, setOrderType] = useState('all');
	const [selectedModels, setSelectedModels] = useState([]);
	const [isEmailing, setIsEmailing] = useState(false);
	const [filterState, setFilterState] = useState({});
	const [filterDialogOpen, setFilterDialogOpen] = useState(false);
	const [viewDetails, setViewDetails] = useState(null);
	const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);
	const orderHistory = useSelector((state) => state.placeOrder.orderHistory);
	const orderHistoryTotalCount = useSelector((state) => state.placeOrder.orderHistoryTotalCount);
	const { enqueueSnackbar } = useSnackbar();

	async function submitFilter(data) {
		if (isLoading) {
			return;
		}
		setFilterState({ ...data, orderType: orderType });
		setFilterDialogOpen(false);
		setIsLoading(true);
		setPage(0);
		setSelectedModels([]);
		await dispatch(filterHistoryThunk({
			orderType: orderType,
			orderNumber: data.orderNumber,
			poNumber: data.poNumber,
			startDate: data.startDate,
			endDate: data.endDate,
			productId: data.productId,
			page: 0
		}));
		setIsLoading(false);
	}

	async function clearFilter() {
		if (isLoading) {
			return;
		}
		setFilterState(defaultState);
		setOrderType('all');
		setFilterDialogOpen(false);
		setIsLoading(true);
		setPage(0);
		setSelectedModels([]);
		await dispatch(filterHistoryThunk(defaultState));
		setIsLoading(false);
	}

	useEffect(() => {
		(async function () {
			setIsLoading(true)
			await dispatch(filterHistoryThunk(defaultState));
			setFilterState(defaultState);
			setIsLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderingFor]);

	async function triggerReFilter(overrideProps) {
		setIsLoading(true);
		setPage(0);
		setSelectedModels([]);

		const newFilterState = { ...filterState, ...overrideProps };
		setFilterState(newFilterState);

		await dispatch(filterHistoryThunk({
			orderType: newFilterState.orderType,
			orderNumber: newFilterState.orderNumber,
			poNumber: newFilterState.poNumber,
			startDate: newFilterState.startDate,
			endDate: newFilterState.endDate,
			productId: newFilterState.productId,
			page: 0
		}));

		setIsLoading(false);
	}

	async function handlePageChange(params) {
		setIsLoading(true);
		setPage(params.page);
		setSelectedModels([]);

		await dispatch(filterHistoryThunk({
			orderType: filterState.orderType,
			orderNumber: filterState.orderNumber,
			poNumber: filterState.poNumber,
			startDate: filterState.startDate,
			endDate: filterState.endDate,
			productId: filterState.productId,
			page: params.page
		}));

		setIsLoading(false);
	}

	// noinspection JSIgnoredPromiseFromCall
	return (
		<>
			<Card
				raised={true}
				className={clsx(classes.column, "flex")}
			>
				<CardContent className={clsx(classes.column, "flex")}>
					<div className={clsx(classes.row, classes.alignSpaceBetween)}>
						<Typography variant="h6">
							Order History
							{orderingFor && <span> - <span
								className={classes.capitalize}>{orderingFor.name.toLowerCase()}</span> - {orderingFor.id}</span>}
						</Typography>
					</div>
					<br/>
					<div className={"flex"}>
						<DataGridPro
							className={classes.cursorPointer}
							classes={{
								root: 'NoSelectAll'
							}}
							columns={orderHistoryColumns}
							rows={isLoading ? [] : orderHistory}
							disableColumnSelector={true}
							disableMultipleSelection={true}
							disableSelectionOnClick={true}
							loading={isLoading}
							checkboxSelection={true}
							selectionModel={selectedModels}
							onSelectionModelChange={(newSelectionModel) => setSelectedModels(newSelectionModel)}
							isRowSelectable={(params) => params.row.invoiceNumber !== "CANCEL"}
							components={{
								Toolbar: () => (
									<div className={clsx(classes.row, classes.header, classes.alignSpaceBetweenCenter)}>
										<div className={clsx(classes.row, "flex")}>
											<FormControl className={clsx(classes.row, classes.radioGroup)} component="fieldset">
												<FormLabel className={classes?.label} component="legend">Order Type</FormLabel>
												<RadioGroup
													name='orderType'
													value={orderType}
													onChange={(e) => {
														setOrderType(e.target.value);
														// noinspection JSIgnoredPromiseFromCall
														triggerReFilter({ orderType: e.target.value });
													}}
												>
													<FormControlLabel
														control={<Radio color="primary" size="small"/>}
														label="All"
														labelPlacement="start"
														value="all"
													/>
													<FormControlLabel
														control={<Radio color="primary" size="small"/>}
														label="Archived"
														labelPlacement="start"
														value="archived"
													/>
													<FormControlLabel
														control={<Radio color="primary" size="small"/>}
														label="Open"
														labelPlacement="start"
														value="open"
													/>
												</RadioGroup>
											</FormControl>
											{filterState.startDate ?
												<>
													<Spacer width={12}/>
													<FormControl className={clsx(classes.column, classes.filterTextContainer)}>
														<FormLabel className={classes?.label} component="legend">Start Date</FormLabel>
														<Typography className={classes.filterText}>
															<Moment format="MM/DD/YYYY">
																{filterState.startDate}
															</Moment>
														</Typography>
													</FormControl>
												</> :
												null
											}
											{filterState.endDate ?
												<>
													<Spacer width={12}/>
													<FormControl className={clsx(classes.column, classes.filterTextContainer)}>
														<FormLabel className={classes?.label} component="legend">End Date</FormLabel>
														<Typography className={classes.filterText}>
															<Moment format="MM/DD/YYYY">
																{filterState.endDate}
															</Moment>
														</Typography>
													</FormControl>
												</> :
												null
											}
											{filterState.orderNumber ?
												<>
													<Spacer width={12}/>
													<FormControl className={clsx(classes.column, classes.filterTextContainer)}>
														<FormLabel className={classes?.label} component="legend">Order Number</FormLabel>
														<Typography className={classes.filterText}>
															{filterState.orderNumber}
														</Typography>
													</FormControl>
												</> :
												null
											}
											{filterState.poNumber ?
												<>
													<Spacer width={12}/>
													<FormControl className={clsx(classes.column, classes.filterTextContainer)}>
														<FormLabel className={classes?.label} component="legend">PO Number</FormLabel>
														<Typography className={classes.filterText}>
															{filterState.poNumber}
														</Typography>
													</FormControl>
												</> :
												null
											}
											{filterState.productId ?
												<>
													<Spacer width={12}/>
													<FormControl className={clsx(classes.column, classes.filterTextContainer)}>
														<FormLabel className={classes?.label} component="legend">Product ID</FormLabel>
														<Typography className={classes.filterText}>
															{filterState.productId}
														</Typography>
													</FormControl>
												</> :
												null
											}
										</div>
										<div>
											<Tooltip title="Clear Filter" placement="top">
												<IconButton
													className={classes.error}
													onClick={clearFilter}
												>
													<SvgIcon component={Icons.ClearFilter} viewBox="-5 -2.5 24 24"/>
												</IconButton>
											</Tooltip>
											<Tooltip title="Filter Orders" placement="top">
												<IconButton
													color='primary'
													onClick={() => {
														setFilterDialogOpen(true);
													}}
												>
													<SvgIcon component={Icons.Filter} viewBox="-5.5 -4 24 24"/>
												</IconButton>
											</Tooltip>
											<Tooltip title="Email Selected" placement="top">
												<IconButton
													color='primary'
													onClick={async () => {
														if (isEmailing) return;

														if (!!!selectedModels || selectedModels.length === 0) {
															enqueueSnackbar('Please select one or more orders to be emailed', {
																anchorOrigin: {
																	vertical: 'bottom',
																	horizontal: 'center'
																},
																variant: 'info'
															});
														} else {
															setIsEmailing(true);
															const response = await axios.post('/printHistory', {
																items: selectedModels.map((itm) => {
																	const isArchived = !!orderHistory[itm].invoiceNumber;

																	return {
																		number: isArchived ? orderHistory[itm].invoiceNumber : orderHistory[itm].orderNumber,
																		isArchived: isArchived
																	}
																}),
																vicariousCompany: store.getState().placeOrder.orderingFor?.id
															});

															enqueueSnackbar(response.data, {
																variant: response.status === 200 ? 'success' : 'error',
																anchorOrigin: {
																	vertical: 'bottom',
																	horizontal: 'center'
																}
															});

															setSelectedModels([]);
															setIsEmailing(false);
														}
													}}
												>
													<SvgIcon component={Icons.Email} viewBox="0 -2 30 20"/>
												</IconButton>
											</Tooltip>
											<Tooltip title="Email Open Order Report" placement="top">
												<IconButton
													color='primary'
													onClick={async () => {
														if (isEmailing) return;

														setIsEmailing(true);
														const response = await axios.post('/openOrderReport', {
															vicariousCompany: store.getState().placeOrder.orderingFor?.id,
															startDate: filterState.startDate,
															endDate: filterState.endDate
														});

														enqueueSnackbar(response.data, {
															variant: response.status === 200 ? 'success' : 'error',
															anchorOrigin: {
																vertical: 'bottom',
																horizontal: 'center'
															}
														});

														setSelectedModels([]);
														setIsEmailing(false);
													}}
												>
													<SvgIcon component={Icons.OrderHistory} viewBox="-1 -2 24 24"/>
												</IconButton>
											</Tooltip>
										</div>
									</div>
								)
							}}
							pagination={true}
							paginationMode="server"
							pageSize={1000}
							page={page}
							onPageChange={handlePageChange}
							hideFooter={!orderHistoryTotalCount || orderHistoryTotalCount <= 1000}
							rowCount={orderHistoryTotalCount}
							rowsPerPageOptions={[]}
							onRowClick={(gridRowParams, muiEvent) => {
								setViewDetails(gridRowParams.row);
								setViewDetailsOpen(true);
							}}
						>
						</DataGridPro>
					</div>
				</CardContent>
			</Card>
			<FilterTools
				isOpen={filterDialogOpen}
				close={() => setFilterDialogOpen(false)}
				clearFilter={clearFilter}
				submitFilter={submitFilter}
				filterState={{ ...filterState, orderType: orderType }}
			/>
			<ViewDetails
				isOpen={viewDetailsOpen}
				order={viewDetails}
				close={() => setViewDetailsOpen(false)}
			/>
		</>
	)
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function FilterTools({ isOpen, close, clearFilter, submitFilter, filterState }) {
	const classes = useStyles();
	const { handleSubmit, control, reset } = useSmartForm({
		schema: checkoutSchema,
		defaultValues: {
			"orderType": 'all',
			"startDate": moment().subtract(1, 'years'),
			"endDate": moment(),
			"orderNumber": '',
			"poNumber": '',
			"productId": ''
		}
	});

	useEffect(() => {
		if (isOpen) {
			reset({
				"orderType": filterState.orderType,
				"startDate": filterState.startDate,
				"endDate": filterState.endDate,
				"orderNumber": filterState.orderNumber,
				"poNumber": filterState.poNumber,
				"productId": filterState.productId
			}, {
				keepDefaultValues: false
			});
		}
		// eslint-disable-next-line
	}, [isOpen]);

	return (
		<Dialog
			open={isOpen}
			TransitionComponent={Transition}
			maxWidth="lg"
		>
			<DialogTitle>Filter Order History</DialogTitle>
			<DialogContent className={clsx(classes.row, classes.filterInputsMargin)}>
				<FormDatePicker
					className={"flex"}
					formProps={{ name: "startDate", control: control }}
					label="Order Start Date"
					size="small"
					InputProps={{
						classes: {
							input: classes.headerInput
						}
					}}
				/>
				<Spacer width={12}/>
				<FormDatePicker
					className={"flex"}
					formProps={{ name: "endDate", control: control }}
					label="Order End Date"
					size="small"
					disableFuture={true}
					InputProps={{
						classes: {
							input: classes.headerInput
						}
					}}
				/>
				<Spacer width={12}/>
				<FormTextField
					className={"flex"}
					formProps={{
						name: 'orderNumber',
						control
					}}
					label="Order Number"
					variant="outlined"
					margin="normal"
					size="small"
					classes={{
						root: classes.noTopMargin
					}}
					InputProps={{
						classes: {
							input: classes.headerInput
						}
					}}
				/>
				<Spacer width={12}/>
				<FormTextField
					className={"flex"}
					formProps={{
						name: 'poNumber',
						control
					}}
					label="PO Number"
					variant="outlined"
					margin="normal"
					size="small"
					classes={{
						root: classes.noTopMargin
					}}
					InputProps={{
						classes: {
							input: classes.headerInput
						}
					}}
				/>
				<Spacer width={12}/>
				<FormTextField
					className={"flex"}
					formProps={{
						name: 'productId',
						control
					}}
					label="Product ID"
					variant="outlined"
					margin="normal"
					size="small"
					classes={{
						root: classes.noTopMargin
					}}
					InputProps={{
						classes: {
							input: classes.headerInput
						}
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={close}>Close</Button>
				<Button color="primary" onClick={clearFilter}>Clear</Button>
				<Button color="primary" onClick={handleSubmit(submitFilter)}>Filter</Button>
			</DialogActions>
		</Dialog>
	);
}

function ViewDetails({ isOpen, order, close }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const orderItems = useSelector((state) => state.placeOrder.orderHistoryDetails);
	const filteredOrderedItems = orderItems.filter((item) => {
		const hasProdId = item["prodId"] ? item["prodId"].trim() !== "" : false;
		const hasDescription = item["description"] ? item["description"].trim() !== "" : false;

		return hasProdId || hasDescription;
	});
	const orderTracking = useSelector((state) => state.placeOrder.orderHistoryTracking);

	useEffect(() => {
		if (!order || !isOpen) return;

		(async function () {
			setIsLoading(true);
			const orderDetails = dispatch(getOrderDetailsThunk(order));
			const orderTrackingNumbers = dispatch(getOrderTrackingDetailsThunk(order));
			await orderDetails;
			await orderTrackingNumbers;
			setIsLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<Dialog
			open={isOpen}
			TransitionComponent={Transition}
			maxWidth="xl"
		>
			<DialogTitle>Order Details
				{order?.orderNumber ? ` - Order #${order.orderNumber}-${order.boNumber}` : null}
				{order?.invoiceNumber ? ` - Invoice #${order.invoiceNumber}` : null}</DialogTitle>
			<DialogContent className={clsx(classes.dialogContent, classes.column)}>
				{isLoading ?
					<div className={clsx(classes.row, classes.alignCenterCenter)}>
						<CircularProgress/>
					</div> :
					<>
						<TableContainer className="flex">
							<Table
								stickyHeader={true}
							>
								<TableHead>
									<TableRow>
										{!order.archived &&
											<TableCell
												className={classes.tableColumnHeaders}
												align="left">
												Est. Avail.
											</TableCell>
										}
										<TableCell
											className={classes.tableColumnHeaders}
											align="left"
										>
											Product ID
										</TableCell>
										<TableCell
											className={classes.tableColumnHeaders}
											align="left"
										>
											Description
										</TableCell>
										<TableCell
											className={classes.tableColumnHeaders}
											align="right"
										>
											Ordered
										</TableCell>
										{!order.archived &&
											<TableCell
												className={classes.tableColumnHeaders}
												align="right"
											>
												Now
											</TableCell>
										}
										{!!order.archived &&
											<TableCell
												className={classes.tableColumnHeaders}
												align="right"
											>
												Shipped
											</TableCell>
										}
										<TableCell
											className={classes.tableColumnHeaders}
											align="right"
										>
											Back Ordered
										</TableCell>
										<TableCell
											className={classes.tableColumnHeaders}
											align="right"
										>
											Dealer Net
										</TableCell>
										<TableCell
											className={classes.tableColumnHeaders}
											align="right"
										>
											Discount
										</TableCell>
										<TableCell
											className={classes.tableColumnHeaders}
											align="right"
										>
											Price
										</TableCell>
										<TableCell
											className={classes.tableColumnHeaders}
											align="right"
										>
											Amount
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredOrderedItems.map((item, idx) => (
										<TableRow
											className={idx % 2 === 0 ? classes.tableEvenRow : classes.tableOddRow}
											key={idx}
										>
											{!order.archived &&
												<TableCell
													align="left"
												>
													{parseInt(item["pickQty"]) > 0  ?
														"" :
														!!item.dueInDate ?
														<Moment format="MM/DD/YYYY">
															{item.dueInDate.substr(0, item.dueInDate.length - 1)}
														</Moment> : "TBD"
													}
												</TableCell>}
											<TableCell
												align="left"
											>
												{item["prodId"]}
											</TableCell>
											<TableCell
												align="left"
											>
												{item["description"]}
											</TableCell>
											<TableCell
												align="right"
											>
												{item["invoiceNumber"] === "REJECT" ?
													"Rejected" :
													<NumberFormat
														thousandSeparator={true}
														decimalScale={0}
														fixedDecimalScale={true}
														value={item["qtyOrdered"]}
														defaultValue={0}
														displayType="text"
													/>
												}
											</TableCell>
											{!order.archived  &&
												<TableCell
												align="right"
												>
													<NumberFormat
														thousandSeparator={true}
														decimalScale={0}
														fixedDecimalScale={true}
														value={item["pickQty"]}
														defaultValue={0}
														displayType="text"
													/>
												</TableCell>}
											{!!order.archived &&
												<TableCell
													align="right"
												>
													{item["invoiceNumber"] === "REJECT" ?
														"Rejected" :
														<NumberFormat
															thousandSeparator={true}
															decimalScale={0}
															fixedDecimalScale={true}
															value={item["qtyShipped"]}
															defaultValue={0}
															displayType="text"
														/>}
												</TableCell>
											}
											<TableCell
												align="right"
											>
												{item["invoiceNumber"] === "REJECT" ?
													"Rejected" :
													<NumberFormat
														thousandSeparator={true}
														decimalScale={0}
														fixedDecimalScale={true}
														value={!!order.archived ? item["qtyBackOrdered"] : (parseInt(item["qtyOrdered"]) - parseInt(item["pickQty"]))}
														defaultValue={0}
														displayType="text"
													/>
												}
											</TableCell>
											<TableCell
												align="right"
											>
												<NumberFormat
													thousandSeparator={true}
													decimalScale={2}
													fixedDecimalScale={true}
													prefix="$"
													value={item["dealerNet"]}
													defaultValue={0}
													displayType="text"
												/>
											</TableCell>
											<TableCell
												align="right"
											>
												<NumberFormat
													thousandSeparator={true}
													decimalScale={2}
													fixedDecimalScale={true}
													prefix="$"
													value={parseFloat(item["dealerNet"]) !== 0 ? parseFloat(item["dealerNet"]) - parseFloat(item["unitSellAmt"]) : 0}
													defaultValue={0}
													displayType="text"
												/>
											</TableCell>
											<TableCell
												align="right"
											>
												<NumberFormat
													thousandSeparator={true}
													decimalScale={2}
													fixedDecimalScale={true}
													prefix="$"
													value={item["unitSellAmt"]}
													defaultValue={0}
													displayType="text"
												/>
											</TableCell>
											<TableCell
												align="right"
											>
												<NumberFormat
													thousandSeparator={true}
													decimalScale={2}
													fixedDecimalScale={true}
													prefix="$"
													value={item["extendedAmt"]}
													defaultValue={0}
													displayType="text"
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						{orderTracking.length > 0 ?
							<>
								<br/>
								<div className={classes.row}>
									&nbsp;
									&nbsp;
									{orderTracking.map((itm, index) => <TrackingNumberDisplay key={index} trackingNumber={itm}
																																						oneBefore={index !== 0}/>)}
								</div>
							</> :
							null
						}
					</>
				}
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={close}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}

export default PlaceOrderHistory;