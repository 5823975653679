import {useDispatch, useSelector} from "react-redux";
import {
	setPromptOrderingFor
} from "../data/slices/place-order";
import {Route, Switch} from "react-router-dom";
import {useEffect} from "react";

function PlaceOrderRoot(props) {
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.users.currentUser);
	const userRoles = useSelector((state) => state.users.roles);
	const currentUserRole = userRoles.find((role) => role.title === currentUser.userRole);
	const isInternal = currentUserRole.internal;
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);

	useEffect(() => {
		if (isInternal) {
			if (!orderingFor) {
				dispatch(setPromptOrderingFor(true));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInternal]);

	return (
		<Switch>
			{!orderingFor && isInternal ? null : props.routes.map((r, i) => {
				return (
					<Route key={i} path={r.path}>
						<r.component {...props}/>
					</Route>
				)
			})}
		</Switch>
	);
}

export default PlaceOrderRoot;