import {useDispatch, useSelector} from "react-redux";
import {
	CircularProgress,
	makeStyles,
	Typography
} from "@material-ui/core";
import {useEffect, useState} from "react";
import {styles} from "../utils/styles";
import clsx from "clsx";
import {getSettings} from "../data/slices/settings";


const useStyles = makeStyles({
	...styles,

});

function PlaceOrderHome() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const announcement = useSelector((state) => state.settings.settings.announcement);

	useEffect(() => {
		(async function () {
			await dispatch(getSettings());
			setIsLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		isLoading ? (
			<div className={clsx(classes.column, classes.alignCenterCenter, "flex")}>
				<CircularProgress/>
			</div>
		) : (
			<>
				<span dangerouslySetInnerHTML={{__html: announcement}}/>
				<br/>
				<br/>
				<Typography variant="subtitle2">Instructions:</Typography>
				<Typography variant="body2" style={{fontSize: 12, paddingLeft: 8}}>
					If you know the catalog number of the item(s) you wish to order, click on Quick Order.<br/>
					To research an item before purchasing, select a product line from the list on the left. If required, select a sub
					category to view items.<br/>
					View items you have placed on your order by clicking Shopping Cart or the Shopping Cart Icon in the top
					right.
				</Typography>
			</>
		)
	)
}

export default PlaceOrderHome;