import * as React from "react";
const SvgOffsite = (props) => (
    <svg
        id="Offsite_svg__Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 20 20"
        xmlSpace="preserve"
        {...props}
    >
        <path
            className="Offsite_svg__st0"
            d="M16 12.5V16H4V4h3.5V0H3C1.3 0 0 1.3 0 3v14c0 1.6 1.3 3 3 3h14c1.6 0 3-1.4 3-3v-4.5h-4z"
        />
        <path
            id="Offsite_svg__Path_85_00000100376330274558829350000008866442125063012241_"
            className="Offsite_svg__st0"
            d="M12 .1c-.3 0-.6.3-.6.6 0 .1.1.2.2.4L14 3.5 9.2 8.3c-.3.3-.2.6 0 .8l1.6 1.6c.2.2.6.2.8 0l4.9-4.9 2.4 2.4c.2.2.5.2.8.1.1-.1.2-.2.2-.4L20 .6c0-.3-.3-.6-.6-.6L12 .1z"
        />
    </svg>
);
export default SvgOffsite;
