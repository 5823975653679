import * as React from "react";

function SvgPlaceAnOrder(props) {
  return (
    <svg
      id="Place-an-Order_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 19.5 20"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M19.3 3c-.1-.2-.4-.3-.6-.3h-14l-.3-1.1C4.1.6 3.3 0 2.3 0H.8C.3 0 0 .4 0 .8s.4.8.8.8h1.5c.2 0 .4.2.5.4l.4 1.6L5 11.9l-1 1.9c-.2.4-.2 1 0 1.4.3.4.7.7 1.2.7h11.3c.4 0 .8-.4.8-.8s-.4-.8-.8-.8h-11l.8-1.4H17c.4 0 .7-.3.8-.6l1.7-8.5c0-.3 0-.6-.2-.8zM13.7 16.7c-.9 0-1.7.7-1.7 1.7 0 .9.7 1.6 1.7 1.6.9 0 1.6-.7 1.6-1.6.1-1-.7-1.7-1.6-1.7zM7.9 16.7c-.9 0-1.7.7-1.7 1.7.1.9.8 1.6 1.7 1.6.9 0 1.6-.7 1.6-1.6.1-1-.7-1.7-1.6-1.7z"
      />
    </svg>
  );
}

export default SvgPlaceAnOrder;
