import * as React from "react";

function SvgClearFilter(props) {
  return (
    <svg
      id="Clear-Filter_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 14 19"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M6.5 4.4c3.8 0 5.3.9 5.4 1.1-.1 0-.1.1-.1.1-.5.4-2 .8-4 .9L10 9.9l2.6-3.5c.3-.2.4-.5.4-.9 0-1.3-2.7-2.2-6.5-2.2h-1l.7 1.1h.3zM13.8 17.5L2.4.4C2.1 0 1.5-.1 1.1.2.6.5.5 1.1.8 1.5l1.5 2.2C.9 4.2 0 4.8 0 5.5c0 .3.1.6.4.8v.1L4.6 12v6.4c0 .3.2.6.6.6 0 0 3.2 0 3.2-2.9v-3.2l3.8 5.7c.2.3.5.4.8.4.2 0 .4-.1.5-.2.5-.3.6-.9.3-1.3zM1.1 5.5c0-.1.6-.5 1.9-.8l1.2 1.8c-2.2-.2-3.1-.8-3.1-1z"
      />
    </svg>
  );
}

export default SvgClearFilter;
