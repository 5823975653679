import {
	Button,
	Card,
	CardActions,
	CardContent, CircularProgress, Dialog, DialogTitle,
	IconButton,
	InputAdornment, List, ListItem, ListItemText,
	makeStyles, SvgIcon,
	TextField
} from "@material-ui/core";
import {styles} from "../utils/styles";
import clsx from "clsx";
import {useState} from "react";
import * as Icons from "../icons";
import loginLogo from '../assets/MAC-Group_Logo.svg';
import * as yup from "yup";
import useSmartForm from "../utils/smart-form";
import {useHistory} from "react-router";
import {setCurrentUserToken, setCurrentUser} from "../data/slices/users";
import {useDispatch} from "react-redux";
import User from "../data/models/user";
import {getAllBrands} from "../data/slices/brands";
import {getAllCategories} from "../data/slices/categories";
import {useSnackbar} from "notistack";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
	...styles,
	loginCard: {
		width: 550
	},
	forgotPasswordText: {
		color: theme.palette.primary.main
	},
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	logo: {
		height: 'auto',
		width: 300
	}
}));

const loginSchema = yup.object().shape({
	email: yup.string().email("Please enter a valid email").required("Email is required"),
	password: yup.string().required("Password is required")
});

function Login() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [promptSepSelect, setPromptSepSelect] = useState(false);
	const [cachedAuthResponse, setCacheAuthResponse] = useState(null);
	const {register, handleSubmit, formState: {errors}} = useSmartForm({schema: loginSchema});
	const {ref: emailRef, ...emailRegister} = register('email');
	const {ref: passwordRef, ...passwordRegister} = register('password');
	const { enqueueSnackbar } = useSnackbar();

	const postLoginRoutine = async (responseData) => {
		const user = new User(responseData.user);
		dispatch(setCurrentUser(user));
		sessionStorage.setItem('currentUserToken', responseData.token);
		sessionStorage.setItem('currentUser', JSON.stringify(user));
		let getBrandsAction = dispatch(getAllBrands());
		let getCategoriesAction = dispatch(getAllCategories());
		await getBrandsAction;
		await getCategoriesAction;
	};

	const onLogin = async (data) => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		try {
			const response = await axios.post('/getToken', {
				email: data.email,
				password: data.password
			});
			if (response.data.authenticated) {
				dispatch(setCurrentUserToken(response.data.token));

				if (response.data.user?.sepUser) {
					setIsLoading(false);
					setCacheAuthResponse(response.data);
					setPromptSepSelect(true);
				} else {
					await postLoginRoutine(response.data);
					history.push("/home");
				}


			} else {
				enqueueSnackbar("The email or password is incorrect!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					variant: "error"
				});

				setIsLoading(false);
			}
		} catch (e) {
			enqueueSnackbar("Login attempt failed!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});

			setIsLoading(false);
		}

	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const changeLoginMode = async (mode) => {
		if (mode === 0) {
			await postLoginRoutine(cachedAuthResponse);
			history.push("/home");
		} else {
			const response = await axios.post("/changeLoginMethod", {
				desiredRole: "EPP"
			});

			if (response.data.succeeded === true) {
				const updatedAuthResponse = {...cachedAuthResponse};
				updatedAuthResponse.user.userRole = "EPP";
				await postLoginRoutine(updatedAuthResponse);
				history.push("/home");
			} else {
				enqueueSnackbar("You are not authorized for this role!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					variant: "error"
				});
			}
		}
	}

	return (
		<>
			<div className={clsx(classes.column, classes.alignCenterCenter, classes.fullHeight)}>
				<Card className={classes.loginCard} raised={true}>
					<CardContent className={classes.column}>
						<div className={clsx(classes.row, classes.alignCenter)}>
							<img className={classes.logo} src={loginLogo} alt="Logo"/>
						</div>
						<TextField {...emailRegister} variant="outlined" label="Email" autoComplete="email" autoFocus={true} type="email"
											 required={true} margin="normal" inputRef={emailRef} error={!!errors.email}
											 helperText={errors.email?.message}/>
						<TextField {...passwordRegister} variant="outlined" label="Password" autoComplete="password"
											 type={showPassword ? "text" : "password"} required={true} margin="normal" inputRef={passwordRef}
											 error={!!errors.password} helperText={errors.password?.message}
											 InputProps={{
												 endAdornment: <InputAdornment position="end">
													 <IconButton
														 aria-label="toggle password visibility"
														 onClick={toggleShowPassword}
													 >
														 {showPassword ? <SvgIcon component={Icons.Hide} viewBox="0 0 27 20"/> : <SvgIcon component={Icons.Show} viewBox="0 0 27.5 17.5"/>}
													 </IconButton>
												 </InputAdornment>
											 }}
											 onKeyPress={(e) => {
												 if (e.key === "Enter") {
													 handleSubmit(onLogin)();
												 }
											 }}
						/>
					</CardContent>
					<CardActions className={clsx(classes.row, classes.alignEnd)}>
						<Button variant="outlined" onClick={() => history.push("/forgotPassword")}>
							<span className={classes.forgotPasswordText}>Forgot Password</span>
						</Button>
						<div className={classes.relativePosition}>
							<Button variant="contained" color="primary" onClick={handleSubmit(onLogin)} disabled={isLoading}>Login</Button>
							{isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
						</div>
					</CardActions>
				</Card>
			</div>
			<Dialog open={promptSepSelect}>
				<DialogTitle children="Select User Mode"/>
				<List>
					<ListItem
						button={true}
						onClick={() => changeLoginMode(0)}
					>
						<ListItemText>
							Log in as {cachedAuthResponse?.user?.userRole}
						</ListItemText>
					</ListItem>
					<ListItem
						button={true}
						onClick={() => changeLoginMode(1)}
					>
						<ListItemText primary="Log in as EPP User"/>
					</ListItem>
				</List>
			</Dialog>
		</>
	);
}

export default Login;