import {makeStyles} from "@material-ui/core";
import {styles} from "../utils/styles";

const useStyles = makeStyles((theme) => ({
	...styles,
	specialOrder: {
		fontWeight: "bold",
		color: theme.palette.warning.main
	}
}));

function SpecialOrderLabel() {
	const classes = useStyles();

	return (
		<span className={classes.specialOrder}>S/O</span>
	);
}

export default SpecialOrderLabel;