import * as React from "react";

function SvgHeliopanFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 20" {...props}>
      <path
        d="M14 9l-2.3 9.9c-.1.6-.7 1.1-1.4 1.1h-.2c-.8 0-1.4-.6-1.4-1.4v-.3l.8-3.6s.7-3.5 1-4.7c.2-1.2 0-1.8-.4-2.2s-1.6-.3-2.9.3c-1 .6-1.7 1.4-2.3 2.4L3 18.9c-.1.6-.7 1.1-1.3 1.1h-.3C.6 20 0 19.4 0 18.6v-.3L3.7 1.1C3.9.5 4.4 0 5.1 0h.4c.8 0 1.4.6 1.4 1.4v.3L5.9 6c.6-.4 1.3-.7 1.9-.9 1.3-.5 2.8-.5 4 .1 1.6.6 2.4 2.2 2.2 3.8z"
      />
    </svg>
  );
}

export default SvgHeliopanFavicon;
