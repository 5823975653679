import * as React from "react";
const SvgPriceListsAndPromotions = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        xmlSpace="preserve"
        {...props}
    >
        <path
            d="M7.8 20c1.2-.3 7.9-7 8.6-8.6.7-1.5 1.2-6-.1-7.3-.5-.5-1.6-.7-2.9-.8 0-1.4 1.2-2.5 2.7-2.4.8 0 1.6.4 2 1.1.6.9 1 2.6-.2 6-.1.2 0 .5.2.5.2.1.4 0 .5-.2 1.5-3.6.9-5.7.2-6.7-.6-1-1.6-1.6-2.7-1.6-2 0-3.5 1.6-3.5 3.7 0 1 .3 2 1 2.8.1.2.4.2.6 0 .2-.1.2-.4 0-.6-.2-.2-.4-.5-.5-.8.7-.1 1.4.3 1.5 1 .1.7-.3 1.4-1 1.5-.6.1-1.3-.2-1.5-.8-.7-.9-1-2-1-3.1v-.3c-.9.1-1.8.3-2.7.6-1.5.6-8.2 7.3-8.6 8.5-.1.5 1.3 2.6 3 4.3s3.8 3.3 4.4 3.2zm-3-6.7-.1-.8c0-.3.2-.6.6-.7.3 0 .6.2.7.5v.8c0 .3.2.5.4.8.1.1.2.2.4.3.2.1.5.1.7 0 .2-.2.2-.5.1-.8l-.5-1.9c-.2-.7 0-1.6.6-2.1.6-.4 1.4-.5 2.1-.1.1 0 .1.1.2.1l.5-.4c.3-.2.7-.2.9.1.2.2.2.6 0 .8l-.4.4c.2.3.3.6.3 1l.1.8c0 .3-.2.6-.6.7-.3 0-.6-.2-.7-.5l-.1-.8c0-.5-.3-.9-.7-1.1-.2-.1-.5-.1-.7 0-.2.2-.2.5-.1.8l.5 1.9c.2.7 0 1.6-.6 2.1-.6.4-1.4.5-2.1.1-.1 0-.1-.1-.2-.1l-.3.3c-.2.2-.6.2-.9 0-.2-.2-.2-.6 0-.9l.3-.3c-.3-.3-.4-.6-.4-1z"
        />
    </svg>
);
export default SvgPriceListsAndPromotions;
