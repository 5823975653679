import * as React from "react";

function SvgManageUsers(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 20" {...props}>
      <path
        d="M17.2 6.9c-1.9 0-3.5-1.6-3.5-3.5S15.3 0 17.2 0s3.5 1.6 3.5 3.5-1.6 3.4-3.5 3.4zm-5.3-2.4C11.9 2 9.9 0 7.5 0 5 0 3.1 2 3.1 4.5s2 4.4 4.4 4.4c2.4 0 4.4-2 4.4-4.4zm10.4 11.1c.4 0 .8-.4.7-.8-.5-3.4-1.9-6.8-5.8-6.8-2.2 0-3.6 1.1-4.5 2.6.6.6 1.2 1.2 1.7 2 .5.9 1 1.9 1.3 3h6.6zM0 18.7c-.1.7.4 1.3 1.1 1.3h12.7c.7 0 1.2-.6 1.1-1.3-.6-4.3-2.5-8.5-7.5-8.5S.7 14.4 0 18.7z"
      />
    </svg>
  );
}

export default SvgManageUsers;
