import * as React from "react";

function SvgToyoFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20" {...props}>
      <path
        d="M6.3 7.5v5c0 1.3 0 2.5-.1 3.8 0 1.2-.1 2.5-.2 3.7.4 0 .9-.1 1.4-.1.5 0 1 0 1.4.1-.1-1.2-.1-2.4-.2-3.7s-.1-2.5-.1-3.8v-5c0-2 0-4 .1-6h1.6c.6 0 1.1 0 1.7.1.6 0 1.1.1 1.6.1s1 .1 1.4.1c-.1-.3-.1-.6-.1-.9 0-.3.1-.6.1-.9-1.3 0-2.5.1-3.8.1H3.5C2.5.1 1.3 0 0 0c.1.3.1.6.1.9 0 .3-.1.6-.1.9.4-.1.9-.1 1.4-.1.5 0 1-.1 1.6-.1.6 0 1.1 0 1.7-.1h1.6v6"
      />
    </svg>
  );
}

export default SvgToyoFavicon;
