import * as React from "react";

function SvgNovoflexFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" {...props}>
      <path
        d="M10.914 0v13h-.1l-4.5-13h-6.3v20h4.9V6.2h.1l4.8 13.8h6.2V0h-5.1z"
      />
    </svg>
  );
}

export default SvgNovoflexFavicon;
