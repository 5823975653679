import * as React from "react";

function SvgArrowUp(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 8.5" {...props}>
      <path
        d="M1.383 8.529l-1.4-1.4 6.8-7.1h1.4l6.8 7.1-1.3 1.4-6.2-6.5z"
      />
    </svg>
  );
}

export default SvgArrowUp;
