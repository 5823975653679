import * as React from "react";

function SvgSave(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M20 2.3v17.1c0 .3-.3.6-.6.6H.6c-.3 0-.6-.3-.6-.6V.6C0 .3.3 0 .6 0h3.1v9.3h12.5V0h1.4L20 2.3zm-8.7 5.6h2.5c.3 0 .5-.2.5-.5V1.8c0-.3-.2-.5-.5-.5h-2.5c-.3 0-.5.2-.5.5v5.7c0 .2.3.4.5.4z"
      />
    </svg>
  );
}

export default SvgSave;
