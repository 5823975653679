import * as React from "react";

function SvgGepeFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M9.7 20C4.2 19.8-.2 15.2 0 9.7.2 4.4 4.4.2 9.7 0c2.9-.1 5.6 1.1 7.5 3.3l.1.1-2.2 2.2-.1-.2c-1.2-1.6-3.1-2.5-5.2-2.5-3.5 0-6.7 3.3-6.7 7-.1 3.8 3 7 6.8 7.1 2.8 0 5.3-1.7 6.3-4.3H7.5V9.8H20v.2c0 5.5-4.5 9.9-10 9.9-.1.1-.2.1-.3.1z"
      />
    </svg>
  );
}

export default SvgGepeFavicon;
