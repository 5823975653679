import clsx from "clsx";
import {Card, CardContent,
	makeStyles,
	Typography
} from "@material-ui/core";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import {styles} from "../utils/styles";
import {useDispatch, useSelector} from "react-redux";
import { useEffect, useMemo, useState } from "react";
import {
	asyncGetAllCategoryProductsSpecial,
	getShoppingCart,
	setShoppingCartLoading
} from "../data/slices/place-order";
import { getSpecialProductRows } from "../components/product-row";
import * as React from "react";

const useStyles = makeStyles({
	...styles,
	firstRowMargin: {
		marginTop: 36,
		marginBottom: 36
	},
	headerFontSize: {
		fontSize: 28
	},
	filterButton: {
		width: 125,
		marginRight: 25
	},
	specialColor: {
		color: 'red'
	},
	iconLabelWrapper: {
		flexDirection: "row-reverse"
	}
});

function SpecialOpportunity(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [productsLoading, setProductsLoading] = useState(true);
	const [specialLocalColumns, setSpecialLocalColumns] = useState(getSpecialProductRows());
	const gridApiRef = useGridApiRef();
	const specialProducts = useSelector((state) => state.placeOrder.allSpecialProducts);
	const shoppingCart = useSelector((state) => state.placeOrder.shoppingCart);
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);

	const createSpecialProductsWithQuantities = (specialProducts, shoppingCart) => {
		const shoppingCartAsMap = shoppingCart.reduce((p, c) => {
			p[`${c.prodId}-${c.specialProduct}`] = c;
			return p;
		}, {});

		return specialProducts.map((p) => {
			const copy = {...p};
			const cartMatch = shoppingCartAsMap[`${p.prodId}-${p.specialProduct}`];

			copy.cartQuantity = (cartMatch && (cartMatch.specialProduct === p.specialProduct)) ? cartMatch.quantity : 0;

			return copy;
		});
	};

	const specialProductsWithQuantities = useMemo(() => createSpecialProductsWithQuantities(specialProducts, shoppingCart), [specialProducts, shoppingCart]);

	const loadProducts = async function () {
		if (!productsLoading) {
			setProductsLoading(true);
		}
		dispatch(setShoppingCartLoading(true));
		const gettingCart = dispatch(getShoppingCart());
		const gettingProductsSpecial = dispatch(asyncGetAllCategoryProductsSpecial({}));
		await gettingCart;
		await gettingProductsSpecial;
		dispatch(setShoppingCartLoading(false));
		setProductsLoading(false);
	};

	useEffect(() => {
		(async () => {
			await loadProducts();
		})();
	}, []);

	return (
		<>
			<div className={clsx(classes.column, "flex")}>
				<div className={clsx(classes.row, classes.alignSpaceBetweenCenter, classes.firstRowMargin)}>
					<Typography className={classes.headerFontSize} variant="h4">
						&nbsp;
						&nbsp;
						&nbsp;
						Special Opportunity Products
						{orderingFor && <span> - <span
							className={classes.capitalize}>{orderingFor.name.toLowerCase()}</span> - {orderingFor.id}</span>}
					</Typography>
				</div>
				<Card className={clsx(classes.column, "flex")}>
					<CardContent className={clsx(classes.column, "flex")}>
						<DataGridPro
							// className='flex'
							columns={specialLocalColumns}
							apiRef={gridApiRef}
							rows={productsLoading ? [] : specialProductsWithQuantities}
							disableColumnSelector={true}
							disableMultipleSelection={true}
							disableSelectionOnClick={true}
							hideFooter={true}
							getRowId={(row) => row.id}
							loading={productsLoading}
						>
						</DataGridPro>
					</CardContent>
				</Card>
			</div>
		</>
	);
}



export default SpecialOpportunity;