import * as React from "react";

function SvgInformation(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm.6 3.3c.9 0 1.7.7 1.7 1.7s-.7 1.7-1.7 1.7S9 5.9 9 5s.7-1.7 1.6-1.7zm-.8 13.5c-1.7 0-2.1-1-2-2.3l.8-5.2c-.6.1-1.1.3-1.7.6.3-1.3 1.5-2.3 2.9-2.3 1.7 0 2.1 1 2 2.3l-.8 5.2c.6-.1 1.2-.3 1.7-.6-.4 1.3-1.5 2.3-2.9 2.3z"
      />
    </svg>
  );
}

export default SvgInformation;
