import './App.css';
import {Switch, Route} from 'react-router-dom';
import {Redirect, useHistory, useLocation} from "react-router";
import {Login, Home} from "./screens/index";
import {
	AppBar, Badge, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
	IconButton,
	makeStyles, SvgIcon, Switch as MuiSwitch,
	Toolbar, Typography, withStyles,
} from "@material-ui/core";
import {styles} from "./utils/styles";
import clsx from 'clsx';
import {useEffect, useRef, useState} from "react";
import {RotateTransitionIcons} from "./utils/animations";
import PlaceOrderRoot from "./screens/place-order-root";
import PlaceOrderHome from "./screens/place-order-home";
import AccountSettings from "./screens/account-settings";
import Sidebar from "./components/sidebar";
import ManageBrands from "./screens/manage-brands";
import ManageUsers from "./screens/manage-users";
import CreateUser from "./screens/create-user";
import EditUser from "./screens/edit-user";
import {useDispatch, useSelector} from "react-redux";
import ManageAnnouncement from "./screens/manage-announcement";
import PlaceQuickOrder from "./screens/place-quick-order";
import {setAppBarRef, setContentNoPaddingRef} from "./utils/utilities";
import {setCurrentUser, setCurrentUserToken} from "./data/slices/users";
import {getAllBrands} from "./data/slices/brands";
import {getAllCategories} from "./data/slices/categories";
import {
	clearShoppingCart, getShoppingCart,
	setOrderingFor,
	setPromptOrderingFor,
	setShoppingCartLoading
} from "./data/slices/place-order";
import store from "./data/store";
import {resetAction} from "./utils/common-actions";
import PlaceOrderShoppingCart from "./screens/place-order-shopping-cart";
import PlaceOrder from "./screens/place-order";
import ForgotPassword from "./screens/forgot-password";
import ResetPassword from "./screens/reset-password";
import PlaceOrderCheckout from "./screens/place-order-checkout";
import PlaceOrderHistory from "./screens/place-order-history";
import loginLogo from "./assets/MAC-Group_Logo.svg";
import {Autocomplete} from "@material-ui/lab";
import {autocompleteRenderInput, useDefaults} from "./utils/autocomplete";
import {getAllCompanies} from "./utils/common-requests";
import * as Icons from "./icons";
import Iframe from "react-iframe";
import BrandInformation from "./screens/brand-information";
import ShippingAndDeliveryInfo from "./screens/shipping-and-delivery-info";
import PhotoEDUReportSales from "./screens/photo-edu-report-sales";
import SpecialOpportunity from "./screens/place-order-special-opportunity";
import PhotoEduRebates from "./screens/photo-edu-rebates";
import Rewards from "./screens/rewards";
import RewardsHistory from "./screens/rewards-history";

function getAuthToken() {
	return store.getState().users.currentUserToken;
}

const axios = require('axios');
axios.defaults.baseURL = "https://portal.macgroupus.com/api";
// axios.defaults.baseURL = "http://localhost:8080";
axios.interceptors.request.use((config) => {
	config.headers['Authorization'] = getAuthToken();
	return config;
});

const routes = [
	{
		path: "/home",
		component: Home,
		title: "Home"
	},
	{
		path: "/login",
		component: Login,
		title: "Login"
	},
	{
		path: "/forgotPassword",
		component: ForgotPassword,
		title: "Forgot Password",
		noAuthenticationNeeded: true
	},
	{
		path: "/resetPassword",
		component: ResetPassword,
		title: "Reset Password",
		noAuthenticationNeeded: true
	},
	{
		path: "/placeOrder",
		component: PlaceOrderRoot,
		title: "Place Order Home",
		routes: [
			{
				path: "/placeOrder/home",
				component: PlaceOrderHome,
				title: "Place Order Home"
			},
			{
				path: "/placeOrder/quick",
				component: PlaceQuickOrder,
				title: "Quick Order"
			},
			{
				path: "/placeOrder/shoppingCart",
				component: PlaceOrderShoppingCart,
				title: "Shopping Cart"
			},
			{
				path: "/placeOrder/category/:id",
				basePath: "/placeOrder/category",
				component: PlaceOrder,
				title: "Place Order"
			},
			{
				path: "/placeOrder/checkout",
				component: PlaceOrderCheckout,
				title: "Checkout"
			},
			{
				path: "/placeOrder/specialOpportunity",
				component: SpecialOpportunity,
				title: "Special Opportunity"
			},
			{
				path: "/placeOrder/history",
				component: PlaceOrderHistory,
				title: "Order History"
			},
		]
	},
	{
		path: "/accountSettings",
		component: AccountSettings,
		title: "Account Settings"
	},
	{
		path: "/manageBrands",
		component: ManageBrands,
		title: "Manage Brands"
	},
	{
		path: "/manageUsers/createUser",
		component: CreateUser,
		title: "Create User"
	},
	{
		path: "/manageUsers/editUser/:id",
		basePath: "/manageUsers/editUser",
		component: EditUser,
		title: "Edit User"
	},
	{
		path: "/manageUsers",
		component: ManageUsers,
		title: "Manage Users"
	},
	{
		path: "/manageAnnouncement",
		component: ManageAnnouncement,
		title: "Manage Announcement"
	},
	{
		path: "/brandInformation/:id",
		basePath: "/brandInformation",
		component: BrandInformation,
		title: "Brand Information"
	},
	{
		path: "/shippingAndDeliveryInfo",
		component: ShippingAndDeliveryInfo,
		title: "Shipping and Delivery Info"
	},
	{
		path: "/photoVideoEDU/reportSales",
		component: PhotoEDUReportSales,
		title: ""
	},
	{
		path: "/photoVideoEDU/rebates",
		component: PhotoEduRebates,
		title: ""
	},
	{
		path: "/rewards",
		component: Rewards,
		title: ""
	},
	{
		path: "/rewardsHistory",
		component: RewardsHistory,
		title: ""
	},
	{
		path: "/",
		component: () => (<Redirect to="/login"/>)
	}
];

function RouteWithSubRoutes(route) {
	const authenticated = useSelector((state) => state.users.currentUserToken);

	return (
		(authenticated || route.path === "/login" || route.noAuthenticationNeeded) ? <Route
			path={route.path}
			render={props => (
				// pass the sub-routes down to keep nesting
				<route.component {...props} routes={route.routes}/>
			)}
		/> : <Redirect to="/login"/>
	);
}

const useStyles = makeStyles((theme) => ({
	...styles,
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: 'white'
	},
	drawerToggle: {
		marginLeft: 4,
		width: 48,
		color: theme.palette.primary.main
	},
	toolbarSpacer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	contentPadding: {
		padding: theme.spacing(3),
		overflow: "auto"
	},
	appBarContent: {
		paddingLeft: 16,
		paddingRight: 16
	},
	noOverflow: {
		overflow: "hidden"
	},
	backgroundColor: {
		backgroundColor: '#F3F3F4'
	},
	logo: {
		height: 35,
		width: 'auto'
	},
	shoppingCartButton: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: 0,
		marginRight: -16,
		"&:hover": {
			backgroundColor: `${theme.palette.primary.main}B3`
		}
	},
	shoppingCartIcon: {
		fontSize: 40
	},
	iFrame: {
		border: "none",
		backgroundColor: "#08090D"
	}
}));

const StyledBadge = withStyles((theme) => ({
	badge: {
		right: 6,
		top: 10.5,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
	},
}))(Badge);

function SidebarCartIcon() {
	const classes = useStyles();
	const shoppingCartItems = useSelector((state) => state.placeOrder.shoppingCart);

	return (
		<StyledBadge
			badgeContent={shoppingCartItems ? shoppingCartItems.reduce((prev, curr) => prev += parseInt(curr.quantity), 0) : 0}
			color="secondary">
			<SvgIcon classes={{root: classes.shoppingCartIcon}} component={Icons.ShoppingCart} viewBox="-1.15 -3.16 24 24"/>
		</StyledBadge>
	);
}

const PURCHASER_ROLES = ["Super Administrator", "Sales Manager", "Salesman", "Administrator", "Buyer"];
const NO_PADDING_URIS = ["/brandInformation", "/shippingAndDeliveryInfo", "/photoVideoEDU/reportSales"];

const staticContentRoutes = {
	'/home': 'https://cnc-api.zmags.com/view/lite/612950d3a3dd511b8c7200af',
	'/whatsNewWhatsHot': 'https://cnc-api.zmags.com/view/lite/6400d4716df9f10f76c46dbf'
}

function App() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const [expandedItems, setExpandedItems] = useState({});
	const [checkingSessionStorage, setCheckingSessionStorage] = useState(true);
	const loggedIn = !!useSelector((state) => state.users.currentUser);
	const localAppBarRef = useRef(null);
	const localContentNoPaddingRef = useRef(null);
	const location = useLocation();
	const [companies, setCompanies] = useState([]);
	const [companiesLoading, setCompaniesLoading] = useState(true);
	const [newOrderingFor, setNewOrderingFor] = useState(null);
	const [shouldClearShoppingCart, setShouldClearShoppingCart] = useState(false);
	const promptOrderingFor = useSelector((state) => state.placeOrder.promptOrderingFor);
	const autocompleteDefaults = useDefaults();
	const currentUser = useSelector((state) => state.users.currentUser);
	const userRoles = useSelector((state) => state.users.roles);
	const currentUserRole = currentUser ? userRoles.find((role) => role.title === currentUser.userRole) : null;
	const isInternal = currentUserRole ? currentUserRole.internal : null;
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);
	const isPurchaser = currentUserRole ? PURCHASER_ROLES.includes(currentUserRole.title) : null;
	const staticContent = staticContentRoutes[location.pathname];
	const removeContentPadding = NO_PADDING_URIS.some((uri) => location.pathname.indexOf(uri) > -1);

	function setAxiosConfig() {
		axios.interceptors.response.use((response) => {
			return response;
		}, (error) => {
			if (error.response.status === 401) {
				sessionStorage.clear();
				dispatch(resetAction());
				if (history.location.pathname !== '/login') {
					history.push('/login');
				}
			}
		});
	}

	async function checkSessionStorage() {
		const cUser = sessionStorage.getItem('currentUser');
		const cUserToken = sessionStorage.getItem('currentUserToken');
		const orderingFor = sessionStorage.getItem('orderingFor');

		if (cUser && cUserToken) {
			dispatch(setCurrentUser(JSON.parse(cUser)));
			dispatch(setCurrentUserToken(cUserToken));
			dispatch(setOrderingFor(JSON.parse(orderingFor)));
			const getBrandsAction = dispatch(getAllBrands());
			const getCategoriesAction = dispatch(getAllCategories());
			await getBrandsAction;
			await getCategoriesAction;
			if (history.location.pathname === "/login" || history.location.pathname === "/") {
				history.push("/home");
			}
		} else {
			sessionStorage.clear();
		}

		setCheckingSessionStorage(false);
	}

	useEffect(() => {
		setAxiosConfig();
		// noinspection JSIgnoredPromiseFromCall
		checkSessionStorage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (loggedIn && companies.length === 0) {
			(async function () {
				setCompaniesLoading(true);
				const results = await getAllCompanies();
				if (results) {
					setCompanies(results.data);
					setCompaniesLoading(false);
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loggedIn])

	useEffect(() => {
		setAppBarRef(localAppBarRef);
	}, [localAppBarRef]);

	useEffect(() => {
		setContentNoPaddingRef(localContentNoPaddingRef);
	}, [localContentNoPaddingRef]);

	useEffect(() => {
		setNewOrderingFor(null);
		setShouldClearShoppingCart(false);
	}, [promptOrderingFor]);

	const toggleDrawer = () => {
		const opening = !sidebarOpen;
		setSidebarOpen(opening);
		if (!opening) {
			setExpandedItems({});
		}
	}

	const toggleExpansion = (itm) => {
		let expanding = false;
		setExpandedItems((prevState) => {
			expanding = !prevState[itm.text];
			return {...prevState, [itm.text]: expanding}
		});
		if (!sidebarOpen && expanding) {
			toggleDrawer();
		}
	};

	/*const getAppBarTitle = () => {
		const path = location.pathname;
		let title = "";
		// use find just to short circuit
		routes.find((itm) => {
			const parentMatch = itm.basePath ? path.indexOf(itm.basePath) > -1 : itm.path === path;

			if (parentMatch) {
				title = itm.title;
				return true;
			} else if (itm.routes) {
				const childMatch = itm.routes.find((it) => {
					return it.basePath ? path.indexOf(it.basePath) > -1 : it.path === path;
				});

				if (childMatch) {
					title = childMatch.title;
				}
				return !!childMatch;
			} else {
				return false;
			}
		});
		return title;
	};*/

	async function clearCart() {
		dispatch(setShoppingCartLoading(true));
		await dispatch(clearShoppingCart());
		await dispatch(getShoppingCart());
		dispatch(setShoppingCartLoading(false));
	}

	async function switchDealers() {
		dispatch(setShoppingCartLoading(true));
		await dispatch(getShoppingCart());
		dispatch(setShoppingCartLoading(false));
	}

	const shouldShowCartIcon = (!isInternal && orderingFor && isPurchaser) || (isInternal && isPurchaser);

	return (
		<>
			<div key="main-content" className={clsx(classes.column, classes.fullHeight)}>
				{loggedIn &&
				<>
					<AppBar position="fixed" className={clsx(classes.appBar)}>
						<Toolbar disableGutters={true}>
							<div className={clsx(classes.row, classes.alignStartCenter, classes.fullWidth)}>
								<IconButton className={classes.drawerToggle} color="inherit" onClick={toggleDrawer}>
									<RotateTransitionIcons
										Icon1={Icons.BurgerMenu}
										Icon1Props={{
											viewBox: "-3 -2 24 24"
										}}
										Icon2={Icons.ArrowLeft}
										Icon2Props={{
											viewBox: "-7.75 -4.5 24 24"
										}}
										inProp={sidebarOpen}/>
								</IconButton>
								<img className={classes.logo} src={loginLogo} alt="Logo"/>
								{/*<Typography variant="h6" noWrap>*/}
								{/*	MacGroup Dealer Portal - {getAppBarTitle()}*/}
								{/*</Typography>*/}
								<div ref={localAppBarRef} className={clsx(classes.row, classes.appBarContent, "flex")}>
									{shouldShowCartIcon ? (
										<div className={clsx(classes.row, classes.alignEndCenter, "flex")}>
											<IconButton className={classes.shoppingCartButton} color="inherit" onClick={() => history.push('/placeOrder/shoppingCart')}>
												<SidebarCartIcon className={classes.white}/>
											</IconButton>
										</div>
									) : null}
								</div>
							</div>
						</Toolbar>
					</AppBar>
					<div className={classes.toolbarSpacer}/>
				</>}
				<div className={clsx(classes.noOverflow, classes.row, classes.fullHeight, "flex", classes.backgroundColor)}>
					{checkingSessionStorage ?
						<div className={clsx(classes.column, classes.alignCenterCenter, "flex")}>
							<CircularProgress size={54}/>
						</div>
						:
						<>
							{loggedIn &&
							<Sidebar sidebarOpen={sidebarOpen} expandedItems={expandedItems} toggleExpansion={toggleExpansion}/>}
							<div ref={localContentNoPaddingRef} className={clsx(classes.column, "flex")}>
								{
									!!staticContent ?
										loggedIn ?
											<Iframe
												url={staticContent}
												className={clsx(classes.iFrame, "flex")}
											/> :
											<Redirect to='/login' />
										:
										<div className={clsx({
											[classes.contentPadding]: !removeContentPadding,
											[classes.column]: !removeContentPadding,
											"flex": !removeContentPadding
										})}>
											<Switch>
												{routes.map((route, i) => (
													<RouteWithSubRoutes key={i} {...route} />
												))}
											</Switch>
										</div>
								}
							</div>
						</>}
				</div>
			</div>
			<Dialog key="order-for-dialog" open={promptOrderingFor} disableBackdropClick={true} disableEscapeKeyDown={true}>
				<DialogTitle>Select Dealer</DialogTitle>
				<DialogContent>
					<Typography>Please indicate who you wish to order for:</Typography>
					<Autocomplete
						{...autocompleteDefaults}
						className="flex"
						getOptionLabel={(x) => `${x.name} - ${x.id}`}
						filterOptions={(options, state) => {
							const inputValue = state.inputValue?.toLowerCase();
							return options.filter((o) => {
								const idMatch = o.id?.toLowerCase() === inputValue;
								const nameMatch = o.name?.toLowerCase()?.indexOf(inputValue) > -1;
								const name2Match = o.name2?.toLowerCase()?.indexOf(inputValue) > -1;
								const addressMatch = o.address?.toLowerCase()?.indexOf(inputValue) > -1;
								const cityMatch = o.city?.toLowerCase()?.indexOf(inputValue) > -1;
								const shipNameMatch = o.shipName?.toLowerCase()?.indexOf(inputValue) > -1;
								const shipName2Match = o.shipName2?.toLowerCase()?.indexOf(inputValue) > -1;
								const shipAddressMatch = o.shipAddress?.toLowerCase()?.indexOf(inputValue) > -1;
								const shipCityMatch = o.shipCity?.toLowerCase()?.indexOf(inputValue) > -1;
								const salesContactMatch = o.salesContact?.toLowerCase()?.indexOf(inputValue) > -1;
								const creditContactMatch = o.creditContact?.toLowerCase()?.indexOf(inputValue) > -1;
								const salesPhoneMatch = o.salesPhone?.toLowerCase() === inputValue;
								const creditPhoneMatch = o.creditPhone?.toLowerCase() === inputValue;

								return idMatch || nameMatch || name2Match || addressMatch || cityMatch || shipNameMatch || shipName2Match || shipAddressMatch || shipCityMatch || salesContactMatch || creditContactMatch || salesPhoneMatch || creditPhoneMatch;
							});
						}}
						options={companies}
						loading={companiesLoading}
						onChange={(e, v, r) => {
							setNewOrderingFor(v);
						}}
						renderInput={autocompleteRenderInput({
							label: "Dealer",
							margin: "normal",
							autoFocus: true
						})}
					/>
					<div className={clsx(classes.row, classes.alignCenter)}>
						<FormControlLabel
							control={<MuiSwitch checked={shouldClearShoppingCart} color="primary" onChange={(e) => setShouldClearShoppingCart(e.target.checked)}/>}
							label="Clear Current Shopping Cart"
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						onClick={(e) => {
							if (!orderingFor && location.pathname.indexOf('placeOrder') > -1) {
								history.goBack();
							}
							dispatch(setPromptOrderingFor(false));
						}}
					>
						Cancel
					</Button>
					<Button
						variant="text"
						color="primary"
						disabled={!newOrderingFor}
						onClick={(e) => {
							dispatch(setOrderingFor(newOrderingFor));
							sessionStorage.setItem('orderingFor', JSON.stringify(newOrderingFor));
							dispatch(setPromptOrderingFor(false));
							if (shouldClearShoppingCart) {
								clearCart();
							} else {
								switchDealers();
							}
						}}
					>
						Select
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default App;
