import {makeStyles, Typography} from "@material-ui/core";
import {styles} from "../utils/styles";
import {useSnackbar} from "notistack";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	...styles,
	trackingNumber: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		'&:active': {
			textDecoration: 'underline'
		}
	}
}));

function getCarrier(trackingNumber) {
	const upsTests = ["^(1Z)[0-9A-Z]{16}$", "^(T)+[0-9A-Z]{10}$", "^[0-9]{9}$", "^[0-9]{26}$"];
	const uspsTests = ["^(94|93|92|94|95)[0-9]{20}$", "^(94|93|92|94|95)[0-9]{22}$", "^(70|14|23|03)[0-9]{14}$", "^(M0|82)[0-9]{8}$", "^([A-Z]{2})[0-9]{9}([A-Z]{2})$"];
	const fedExTests = ["^[0-9]{20}$", "^[0-9]{15}$", "^[0-9]{12}$", "^[0-9]{22}$"];

	const isUps = upsTests.some((regexStr) => {
		const regex = new RegExp(regexStr);
		return regex.test(trackingNumber);
	});

	if (isUps)
		return "UPS";

	const isUsps = uspsTests.some((regexStr) => {
		const regex = new RegExp(regexStr);
		return regex.test(trackingNumber);
	});

	if (isUsps)
		return "USPS";

	const isFedex = fedExTests.some((regexStr) => {
		const regex = new RegExp(regexStr);
		return regex.test(trackingNumber);
	});

	if (isFedex)
		return "FedEx";
	else
		return null;
}

function TrackingNumberDisplay({trackingNumber, oneBefore}) {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const carrier = getCarrier(trackingNumber);

	function openTracking() {
		const ups = `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
		const usps = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`;
		const fedex = `https://www.fedex.com/fedextrack/?action=track&tracknumbers=${trackingNumber}&locale=en_US&cntry_code=en`;

		switch (carrier) {
			case "UPS":
				window.open(ups, '_blank');
				break;
			case "USPS":
				window.open(usps, '_blank');
				break;
			case "FedEx":
				window.open(fedex, '_blank');
				break;
			default:
				enqueueSnackbar("Unable to match carrier to that tracking number", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					variant: "warning"
				});
		}
	}

	return (
		<>
			<span>{oneBefore ? "," : "Tracking Number(s):"}</span>
			&nbsp;
			<Typography className={clsx({[classes.trackingNumber]: !!carrier})} variant="subtitle2" onClick={!!carrier ? openTracking : null}>
				{trackingNumber}
			</Typography>
		</>
	);
}

export default TrackingNumberDisplay;