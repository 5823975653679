import * as React from "react";

function SvgFilter(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 16" {...props}>
      <path
        d="M6.5 0C2.7 0 0 .9 0 2.3c0 .3.1.6.4.8v.1l4.2 5.7v6.5c0 .3.2.6.5.6 0 0 3.2 0 3.2-3V8.9l4.2-5.7c.3-.2.5-.6.5-.9C13 .9 10.3 0 6.5 0zm5.4 2.3c-.1 0-.1.1-.1.1-.7.5-2.6 1-5.2 1-3.8 0-5.4-1-5.4-1.2C1.2 2 2.8 1 6.6 1c3.7.1 5.2 1.1 5.3 1.3z"
      />
    </svg>
  );
}

export default SvgFilter;
