import * as React from "react";

function SvgArrowLeft(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.5 15" {...props}>
      <path
        d="M2.083 7.529l6.4-6.2-1.3-1.3-7.2 6.8v1.4l7.2 6.8 1.3-1.4z"
      />
    </svg>
  );
}

export default SvgArrowLeft;
