import {
	Button,
	Card,
	CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
	Divider,
	InputAdornment,
	makeStyles, SvgIcon,
	TextField, Tooltip,
	Typography
} from "@material-ui/core";
import { styles } from "../utils/styles";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import FormAutocomplete from "../components/form-autocomplete";
import { useAutocomplete, useDefaults } from "../utils/autocomplete";
import useSmartForm from "../utils/smart-form";
import * as yup from "yup";
import { XGrid } from "@material-ui/x-grid";
import { searchProducts } from "../utils/common-requests";
import { formatCurrency } from "../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import {
	asyncAddToShoppingCart,
	getShoppingCart, setShoppingCartLoading
} from "../data/slices/place-order";
import { useSnackbar } from "notistack";
import HtmlTooltip from "../components/html-tooltip";
import getShoppingCartColumns from "../components/shopping-cart-row";
import FormTextField from "../components/form-text-field";
import NumberFormat from "react-number-format";
import axios from "axios";
import * as Icons from "../icons";
import { useHistory } from "react-router";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
	...styles,
	textRight: {
		textAlign: "right"
	},
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	footerPadding: {
		padding: 8
	},
	warn: {
		color: theme.palette.warning.main
	},
	fontSize12: {
		fontSize: '12px !important'
	}
}));

const quickOrderSchema = yup.object().shape({
	product: yup.object().nullable().required("Product is required"),
	quantity: yup.number().required("Quantity is required").positive("Quantity must be positive").integer("Quantity must be a whole number").typeError("Quantity must be a number").moreThan(0, "Quantity must be positive")
});

function PlaceQuickOrder() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [localColumns, setLocalColumns] = useState(getShoppingCartColumns());
	const [description, setDescription] = useState("");
	const [quantityAvailable, setQuantityAvailable] = useState("");
	const [dealerNet, setDealerNet] = useState("0.00");
	const [price, setPrice] = useState("0.00");
	const [extendedPrice, setExtendedPrice] = useState("0.00");
	const [addLoading, setAddLoading] = useState(false);
	const [addToCartDisabled, setAddToCartDisabled] = useState(false);
	const [isDuplicateItem, setIsDuplicateItem] = useState(false);
	const [duplicateMessage, setDuplicateMessage] = useState("");
	const [hasSpecialPopup, setHasSpecialPopup] = useState(null);
	const cartLoading = useSelector((state) => state.placeOrder.shoppingCartLoading);
	const shoppingCartItems = useSelector((state) => state.placeOrder.shoppingCart);
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);
	const currentUser = useSelector((state) => state.users.currentUser);
	const autocompleteDefaults = useDefaults();
	const history = useHistory();
	const actionRef = useRef('');
	const [setProductSearch, productResults, productsIsLoading] = useAutocomplete(searchProductsWithCompany);
	const { register, handleSubmit, formState: { errors }, control, setValue, getValues, getField } = useSmartForm({
		schema: quickOrderSchema,
		defaultValues: {
			"product": null,
			"quantity": 1,
			"productInput": ''
		}
	});

	function searchProductsWithCompany(searchTerm) {
		return searchProducts(searchTerm, orderingFor ? orderingFor.id : null);
	}

	function changeProduct(v, fromBlur) {
		setDescription(v ? v.extraDescription ? v.extraDescription : v.description : '');
		let newDealerNet = 0.0;
		if (currentUser.userRole === "EPP") {
			if (v?.map > 0) {
				newDealerNet = v.map;
			} else if (v?.street > 0) {
				newDealerNet = v.street;
			} else {
				newDealerNet = "N/A";
			}
		} else {
			newDealerNet = v?.dealerNet;
		}
		setDealerNet(v ? formatCurrency(newDealerNet) : '0.00');
		setPrice(v ? formatCurrency(v.actualPrice) : '0.00');
		setQuantityAvailable(v ? v.available < 0 ? 0 : v.available : '');
		if (fromBlur === true) {
			setExtendedPrice(v ? formatCurrency(v.actualPrice * getValues('quantity')) : '0.00');
		} else {
			setValue('quantity', 1);
			setExtendedPrice(v ? formatCurrency(v.actualPrice) : '0.00');
		}
		setValue('productInput', v ? v.prodId : '');
		setValue('product', v, { shouldValidate: true });
	}

	async function getProductFromId(prodId) {
		let response = await axios.get('/products/byProdId', {
			params: {
				vicariousCompany: orderingFor?.id,
				prodId: prodId
			}
		});

		return response.data;
	}

	async function handleProductBlur(e) {
		const currentProduct = getValues('product');

		if (!e.target.value) {
			changeProduct(null);
		} else if (e.target.value && (!currentProduct || e.target.value !== currentProduct.prodId)) {
			setAddToCartDisabled(true);

			const product = await getProductFromId(e.target.value);

			if (product) {
				changeProduct(product, true);
			} else {
				changeProduct(null);
			}
			setAddToCartDisabled(false);
		}
	}

	useEffect(() => {
		(async function () {
			dispatch(setShoppingCartLoading(true));
			await dispatch(getShoppingCart());
			dispatch(setShoppingCartLoading(false));
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleCleanup() {
		dispatch(setShoppingCartLoading(true));
		setDescription('');
		setDealerNet('0.00');
		setPrice('0.00');
		setExtendedPrice('0.00');
		setQuantityAvailable('');
		setValue('quantity', 1);
		setValue('product', null);
		setValue('productInput', '');
		setAddLoading(false);
		dispatch(setShoppingCartLoading(false));
		getField('productInput')?.ref?.current?.focus();
	}

	async function addToCart(data) {
		if (addLoading) {
			return;
		}

		const shoppingCartMatch = shoppingCartItems.find((itm) => itm.prodId === data.product.prodId);
		const isSpecialOrder = data.product.code === "D";
		const totalOrderedMoreThanAvailable = parseInt(data.product.available) < (parseInt(data.quantity) + parseInt(shoppingCartMatch?.quantity ?? 0));

		if (isSpecialOrder && totalOrderedMoreThanAvailable) {
			enqueueSnackbar("This is a discontinued product. Quantity ordered cannot exceed available for this product.", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "warning"
			});

			return;
		}

		setAddLoading(true);
		try {
			if (actionRef.current) {
				await dispatch(asyncAddToShoppingCart({
					prodId: data.product.prodId,
					quantity: data.quantity,
					actionType: actionRef.current,
					fromSpecial: 0
				}));
				enqueueSnackbar("Product added successfully!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					variant: "success"
				});
				actionRef.current = '';
				setAddLoading(false);
				handleCleanup();
				setIsDuplicateItem(false);
				setDuplicateMessage('');
			} else {
				let response = await dispatch(asyncAddToShoppingCart({
					prodId: data.product.prodId,
					quantity: data.quantity,
					fromSpecial: 0
				}));
				if (response.payload?.hasDuplicate) {
					setDuplicateMessage(response.payload?.message);
					setIsDuplicateItem(true);
					setAddLoading(false);
				} else {
					enqueueSnackbar("Product added successfully!", {
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
						variant: "success"
					});
					setAddLoading(false);
					handleCleanup();
				}

			}
		} catch (e) {
			enqueueSnackbar("Something went wrong...", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
			setAddLoading(false);
		}

	}

	return (
		<>
			<Card
				raised={true}
				className={clsx(classes.column, "flex")}
			>
				<CardContent className={clsx(classes.column, "flex")}>
					<Typography variant="h6">
						&nbsp;
						&nbsp;
						Quick Order Product
						{orderingFor && <span> - <span
							className={classes.capitalize}>{orderingFor.name.toLowerCase()}</span> - {orderingFor.id}</span>}
					</Typography>
					<div className={classes.row}>
						<FormAutocomplete
							{...autocompleteDefaults}
							formProps={{ name: "product", control: control }}
							className="flex25"
							getOptionLabel={(x) => `${x.prodId}`}
							filterOptions={(options, _) => options}
							renderOption={(x) => (
								<div className={classes.column}>
									<b>{x.prodId}</b>
									<span>{x.extraDescription ? x.extraDescription : x.description}</span>
								</div>
							)}
							loading={productsIsLoading}
							options={productResults}
							getOptionSelected={(o, v) => {
								return o.prodId === v.prodId
							}}
							onInputChange={(e, value, reason) => setProductSearch(value)}
							onChange={async (e, v, r) => {
								changeProduct(v);

								if (v) {
									setAddToCartDisabled(true);
									let productWithPricing = await getProductFromId(v.prodId);
									changeProduct(productWithPricing);
									setAddToCartDisabled(false);
								}
							}}
							renderInputParams={{
								label: "Product",
								isLoading: productsIsLoading,
								margin: "normal",
								name: "productInput",
								register: register,
								error: !!errors.product || !!errors.productInput,
								helperText: errors.product?.message || errors.productInput?.message ||
									(getValues('product') && getValues('product').code === "O" && "This is a special order product"),
								FormHelperTextProps: {
									classes: !!!errors.product && !!!errors.productInput ? {
										root: classes.warn
									} : null
								},
								required: true,
								disabled: addLoading,
								onBlur: (e) => {
									handleProductBlur(e);
								}
							}}
						/>
						<span style={{ paddingLeft: 8 }}/>
						<HtmlTooltip
							arrow={true}
							placement="top"
							title={
								<>
									<Typography color="inherit">Full Product Description</Typography>
									{description ? description : "Please select a product to view its description."}
								</>
							}
						>
							<TextField className={"flex25"}
												 variant="outlined"
												 label="Description"
												 value={description}
												 margin="normal"
												 disabled={true}
							/>
						</HtmlTooltip>
						<span style={{ paddingLeft: 8 }}/>
						<TextField className={"flex25"}
											 variant="outlined"
											 label="Quantity Available"
											 value={quantityAvailable}
											 margin="normal"
											 inputProps={{
												 className: classes.textRight
											 }}
											 disabled={true}
						/>
						<span style={{ paddingLeft: 8 }}/>
						<FormTextField formProps={{ name: 'quantity', control: control }}
													 className={"flex25"}
													 variant="outlined"
													 label="Quantity"
													 margin="normal"
													 onChange={(e) => {
														 const valAsNumber = parseInt(e.target.value);
														 setExtendedPrice(isNaN(valAsNumber) ? '0.00' : formatCurrency(valAsNumber * parseFloat(price.replace(',', ''))));
													 }}
													 onFocus={(e) => e.target.select()}
													 inputProps={{
														 className: classes.textRight
													 }}
													 disabled={addLoading}
													 InputProps={{
														 startAdornment: quantityAvailable !== "" && (quantityAvailable < 0 ? 0 : quantityAvailable) < getValues('quantity') ?
															 <InputAdornment position="start">
																 <Tooltip title="Quantity available is less than quantity ordered" placement="right">
																	 <div className={clsx(classes.column, classes.alignCenterStart)}>
																		 <SvgIcon className={classes.warn} component={Icons.Information}
																							viewBox="-2 -2 24 24"/>
																	 </div>
																 </Tooltip>
															 </InputAdornment> :
															 null
													 }}
						/>
					</div>
					<div className={classes.row}>
						<TextField className={"flex25"}
											 variant="outlined"
											 label={currentUser.userRole === "EPP" ? "MAP/Street" : "Dealer Net"}
											 value={dealerNet}
											 margin="normal"
											 disabled={true}
											 InputProps={{
												 startAdornment: <InputAdornment position="start">$</InputAdornment>
											 }}
											 inputProps={{
												 className: classes.textRight
											 }}
						/>
						<span style={{ paddingLeft: 8 }}/>
						<TextField className={"flex25"}
											 variant="outlined"
											 label="Price"
											 value={price}
											 margin="normal"
											 disabled={true}
											 InputProps={{
												 startAdornment: <InputAdornment position="start">$</InputAdornment>
											 }}
											 inputProps={{
												 className: classes.textRight
											 }}
						/>
						<span style={{ paddingLeft: 8 }}/>
						<TextField className={"flex25"}
											 variant="outlined"
											 label="Extended"
											 value={extendedPrice}
											 margin="normal"
											 disabled={true}
											 InputProps={{
												 startAdornment: <InputAdornment position="start">$</InputAdornment>
											 }}
											 inputProps={{
												 className: classes.textRight
											 }}
						/>
						<span style={{ paddingLeft: 8 }}/>
						<div className={clsx("flex25", classes.row, classes.alignCenterCenter)}>
							<div className={classes.relativePosition}>
								<Button
									size="large"
									variant="contained"
									color="primary"
									onClick={() => {
										const product = getValues('product');
										const hasMatch = !!shoppingCartItems.find((item) => item.prodId === product.prodId);
										const hasSpecial = product.specialPricingAvailable;

										if(!hasMatch && hasSpecial && currentUser.userRole !== "EPP") {
											setHasSpecialPopup(product);
										}else{
											handleSubmit(addToCart)();
										}
									}}
									disabled={addLoading || addToCartDisabled}
									endIcon={<SvgIcon className={classes.fontSize12} component={Icons.Add} viewBox="2 2 16 16"/>}
								>
									Add to Cart
								</Button>
								{addLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
							</div>
						</div>
					</div>
					<br/>
					<Divider/>
					<br/>
					<Typography variant="h6">
						&nbsp;
						&nbsp;
						Shopping Cart
					</Typography>
					<br/>
					<div className={"flex"}>
						<XGrid
							columns={localColumns}
							rows={cartLoading ? [] : [...shoppingCartItems].sort((a, b) => b.id - a.id)}
							disableColumnSelector={true}
							disableMultipleSelection={true}
							disableSelectionOnClick={true}
							loading={cartLoading}
							components={{
								Footer: () => (
									<div>
										<Divider/>
										<div className={clsx(classes.row, classes.alignSpaceAround, classes.footerPadding)}>
											<div className={clsx(classes.column, classes.alignCenterCenter)}>
												<Typography variant="subtitle2">Unique SKUs: </Typography>
												<NumberFormat
													value={shoppingCartItems?.length ?? 0}
													decimalScale={0}
													fixedDecimalScale={true}
													thousandSeparator=','
													displayType='text'
													renderText={(formattedValue, _) => (
														<Typography variant="body2">
															{formattedValue}
														</Typography>
													)}
												/>
											</div>
											<div className={clsx(classes.column, classes.alignCenterCenter)}>
												<Typography variant="subtitle2">Total Pieces: </Typography>
												<NumberFormat
													value={shoppingCartItems?.reduce((prev, curr) => prev += curr.quantity, 0) ?? 0}
													decimalScale={0}
													fixedDecimalScale={true}
													thousandSeparator=','
													displayType='text'
													renderText={(formattedValue, _) => (
														<Typography variant="body2">
															{formattedValue}
														</Typography>
													)}
												/>
											</div>
											<div className={clsx(classes.column, classes.alignCenterCenter)}>
												<Typography variant="subtitle2">Total Amount: </Typography>
												<NumberFormat
													value={shoppingCartItems?.reduce((prev, curr) => {
														return prev += parseFloat((curr.quantity * curr.actualPrice).toFixed(2));
													}, 0.0) ?? 0}
													decimalScale={2}
													fixedDecimalScale={true}
													thousandSeparator=','
													prefix='$'
													displayType='text'
													renderText={(formattedValue, _) => (
														<Typography variant="body2">
															{formattedValue}
														</Typography>
													)}
												/>
											</div>
										</div>
									</div>
								)
							}}
						>
						</XGrid>
					</div>
				</CardContent>
			</Card>
			<Dialog open={hasSpecialPopup}>
				<DialogContent>
					This item is available in the Special Opportunity section.
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						variant="text"
						onClick={(e) => {
							handleSubmit(addToCart)();
							setHasSpecialPopup(false)
						}}
					>
						Continue Adding to Cart
					</Button>
					<Button
						style={{color: '#49c914'}}
						variant="text"
						onClick={(e) => {
							history.push(`/placeOrder/category/${hasSpecialPopup.category}?special=true&prodId=${hasSpecialPopup.id}`);
							setHasSpecialPopup(false);
						}}
					>
						View Special Opportunity
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={isDuplicateItem} disableBackdropClick={true} disableEscapeKeyDown={true}>
				<DialogTitle>Duplicate Items</DialogTitle>
				<DialogContent>
					<Typography>{duplicateMessage}</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setIsDuplicateItem(false);
							setDuplicateMessage('');
							handleCleanup();
						}}
						disabled={addLoading}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							actionRef.current = 'update';
							handleSubmit(addToCart)();
						}}
						disabled={addLoading}
						color='primary'
					>
						Update
					</Button>
					<Button
						onClick={() => {
							actionRef.current = 'add';
							handleSubmit(addToCart)();
						}}
						disabled={addLoading}
						color='primary'
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default PlaceQuickOrder;