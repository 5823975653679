// noinspection DuplicatedCode
import {useState, useRef, createRef, useEffect, useReducer} from 'react';
import isEqual from 'react-fast-compare';

export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}

function getValuesMap(fields, defaultValues, setValueCache) {
	let values = {};
	let keys = Object.keys(fields);
	for (let i = 0; i < keys.length; i++) {
		// we will need to check whether the field is a fieldArray
		// if it is, we will need to create an array and call getValuesMap on each of those elements
		if (fields[keys[i]].isFieldArray) {
			values[keys[i]] = Object.values(fields[keys[i]].fieldValues).map((field) => {
				return getValuesMap(field, defaultValues[keys[i]] || {}, setValueCache[keys[i]] || {});
			});
		} else {
			values[keys[i]] = fields[keys[i]].value === undefined ? defaultValues[keys[i]] : fields[keys[i]].value;
		}
	}

	for (const [key, value] of Object.entries(defaultValues)) {
		if (values[key] === undefined) {
			values[key] = setValueCache[key] === undefined ? value : setValueCache[key];
		}
	}

	return values;
}

const fieldArrayRegex = /([a-zA-Z0-9]+)\[([0-9]+)\]\.([a-zA-Z0-9]+)/

function useForm({
									 defaultValues: defaultValuesProp,
									 resolver,
									 mode = "onSubmit",
									 criteriaMode,
									 profile,
									 shouldFocusError = true
								 } = {}) {

	// defaultValues must be stored only to support the awkward `keepDefaultValues` option on reset
	let defaultValues = useRef(defaultValuesProp || {});

	// data must be stored in a ref since we only want a re-render on watched variables
	let data = useRef({});

	// watchFieldCache is a hack to allow users of the form library to watch fields that haven't been registered
	// I think the code would be way simpler if we just didn't allow that
	let watchFieldCache = useRef({});
	// setValueCache is a hack to allow out-of-sync code to setValue of fields that haven't yet been registered
	let setValueCache = useRef({});
	// this handles the auto-focus on submit when there are errors
	let registerPosition = useRef(0);
	// we need this to force re-renders because setState won't re-render if value hasn't changed
	const [, forceRender] = useReducer((s) => s + 1, 0);

	// formState stored in useState so that we force re-renders when state changes.
	let [formState, _setFormState] = useState({
		isDirty: false,
		dirtyFields: {},
		touchedFields: {},
		isSubmitted: false,
		isSubmitSuccessful: false,
		isSubmitting: false,
		submitCount: 0,
		isValid: true,
		isValidating: false,
		errors: {}
	});

	// this is a fun trick. We will create a ref that we sync to be the current values of formState and setFormState
	// that way we can close over the ref in the `onblur` method bound to the input at time of ref binding and use the ref to get the current
	// value of formState and the appropriate setter. Otherwise onblur would be buggy since it would refer the the stale versions of formState and setFormState
	// that were valid at the time the onblur handler was bound
	let formStateRef = useRef([formState, _setFormState]);
	// This function proxies the useState setter to ensure that the ref always has the current state
	// callers will still need to reget the values from the ref in order to be assured they have the current state
	function setFormState(fs) {
		_setFormState(fs);
		formStateRef.current[0] = fs;
	}

	formStateRef.current = [formState, setFormState];

	// We want to encapsulate all touching code in this function but we don't want to cause a re-render every time
	// we touch a field so we will return a new formState object with the change made, that way we can build up a new formState
	// over many calls to formState mutators and then use setFormState at the end for the re-render
	// the third argument is just to support touched state on useFieldArray
	function setFieldTouched(name, fs = formState, {fieldId, values} = {}) {
		if (data.current[name].isFieldArray) {
			// when the name corresponds to a field array we will have to mark the field inside the field array
			// as being touched
			let valueKeys = Object.keys(values);
			for (let i = 0; i < valueKeys.length; i++) {
				if (data.current[name].fieldValues[fieldId][valueKeys[i]] instanceof Object) {
					data.current[name].fieldValues[fieldId][valueKeys[i]].isTouched = true;
				}
			}

			let newIsTouched = {};
			for (let i = 0; i < valueKeys.length; i++) {
				newIsTouched[valueKeys[i]] = true;
			}

			// There may not already be a map defined for this fieldArray defined in the formState
			// because we can't initialize it on first render without triggering another render since formState is in useState
			// therefore we have to check to see if there is already a map
			if (fs.touchedFields[name]) {
				// even if there is a map for the fieldArray already, we will have to check to see
				// if there is a an entry for this particular fieldId since we will have to create it if not
				// however in either case we will need a map of bools


				if (fs.touchedFields[name][fieldId]) {
					return {
						...fs,
						touchedFields: {
							...fs.touchedFields,
							[name]: {
								...fs.touchedFields[name],
								[fieldId]: {
									...fs.touchedFields[name][fieldId],
									...newIsTouched
								}
							}
						}
					};
				} else {
					return {
						...fs,
						touchedFields: {
							...fs.touchedFields,
							[name]: {
								...fs.touchedFields[name],
								[fieldId]: newIsTouched
							}
						}
					};
				}
			} else {
				return {
					...fs,
					touchedFields: {
						...fs.touchedFields,
						[name]: {
							[fieldId]: newIsTouched
						}
					}
				};
			}
		} else {
			data.current[name].isTouched = true;
			// RHF sets the value in touchedFields to the "field" rather than just boolean true.
			// storing the field itself requires duplicating state which can lead to bugs and complexity
			// instead we will provide `getField` function to access the field when we need it
			return {...fs, touchedFields: {...fs.touchedFields, [name]: true}};
		}

	}

	// see setFieldTouched for why this returns formState-like object and accepts formState as argument
	function setFieldDirty(name, fs = formState, {fieldId, values} = {}) {
		// see notes in setFieldTouched for understanding isFieldArray
		if (data.current[name].isFieldArray) {
			let valueKeys = Object.keys(values);
			for (let i = 0; i < valueKeys[i]; i++) {
				data.current[name].fieldValues[fieldId][valueKeys[i]].isDirty = true;
			}

			let newIsDirty = {};
			for (let i = 0; i < valueKeys.length; i++) {
				newIsDirty[valueKeys[i]] = true;
			}
			if (fs.dirtyFields[name]) {

				if (fs.dirtyFields[name][fieldId]) {
					return {
						...fs,
						dirtyFields: {
							...fs.dirtyFields,
							[name]: {
								...fs.dirtyFields[name],
								[fieldId]: {
									...fs.dirtyFields[name][fieldId],
									...newIsDirty
								}
							}
						}
					};
				} else {
					return {
						...fs,
						dirtyFields: {
							...fs.dirtyFields,
							[name]: {
								...fs.dirtyFields[name],
								[fieldId]: newIsDirty
							}
						}
					};
				}
			} else {
				return {
					...fs,
					dirtyFields: {
						...fs.dirtyFields,
						[name]: {
							[fieldId]: newIsDirty
						}
					}
				};
			}
		} else {
			data.current[name].isDirty = true;
			// see setFieldTouched for why this is boolean instead of the field itself
			return {...fs, isDirty: true, dirtyFields: {...fs.dirtyFields, [name]: true}};
		}

	}

	// This function supports chaining formState for performance like the other functions
	// it also has side-effects like the other functions with respect to validation state in the fields map
	// name passed to validate so we can choose which fields to validate as opposed to being forced to validate all at a given time
	function validate(fs = formState, name, {fieldId, fieldValues} = {}) {
		if (resolver.mode === 'sync') {
			let {errors, values} = resolver.validateSync(getValuesMap(data.current, defaultValues.current, setValueCache.current), {criteriaMode});

			for (const k of Object.keys(data.current)) {
				if (!data.current[k].isFieldArray) {
					data.current[k].isValid = true;
				} else {
					let fieldIds = Object.keys(data.current[k].fieldValues);
					for (const k2 of fieldIds) {
						let temp = data.current[k].fieldValues[k2];
						for (const k3 of Object.keys(temp)) {
							if (temp[k3] instanceof Object) {
								temp[k3].isValid = true;
							}
						}
					}
				}
			}
			// The errors will be keyed like "entries[5].data" so we will need to somehow convert that to an accessor maybe?
			// if we only support single layer of nesting with field arrays we can just parse the strings for the params we need
			// we need the name of the field array, the index, and the fieldname
			// filter out errors that shouldn't apply if name is provided based on isTouched values
			if (name) {
				const filteredErrors = {};

				for (const k of Object.keys(errors)) {
					let reg = k.match(/([A-Za-z]+)\[([0-9]+)\]\.([A-Za-z0-9]+)/);
					if (reg) {
						let fields = Object.values(data.current[reg[1]].fieldValues);
						let field = fields.find((x) => x.index === parseInt(reg[2]));
						if (field[reg[3]]?.isTouched) {
							filteredErrors[k] = errors[k];
							field[reg[3]].isValid = false;
						}
					} else {
						if (data.current[k]?.isTouched) {
							filteredErrors[k] = errors[k];
							data.current[k].isValid = false;
						}
					}

				}

				// TODO: need to know how the keys on errors look if the name is a fieldArray

				errors = filteredErrors;
			} else {
				// set is touched on all the fields as we're validating the whole form
				// this avoids showing red on submit and fields losing their error state when
				// validate gets called on a particular field ex post facto
				for (const k of Object.keys(data.current)) {
					if (data.current[k].isFieldArray) {
						let fieldValueKeys = Object.keys(data.current[k].fieldValues);
						for (let i = 0; i < fieldValueKeys.length; i++) {
							fs = setFieldTouched(k, fs, {
								fieldId: fieldValueKeys[i],
								values: data.current[k].fieldValues[fieldValueKeys[i]]
							});
						}
					} else {
						fs = setFieldTouched(k, fs);
					}
				}
			}

			// need to process the errors map and create a new errors array that makes it easier to get the errors for that particular field
			// field for fieldArray should correspond to a map from fieldId to list of errors for that entry
			let newErrors = {};
			let errorKeys = Object.keys(errors);
			for (let i = 0; i < errorKeys.length; i++) {
				let rmatch = errorKeys[i].match(fieldArrayRegex);
				if (rmatch) {
					if (!newErrors[rmatch[1]]) {
						// the key is not already defined
						newErrors[rmatch[1]] = {};
					}
					let fieldValues = data.current[rmatch[1]].fieldValues;
					let sortedFields = Object.keys(fieldValues).map((x) => {
						fieldValues[x]._id = x;
						return fieldValues[x];
					}).sort((a, b) => a.index - b.index);
					let field = sortedFields[rmatch[2]];
					let fieldId = field._id;
					if (!newErrors[rmatch[1]][fieldId]) {
						newErrors[rmatch[1]][fieldId] = {};
					}
					newErrors[rmatch[1]][fieldId][rmatch[3]] = errors[errorKeys[i]];
				} else {
					newErrors[errorKeys[i]] = errors[errorKeys[i]];
				}
			}

			fs = {
				...fs,
				isValid: isEqual(errors, {}),
				errors: newErrors
			}
			// update all the fields that have errors to be invalid
			for (let i = 0; i < errorKeys.length; i++) {
				let rmatch = errorKeys[i].match(fieldArrayRegex);
				if (rmatch) {
					let fieldValues = data.current[rmatch[1]].fieldValues;
					let sortedValues = Object.values(fieldValues).sort((a, b) => a.index - b.index);
					let field = sortedValues[rmatch[2]];
					field[rmatch[3]].isValid = false;
				} else {
					data.current[errorKeys[i]].isValid = false;
				}
			}

			// update the form values to potentially coerced values from the schema
			// we are not checking for watched values here because I'm worried html inputs making things strings when the schema tries to make them numbers
			// will result in an infinite loop for controlled inputs
			// if this results in quirky behavior we will revisit
			let valueKeys = Object.keys(values);
			for (let i = 0; i < valueKeys.length; i++) {
				_updateValue(valueKeys[i], values[valueKeys[i]]);
			}
		} else {
			fs = {
				...fs,
				isValidating: true
			};
			resolver.validateAsync(getValuesMap(data.current, defaultValues.current, setValueCache.current), {criteriaMode}).then(({values, errors}) => {
				// filter out errors that shouldn't apply if name is provided based on isTouched values
				if (name) {
					const filteredErrors = {};
					for (const k of Object.keys(errors)) {
						if (data.current[k]?.isTouched) {
							filteredErrors[k] = errors[k];
						}
					}
					errors = filteredErrors;
				}

				// by the time async validation finishes there may have been changes to formState, rather than overwrite them and cause bugs
				// we will use the ref to get the exact current values
				let [formState, setFormState] = formStateRef.current;
				let fs = {
					...formState,
					isValid: isEqual(errors, {}),
					isValidating: false,
					errors
				};

				let errorKeys = Object.keys(errors);
				for (let i = 0; i < errorKeys.length; i++) {
					data.current[errorKeys[i]].isValid = false;
				}
				// see notes on sync version of this
				let valueKeys = Object.keys(values);
				for (let i = 0; i < valueKeys.length; i++) {
					_updateValue(valueKeys[i], values[valueKeys[i]]);
				}
				if (profile) {
					console.log('setFormState from async validate');
				}
				setFormState(fs);
			});
		}

		return fs;
	}

	// this is a private function used to perform updates to the field value/ ref value in an atomic way so that we can be sure it's handled in a single place
	// ignore ref was necessary given how material ui text fields update the label and call their own onChange (it doesn't work with only oninput)
	function _updateValue(name, value, ignoreRef = false) {
		// we will populate cache for later use when initializing field
		if (!data.current[name]) {
			setValueCache.current[name] = value;
			return;
		}
		// if the name corresponds to a fieldArray we have different logic
		if (data.current[name].isFieldArray) {
			let {fieldId, values} = value;
			let fieldValues = data.current[name].fieldValues;
			let valueKeys = Object.keys(values);
			for (let i = 0; i < valueKeys.length; i++) {
				fieldValues[fieldId][valueKeys[i]].value = values[valueKeys[i]];
				if (fieldValues[fieldId][valueKeys[i]].ref.current && !ignoreRef) {
					fieldValues[fieldId][valueKeys[i]].ref.current.value = values[valueKeys[i]];
				}


				if (fieldValues[fieldId][valueKeys[i]].changeHandler) {
					fieldValues[fieldId][valueKeys[i]].changeHandler(values[valueKeys[i]]);
				}
			}
		} else {
			data.current[name].value = value;
			if (data.current[name].ref.current && !ignoreRef) {
				// set the ref value anytime we set the field value
				data.current[name].ref.current.value = value;
			}
			// this is to support the cardinal sin of duplicating state, it's necessary because useController must have value in a useState
			// at least if we guarantee it's only changed here we can be sure they are kept in sync
			if (data.current[name].changeHandler) {
				data.current[name].changeHandler(value);
			}
		}

	}

	// if formState must be changed, setValue will actually cause re-render regardless of whether field is watched
	function setValue(name, value, {
		shouldValidate = false,
		shouldDirty = true,
		shouldTouch = true,
		ignoreRef = false
	} = {}) {
		// We will have to use the formState and setFormState from the ref because setValue will sometimes be called from
		// an onchange handler that closed over an old version of `setValue`, if we don't guarantee current formState we will get bugs
		let [formState, setFormState] = formStateRef.current;

		_updateValue(name, value, ignoreRef);
		// if the field we are updating hasn't been registered, there is no need to do validation or mark things dirty
		// we can re-evaluate this later but it will make code more complex
		if (!data.current[name]) {
			if (watchFieldCache.current[name]) {
				forceRender();
			}
			return;
		}
		// we will build up a new formState to do single set at the end
		let fs = formState;
		if (shouldDirty) {
			fs = setFieldDirty(name, fs, value instanceof Object ? value: {});
		}
		if (shouldTouch) {
			fs = setFieldTouched(name, fs, value instanceof Object ? value: {});
		}
		if (shouldValidate) {
			fs = validate(fs, name, value instanceof Object ? value: {});
		}

		// if field is watched we will setFormState to re-render,
		// if not we will only setFormState if they there has actually been a change in state
		if (!isEqual(fs, formState)) {
			if (profile) {
				console.log('setFormState from setValue');
			}
			setFormState(fs);
		} else if (data.current[name].isWatched) {
			forceRender();
		}
	}

	function onFieldChange(name, itemId, fieldName, value) {
		// using itemId instead of item here because we don't have access to the idField
		let vMap = {fieldId: itemId, values: {[fieldName]: value}};
		setValue(name, vMap);
		let [formState, setFormState] = formStateRef.current;
		let fs = formState;
		if (mode === "onChange" || (mode === "onSubmit" && !data.current[name].fieldValues[itemId][fieldName].isValid) || mode === "all") {
			fs = validate(fs, name, vMap);
			if (!isEqual(fs, formState)) {
				if (profile) {
					console.log('setFormState from onFieldChange');
				}
				setFormState(fs);
			}
		}
	}

	function onFieldBlur(name, itemId, fieldName) {
		let vMap = {fieldId: itemId, values: {[fieldName]: true}};
		let [formState, setFormState] = formStateRef.current;
		let fs = setFieldTouched(name, formState, vMap);
		if (mode === "onBlur" || (mode === "onTouched" && isEqual(fs.touchedFields, {})) || mode === "all") {
			fs = validate(fs, name, vMap);
			if (!isEqual(fs, formState)) {
				if (profile) {
					console.log('setFormState from onFieldBlur');
				}
				setFormState(fs);
			}
		}
	}

	function register(name, {controlled = false} = {}) {
		// registerRef is a higher-order function so that we can close over the current ref when we return the ref as a callback
		const registerRef = (ref) => (el) => {
			// callback is called with null https://reactjs.org/docs/refs-and-the-dom.html#caveats
			if (!el) {
				return
			}
			// This may seem odd, there is no callback for knowing when a ref has actually been registered
			// but React lets you pass a function AS a ref and it will call it with the element
			// this gives us the hook we need to register the onchange and onblur handlers for working with uncontrolled inputs
			ref.current = el;
			if (getValues(name) !== undefined) {
				el.value = getValues(name);
			}

		}
		const onChange = (e) => {
			setValue(name, e.target.value);
			let [formState, setFormState] = formStateRef.current;
			let fs = formState;
			if (mode === "onChange" || (mode === "onSubmit" && !data.current[name].isValid) || mode === "all") {
				fs = validate(fs, name);
				if (!isEqual(fs, formState)) {
					if (profile) {
						console.log('setFormState from onChange');
					}
					setFormState(fs);
				}
			}
		}
		const onBlur = (e) => {
			let fs = setFieldTouched(name);
			if (mode === "onBlur" || (mode === "onTouched" && isEqual(fs.touchedFields, {})) || mode === "all") {
				fs = validate(fs, name);
			}
			if (!isEqual(fs, formState)) {
				if (profile) {
					console.log('setFormState from onBlur');
				}
				setFormState(fs);
			}
		}
		if (data.current[name]) {
			// we will update the bindings for onChange and onBlur in the field objects to avoid bugs from versions of onChange and onBlur that closed over stale data
			data.current[name].onChange = onChange;
			data.current[name].onBlur = onBlur;
			return {ref: registerRef(data.current[name].ref), onChange, onBlur, name};
		}

		let ref = createRef();
		data.current[name] = {
			ref: ref,
			isTouched: false,
			isDirty: false,
			isWatched: watchFieldCache.current[name] || false,
			isValid: true,
			value: setValueCache.current[name] || (defaultValues.current[name] !== undefined ? defaultValues.current[name] : undefined),
			onChange,
			onBlur,
			order: registerPosition.current++
		};

		return {
			ref: registerRef(ref),
			onChange,
			onBlur,
			name
		};
	}

	function reset(values, {
		keepErrors,
		keepDirty,
		keepValues,
		keepDefaultValues,
		keepIsSubmitted,
		keepTouched,
		keepIsValid,
		keepSubmitCount
	} = {}) {
		// if values is empty map we will reset all the fields, otherwise we will reset only the fields in the values map
		if (isEqual(values, {})) {
			let keys = Object.keys(data.current);
			for (let i = 0; i < keys.length; i++) {
				if (!keepValues) {
					_updateValue(keys[i], keepDefaultValues ? defaultValues.current[keys[i]] : null);
				}
				if (!keepDirty) {
					data.current[keys[i]].isDirty = false;
				}
				if (!keepTouched) {
					data.current[keys[i]].isTouched = false;
				}
				if (!keepIsValid) {
					data.current[keys[i]].isValid = true;
				}
			}
		} else {
			let keys = Object.keys(values);
			for (let i = 0; i < keys.length; i++) {
				_updateValue(keys[i], values[keys[i]]);
				if (data.current[keys[i]]) {
					if (!keepDirty) {
						data.current[keys[i]].isDirty = false;
					}
					if (!keepTouched) {
						data.current[keys[i]].isTouched = false;
					}
					if (!keepIsValid) {
						data.current[keys[i]].isValid = true;
					}
				}
			}
		}
		if (!keepDefaultValues) {
			defaultValues.current = values || {};
		}
		if (profile) {
			console.log('setFormState from reset');
		}
		setFormState({
			...formState,
			errors: keepErrors ? formState.errors : {},
			isDirty: keepDirty ? formState.isDirty : false,
			dirtyFields: keepDirty ? formState.dirtyFields : {},
			touchedFields: keepTouched ? formState.touchedFields : {},
			isSubmitted: keepIsSubmitted ? formState.isSubmitted : false,
			submitCount: keepSubmitCount ? formState.submitCount : 0,
			isValid: keepIsValid ? formState.isValid : true
		})
	}

	function getValues(name) {
		if (!name) {
			return getValuesMap(data.current, defaultValues.current, setValueCache.current);
		} else if (data.current[name]) {
			return data.current[name] && (data.current[name].value || (data.current[name].isTouched ? data.current[name].value : defaultValues.current[name]));
		} else if (setValueCache.current[name] !== undefined) {
			return setValueCache.current[name];
		} else if (defaultValues.current[name] !== undefined) {
			return defaultValues.current[name];
		} else {
			return undefined;
		}
	}

	function watch(name) {
		// if the field hasn't been registered we will update cache to make sure register knows to populate isWatched
		if (!data.current[name]) {
			watchFieldCache.current[name] = true;
			if (setValueCache.current[name] !== undefined) {
				return setValueCache.current[name];
			}
			return defaultValues.current[name];
		}
		data.current[name].isWatched = true;
		// There will not be a value until after registration so we default to defaultValues if missing
		return getValues(name) !== undefined ? getValues(name) : defaultValues.current[name]
	}

	function handleSubmit(fn) {
		return function () {
			// can trust formState here since handleSubmit will always be regenerated with formState
			let fs = formState;
			// TODO: find way to support the async validation here, it currently will assume sync
			if (mode === "onSubmit" || mode === "all") {
				fs = validate();
			}
			let values = getValuesMap(data.current, defaultValues.current, setValueCache.current);
			if (isEqual(fs.errors, {})) {

				let ret = fn(values)
				fs = {
					...fs,
					isSubmitting: ret instanceof Promise,
					submitCount: fs.submitCount + 1,
					isSubmitted: !(ret instanceof Promise)
				}
				if (ret instanceof Promise) {
					ret.then((v) => {
						let [formState, setFormState] = formStateRef.current;
						let fs = {
							...formState,
							isSubmitSuccessful: true,
							isSubmitting: false
						};
						if (profile) {
							console.log('setFormState from async submit success');
						}
						setFormState(fs);
					}, (e) => {
						let [formState, setFormState] = formStateRef.current;
						let fs = {
							...formState,
							isSubmitSuccessful: false,
							isSubmitting: false
						};
						if (profile) {
							console.log('setFormState from async submit fail');
						}
						setFormState(fs);
					});
				}
			} else if (shouldFocusError) {
				const orderedFields = [];
				for (const k of Object.keys(data.current)) {
					if (fs.errors[k]) {
						orderedFields.push({order: data.current[k].order, field: data.current[k].ref?.current});
					}
				}

				orderedFields.sort((a, b) => {
					return a.order - b.order;
				});

				orderedFields[0]?.field?.focus();
			}
			// No need to waste cpu cycles checking if it's different here
			// in the case of async submit, isSubmitting will have changed
			// in the case of sync submit, submitCount will have changed
			if (profile) {
				console.log('setFormState from handleSubmit');
			}
			setFormState(fs);
		}

	}

	function getField(name) {
		return data.current[name];
	}

	function registerFieldArray(name) {
		data.current[name] = {
			isFieldArray: true, // flag will help useForm decide how to handle changing touched state and stuff like that
			fields: {}, // a map from name to field data for things like iswatched
			fieldValues: {} // a map from id to row data for accessing values and order and stuff
		};
	}

	return {
		setValue,
		register,
		watch,
		formState,
		getValues,
		reset,
		handleSubmit,
		getField,
		defaultValuesRef: defaultValues,
		control: {
			register, // control will need register to support registering on init of useController using the name passed in
			registerFieldArray, // useFieldArray will have to have a way of registering itself with the parent form
			formState,
			getField, // control will need getField to support useController accessing the onChange and onBlur handlers for the parent field while overriding them
			onFieldChange, // methods for useFieldArray register function
			onFieldBlur,
			registerPosition,
			setValue,
			registerChangeHandler(name, fn) {
				data.current[name].changeHandler = fn;
			}
		}
	}
}

// resolver will have two funtions with a mode property to tell caller which function to use
// The reason for two functions is that we want to avoid async altogether if we know our validation is sync
function yupResolver(schema, options = {mode: 'sync'}) {
	let {mode} = options;

	return {
		mode,
		validateSync: function (values, schemaOpts) {
			// yup works by throwing error so we will catch to update errors
			try {
				let result = schema.validateSync(values, Object.assign({abortEarly: false}, schemaOpts))
				return {
					values: result,
					errors: {}
				};
			} catch (e) {
				let inner = e.inner;
				return {
					errors: inner.reduce((p, {path, message, type}) => {
						if (!p[path]) {
							p[path] = {message, type};
						}

						if (schemaOpts.criteriaMode === "all") {
							const types = p[path].types;
							const messages = types && types[type];

							p[path] = {
								...p[path],
								types: {
									...(p[path] && p[path].types ? p[path].types : {}),
									[type]: messages ? [].concat(messages, message) : message
								}
							}
						}
						return p;
					}, {}),
					values: {}
				};
			}
		},
		validateAsync: async function (values, schemaOpts) {
			// yup works by throwing error so we will catch to update errors map
			try {
				let result = await schema.validate(values, schemaOpts)
				return {
					values: result,
					errors: {}
				};
			} catch (e) {
				let inner = e.inner;
				return {
					errors: inner.reduce((p, {path, message, type}) => {
						if (!p[path]) {
							p[path] = {message, type};
						}
						if (schemaOpts.criteriaMode === "all") {
							const types = p[path].types;
							const messages = types && types[type];

							p[path] = {
								...p[path],
								types: {
									...(p[path] && p[path].types ? p[path].types : {}),
									[type]: messages ? [].concat(messages, message) : message
								}
							}
						}
						return p;
					}),
					values: {}
				};
			}
		}
	}
}

function useController({name, control: {getField, formState, register, registerChangeHandler}}) {
	// register as controlled so that we control the value and changes
	const {ref} = register(name, {controlled: true});
	// need to have the value in a useState so that component scoped to useController will always render when val changes
	const [val, setVal] = useState(getField(name).value);

	// If there is an issue we should check here. This code was changed on payroll, presumably for some reason.
	useEffect(() => {
		// keep value in sync with form value
		registerChangeHandler(name, (value) => {
			setVal(value);
		})
	}, [getField(name), getField(name)._key]);

	useEffect(() => {
		// if the key changes we will want to update the value state to reflect an potential changes as a result of new field
		// changes made AFTER append will be caught in useEffect above
		setVal(getField(name).value);
	}, [getField(name)._key]);

	return {
		field: {
			onChange(value) {
				// controlled inputs can't assume value is in shape of an event so we will fake it
				getField(name).onChange({target: {value: value}});
			},
			onBlur() {
				getField(name).onBlur();
			},
			value: val,
			ref: ref
		},
		fieldState: {
			invalid: !getField(name).isValid,
			isTouched: getField(name).isTouched,
			isDirty: getField(name).isDirty,
			error: formState.errors[name]
		},
		formState
	}
}

function useFieldArray({
												 name,
												 control: {
													 getField,
													 onFieldChange,
													 onFieldBlur,
													 registerPosition,
													 formState,
													 register,
													 setValue,
													 registerChangeHandler,
													 registerFieldArray,
													 defaultValuesRef
												 },
												 idField = 'id',
												 defaultFieldValues = {}
											 }) {
	// useEffect seems like the natural choice here but we don't want to wait for after render since that will inevitably lead to timing-related bugs
	let fieldArray = getField(name);
	if (!fieldArray) {
		registerFieldArray(name);
		fieldArray = getField(name);
	}

	// similar to use-case in parent useForm, we don't want to force users to register before watching since register will often
	// be spread into the input itself
	let watchFieldCache = useRef({});

	// keep track of the size of the array
	let sizeRef = useRef(0);

	// eslint-disable-next-line no-unused-vars
	const [_, forceRender] = useReducer((p) => !p, false);

	// if any rows have the named field changed, we will trigger a re-render regardless of whether formState has been changed
	// it doesn't make any sense to return from this function since it will watch all rows and therefore there are many values
	function watchArrayField(name) {
		if (!fieldArray.fields[name]) {
			watchFieldCache.current[name] = true;
			return;
		}
		fieldArray.fields[name].isWatched = true;
	}

	// should check to see if item is defined in values array and if not return the default values defined
	// in the parent form
	function getItemValues(item, fieldName) {
		let values = fieldArray.fieldValues[item[idField]];
		if (values) {
			// getValues in parent will be recursive so we can
			let newValues = {};
			let keys = Object.keys(values);
			for (let i = 0; i < keys.length; i++) {
				newValues[keys[i]] = values[keys[i]].value;
			}
			if (fieldName) {
				return newValues[fieldName];
			} else {
				return newValues;
			}
		} else {
			let retMap = (defaultValuesRef.current[name] || {});
			return fieldName ? retMap[fieldName] : retMap;
		}
	}

	function addItem(obj) {
		// populate any missing fields from defaultFieldValues
		let newObj = {...obj};
		let keys = Object.keys(defaultFieldValues);
		for (let i = 0; i < keys.length; i++) {
			if (newObj[keys[i]] === undefined) {
				newObj[keys[i]] = defaultFieldValues[keys[i]];
			}
		}

		// now that we have a fully formed object, we will create the fieldValues entry
		let fieldValue = {};
		keys = Object.keys(newObj);
		for (let i = 0; i < keys.length; i++) {
			let ref = createRef();
			// onChange and onBlur will be provided during registration
			fieldValue[keys[i]] = {
				ref: ref,
				isTouched: false,
				isDirty: false,
				isWatched: watchFieldCache.current[keys[i]] || false,
				isValid: true,
				value: newObj[keys[i]],
				// Can look at removing this, somehow it was working for Jordan on master without this so who the hell knows
				_key: uuidv4() // Need to make sure that remove/append will update controllers who use form field state, therefore we need a unique key per instance regardless of model id
			};
		}

		// update the fieldValues map with the newly create field
		fieldArray.fieldValues[newObj[idField]] = fieldValue;
		forceRender();
	}

	function append(val, opts) {
		if (val instanceof Array) {
			// if the user passes in an array we will loop over the items,
			// create a new map that includes the defaultFieldValues
			// assoc the new field onto
			for (let i = 0; i < val.length; i++) {
				addItem(val[i]);
				// set index for new item
				fieldArray.fieldValues[val[i][idField]].index = sizeRef.current++;
			}
		} else {
			addItem(val);
			fieldArray.fieldValues[val[idField]].index = sizeRef.current++;
		}
	}

	function remove(val, opts) {
		if (val) {
			delete fieldArray.fieldValues[val[idField]];
		} else {
			// TODO: Implement remove code
			fieldArray.fieldValues = {};
		}
		forceRender();
	}

	function setItemValue(item, fieldName, value, opts) {
		// need to update the value in the field map and also potentially update formState
		// it would be super dope to keep the plumbing from the parent hook
		let vMap = {fieldId: item[idField], values: {[fieldName]: value}};
		setValue(name, vMap, opts);
	}

	function registerField(item, fieldName, opts) {
		const registerRef = (ref) => (el) => {
			if (!el) {
				return;
			}

			ref.current = el;
			if (getItemValues(item, fieldName) !== undefined) {
				el.value = getItemValues(item, fieldName);
			}
		}

		const onChange = (e) => {
			onFieldChange(name, item[idField], fieldName, e.target.value);
		}

		const onBlur = (e) => {
			onFieldBlur(name, item[idField], fieldName);
		}

		if (fieldArray.fieldValues[item[idField]][fieldName]) {
			fieldArray.fieldValues[item[idField]][fieldName].onChange = onChange;
			fieldArray.fieldValues[item[idField]][fieldName].onBlur = onBlur;
			return {
				ref: registerRef(fieldArray.fieldValues[item[idField]][fieldName].ref),
				onChange,
				onBlur,
				name: fieldName
			};
		}

		// This should never happen since `addItem` will be called any time a row is added and
		// that function should populate the map.
		let ref = createRef();
		fieldArray.fieldValues[item[idField]][fieldName] = {
			ref: ref,
			isTouched: false,
			isDirty: false,
			isWatched: watchFieldCache.current[fieldName] || false,
			isValid: true,
			value: undefined, // TODO: think about this
			onChange,
			onBlur,
			order: registerPosition.current++
		}

		return {
			ref: registerRef(ref),
			onChange,
			onBlur,
			name: fieldName
		}
	}

	const fields = Object.keys(fieldArray.fieldValues).map((itemId) => {
		let obj = {[idField]: itemId};
		obj = Object.keys(fieldArray.fieldValues[itemId]).reduce((p, c) => {
			p[c] = fieldArray.fieldValues[itemId][c].value;
			return p;
		}, obj);
		return obj;
	});

	return {
		watchArrayField,
		registerField,
		getItemValues,
		setItemValue,
		fields,
		append,
		remove,
		errors: (item) => {
			return (formState.errors[name] || {})[item[idField]] || {};
		},
		control: (item) => {
			return {
				formState,
				register: (fieldName, opts) => {
					return registerField(item, fieldName, opts);
				},
				getField: (fieldName) => {
					return fieldArray.fieldValues[item[idField]][fieldName];
				},
				registerChangeHandler: (fieldName, fn) => {
					fieldArray.fieldValues[item[idField]][fieldName].changeHandler = fn;
				}
			}
		}
	};
}

export {useForm, yupResolver, useController, useFieldArray}