import * as React from "react";
const SvgGravityFavicon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20"
        xmlSpace="preserve"
        {...props}
    >
        <path
            d="M12.4 12.8c0 3.2-2.4 4.6-4.7 4.6-2.9 0-5.1-2.1-5.1-5s2.2-5 5.1-5c2.2 0 3.8 1.2 4.3 2.6l-1.9.9c-.3-.9-1.2-1.6-2.5-1.6-1.8 0-3 1.3-3 3.1s1.3 3.2 3.1 3.2c1.4 0 2.6-.8 2.6-2.1H7.6v-1.7h4.8v1zM15 0H0v20h15V0z"
        />
    </svg>
);
export default SvgGravityFavicon;

