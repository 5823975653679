import {Checkbox, FormControlLabel} from "@material-ui/core";
import {useController} from "../utils/use-form";
import * as PropTypes from "prop-types";

function FormCheckbox(props) {
	const {name, control, color, ...restProps} = props;
	const {field} = useController({
		name: name,
		control: control
	});

	return (
		<FormControlLabel
			{...restProps}
			onChange={(e) => {
				field.onChange(e.target.checked);
			}}
			checked={!!field.value}
			inputRef={field.ref}
			control={<Checkbox color={color ? color : "primary"}/>}
		/>
	);
}

FormCheckbox.propTypes = {
	name: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	color: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool
}

export default FormCheckbox;