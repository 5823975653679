import {KeyboardDatePicker} from "@material-ui/pickers";
import {useController} from "../utils/use-form";

function FormDatePicker({initialFocusedDate, label, formProps, onBlur, onChange, size, InputProps, className, disableFuture, disablePast, required = false, disabled}) {
	const {field, fieldState} = useController({
		name: formProps.name,
		control: formProps.control
	});

	return (
		<KeyboardDatePicker
			className={className}
			animateYearScrolling
			required={required}
			autoOk
			disabled={disabled}
			disableFuture={disableFuture}
			disablePast={disablePast}
			error={fieldState.invalid}
			helperText={fieldState.error?.message}
			size={size ? size : "medium"}
			format="MM/DD/YYYY"
			initialFocusedDate={initialFocusedDate}
			inputVariant="outlined"
			openTo="date"
			variant="inline"
			label={label}
			onChange={(date, value) => {
				field.onChange(value);
				if (onChange) {
					onChange(date, value);
				}
			}}
			onBlur={(e) => {
				if (onBlur) {
					onBlur(e);
				}
				field.onBlur();
			}}
			value={field.value}
			InputProps={InputProps}
		/>
	);
}

export default FormDatePicker;