export const usStates = [
   {value: "AL"},
   {value: "AK"},
   {value: "AZ"},
   {value: "AR"},
   {value: "CA"},
   {value: "CO"},
   {value: "CT"},
   {value: "DE"},
   {value: "FL"},
   {value: "GA"},
   {value: "HI"},
   {value: "ID"},
   {value: "IL"},
   {value: "IN"},
   {value: "IA"},
   {value: "KS"},
   {value: "KY"},
   {value: "LA"},
   {value: "ME"},
   {value: "MD"},
   {value: "MA"},
   {value: "MI"},
   {value: "MN"},
   {value: "MS"},
   {value: "MO"},
   {value: "MT"},
   {value: "NE"},
   {value: "NV"},
   {value: "NH"},
   {value: "NJ"},
   {value: "NM"},
   {value: "NY"},
   {value: "NC"},
   {value: "ND"},
   {value: "OH"},
   {value: "OK"},
   {value: "OR"},
   {value: "PA"},
   {value: "RI"},
   {value: "SC"},
   {value: "SD"},
   {value: "TN"},
   {value: "TX"},
   {value: "UT"},
   {value: "VT"},
   {value: "VA"},
   {value: "WA"},
   {value: "WV"},
   {value: "WI"},
   {value: "WY"},
   {value: "DC"}
];