import * as React from "react";

function SvgShimodaFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.5 20" {...props}>
      <path
        d="M10.276 0c-5.7 0-10.3 4.7-10.2 10.4 0 2.9 1.2 5.6 3.3 7.6l.2.2 4.3-4.4 6.1 6.2.4-.4-3.9-4 2.1-2.1 4.4 4.5.3-.2c2-2 3.2-4.7 3.3-7.5-.1-5.6-4.6-10.3-10.3-10.3m9.6 10.4c0 2.6-1.1 5-2.9 6.8l-4.5-4.5-2.5 2.5-2.2-2.2-4.3 4.4c-1.8-1.9-2.9-4.4-2.9-7 0-5.4 4.3-9.7 9.6-9.7 5.4-.1 9.7 4.3 9.7 9.7"
      />
    </svg>
  );
}

export default SvgShimodaFavicon;
