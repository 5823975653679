import * as React from "react";

function SvgAccsoonFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.5 20" {...props}>
      <path
        d="M19 20c-.1 0-.1 0-.1-.1v-.1l3.7-13.6-.4.3C17.5 10.1 9.7 16.2 4.8 20H.2c-.1 0-.2 0-.2-.1v-.2C5.7 15.2 24.4.7 24.7.5c.4-.3.9-.5 1.4-.5.4 0 .8.2 1.1.5.3.5.4 1.2.2 1.7-.2.7-4.8 17.5-4.8 17.6 0 .1-.1.1-.2.1H19z"
      />
    </svg>
  );
}

export default SvgAccsoonFavicon;
