import * as React from "react";

function SvgSaramonicFavicon(props) {
  return (
    <svg
      id="Saramonic_Favicon_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 25 20"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Saramonic_Favicon_svg__Group_203">
        <g id="Saramonic_Favicon_svg__Group_198" transform="translate(12.691)">
          <path
            id="Saramonic_Favicon_svg__Path_114"
            d="M-.2 20c-.7 0-1.3-.6-1.3-1.3V1.3C-1.4.6-.9 0-.2 0 .5 0 1 .6 1 1.3v17.5c.1.6-.5 1.2-1.2 1.2"
          />
        </g>
        <g
          id="Saramonic_Favicon_svg__Group_199"
          transform="translate(6.991 -3.377)"
        >
          <path
            id="Saramonic_Favicon_svg__Path_115"
            d="M-.1 20c-.7 0-1.3-.6-1.3-1.3V8c0-.7.6-1.3 1.3-1.3.7 0 1.2.6 1.2 1.3v10.7c0 .7-.5 1.3-1.2 1.3"
          />
        </g>
        <g
          id="Saramonic_Favicon_svg__Group_200"
          transform="translate(1.291 -6.926)"
        >
          <path
            id="Saramonic_Favicon_svg__Path_116"
            d="M0 20c-.7 0-1.3-.6-1.3-1.3v-3.6c0-.7.6-1.3 1.3-1.3.7 0 1.2.6 1.2 1.3v3.6C1.2 19.4.7 20 0 20"
          />
        </g>
        <g
          id="Saramonic_Favicon_svg__Group_201"
          transform="translate(18.363 -3.377)"
        >
          <path
            id="Saramonic_Favicon_svg__Path_117"
            d="M-.3 20c.7 0 1.3-.6 1.3-1.3V8C1 7.3.4 6.7-.3 6.7c-.7 0-1.2.6-1.2 1.3v10.7c0 .7.6 1.3 1.2 1.3"
          />
        </g>
        <g
          id="Saramonic_Favicon_svg__Group_202"
          transform="translate(24.063 -6.926)"
        >
          <path
            id="Saramonic_Favicon_svg__Path_118"
            d="M-.3 20c.7 0 1.3-.6 1.3-1.3v-3.6c0-.7-.6-1.3-1.3-1.3-.7 0-1.2.6-1.2 1.3v3.6c-.1.7.5 1.3 1.2 1.3"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSaramonicFavicon;
