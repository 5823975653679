import * as React from "react";

function SvgAdministratorSettings(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.5 20" {...props}>
      <path
        d="M15.8 1.4C10.9-.4 5.5-.4.6 1.4c-.3.1-.6.5-.6 1v6.5c0 3.2 1.5 6.3 4.1 8.2L7.9 20c.2.1.5.1.7 0l3.8-2.9c2.6-1.9 4.1-5 4.1-8.2V2.4c0-.5-.3-.9-.7-1zm-2.5 5.5c0 .1-.1.1 0 0l-.5.5c-.3.3-.5.6-.8.8l-.9.9-1 1-1 1-.8.8-.6.6c-.1.1-.2.3-.4.3-.1 0-.3 0-.4-.1l-.1-.1-.2-.2-.8-.8-1-1c-.2-.3-.5-.6-.7-.8l-.3-.3s-.1-.1-.1-.2c-.1-.1-.1-.3 0-.4l.1-.1c.2-.2.3-.4.5-.5l.4-.4.1-.1c.2-.2.4-.2.5-.1.1 0 .2.1.2.2.2.1.3.2.4.3l1 1c.1.1.2.3.4.4l.4-.4L8.9 8l1.3-1.3 1.1-1.1.3-.3.1-.1c.1-.1.4-.2.5 0l.2.2c.3.4.6.6.8.9l.1.1c.1.2.1.4 0 .5z"
      />
    </svg>
  );
}

export default SvgAdministratorSettings;
