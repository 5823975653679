import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Setting from "../models/setting";
import {resetAction} from "../../utils/common-actions";

const axios = require('axios');

const getSettings = createAsyncThunk(
	'settings/getSettings',
	async (arg, thunkAPI) => {
		try {
			const response = await axios.get('/settings');
			return Setting.fromJson(response.data);
		} catch (e) {
			return null;
		}
	}
);

export { getSettings };

const initialState = {
	settings: {}
};

export const settingsSlice = createSlice({
	name: 'settings',
	initialState: initialState,
	reducers: {
		setSettings: (state, action) => {
			state.settings = action.payload;
		},
		setAnnouncement: (state, action) => {
			state.settings.announcement = action.payload;
		}
	},
	extraReducers: {
		[getSettings.fulfilled]: (state, action) => {
			state.settings = action.payload;
		},
		[resetAction]: (state, action) => {
			return initialState;
		}
	}
});

export const {setSettings, setAnnouncement} = settingsSlice.actions;

export default settingsSlice.reducer;