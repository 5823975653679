import clsx from "clsx";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
	Divider, IconButton, List, ListItem,
	makeStyles, Slide, SvgIcon,
	Typography
} from "@material-ui/core";
import {styles} from "../utils/styles";
import NumberFormat from "react-number-format";
import AddressBlock from "../components/address-block";
import {useDefaults} from "../utils/autocomplete";
import FormAutocomplete from "../components/form-autocomplete";
import useSmartForm from "../utils/smart-form";
import * as yup from "yup";
import {Autocomplete} from "@material-ui/lab";
import FormTextField from "../components/form-text-field";
import {useEffect, useState} from "react";
import FormSwitch from "../components/form-switch";
import {useDispatch, useSelector} from "react-redux";
import {
	addCustomShippingAddress,
	getCheckoutSession,
	getShoppingCart, submitOrder,
	createEmail as createEmailThunk,
	editEmail as editEmailThunk,
	deleteEmail as deleteEmailThunk, replaceShippingAddresses, getPaymentMethods
} from "../data/slices/place-order";
import store from "../data/store";
import Spacer from "../components/spacer";
import * as Icons from '../icons';
import {useSnackbar} from "notistack";
import axios from "axios";
import {useHistory} from "react-router";
import * as React from "react";
import Iframe from "react-iframe";
import FormTelephoneInput from "../components/form-telephone-input";
import {formatPhoneNumber} from "../utils/utilities";
import moment from "moment";
import FormCardInput from "../components/form-card-input";

const useStyles = makeStyles((theme) => ({
	...styles,
	bold: {
		fontWeight: 500
	},
	billingSpacer: {
		display: 'block',
		height: 80
	},
	shipToContainer: {
		height: 80
	},
	shipToHeader: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%'
	},
	searchShipTos: {
		position: 'absolute',
		right: 0,
		top: 0
	},
	createShipTo: {
		position: 'absolute',
		right: 32,
		top: 0
	},
	error: {
		color: theme.palette.error.main
	},
	switchWidth: {
		width: 200
	},
	rightSwitch: {
		marginRight: 0,
		marginLeft: 16
	},
	breakOnSpace: {
		whiteSpace: 'break-spaces'
	}
}));

const validateCard = require('card-validator');


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

function PlaceOrderCheckout() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const autocompleteDefaults = useDefaults();
	const {enqueueSnackbar} = useSnackbar();
	const [isLoading, setIsLoading] = useState(true);
	const [isViewingShippingTerms, setIsViewingShippingTerms] = useState(false);
	const [emailDropdownOpen, setEmailDropdownOpen] = useState(false);
	const [customAddressOpen, setCustomAddressOpen] = useState(false);
	const [editCustomAddressOpen, setEditCustomAddressOpen] = useState(false);
	const [editEppAddressOpen, setEditEppAddressOpen] = useState(false);
	const [checkingValidPo, setCheckingValidPo] = useState(false);
	const [poValid, setPoValid] = useState(false);
	const [showShipToSearch, setShowShipToSearch] = useState(false);
	const [editingEmail, setEditingEmail] = useState(null);
	const [promptingCreateEmail, setPromptingCreateEmail] = useState('');
	const [deletingEmail, setDeletingEmail] = useState(null);
	const [selectOnFilePayment, setSelectOnFilePayment] = useState(false);
	const shoppingCartItems = useSelector((state) => state.placeOrder.shoppingCart);
	const [salesTax, setSalesTax] = useState(null);
	const cartInfoMap = shoppingCartItems.reduce((p, c) => {
		p.totalItems = p.totalItems += c.quantity;
		p.dealerNetPricing = p.dealerNetPricing += (c.quantity * parseFloat(c.dealerNet));
		p.totalPricing = p.totalPricing += (c.quantity * c.actualPrice);
		return p;
	}, {totalItems: 0, dealerNetPricing: 0.0, totalPricing: 0.0});
	const uniqueSkus = shoppingCartItems.length;
	const totalItems = cartInfoMap.totalItems;
	const dealerNetPricing = cartInfoMap.dealerNetPricing;
	const totalPricing = cartInfoMap.totalPricing + (salesTax || 0);
	const discounts = totalPricing - (salesTax || 0) - dealerNetPricing;
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);
	const shoppingCartLoading = useSelector((state) => state.placeOrder.shoppingCartLoading);
	const emailOptions = useSelector((state) => state.placeOrder.checkoutEmails);
	const addressOptions = useSelector((state) => state.placeOrder.checkoutShippingAddresses);
	const shippingOptions = useSelector((state) => state.placeOrder.checkoutShippingMethods);
	const checkoutCustomer = useSelector((state) => state.placeOrder.checkoutCustomer);
	const paymentMethods = useSelector((state) => state.placeOrder.paymentMethods);
	const currentUser = useSelector((state) => state.users.currentUser);
	const userRoles = useSelector((state) => state.users.roles);
	const currentUserRole = userRoles.find((role) => role.title === currentUser.userRole);
	const inSepMode = currentUser.userRole === "EPP";
	const isInternal = currentUserRole.internal;
	const canManageEmails = ["Administrator", "Sales Manager", "Salesman", "Super Administrator"].includes(currentUserRole.title);
	const hasSpecialItem = store.getState().placeOrder.shoppingCart.some((itm) => itm.specialProduct);
	const checkoutSchema = yup.object().shape({
		shippingMethodInput: yup.string().required('Shipping method is required').test({
			name: 'max',
			test: (value) => {
				const shippingMethods = store.getState().placeOrder.checkoutShippingMethods;
				const isExisting = shippingMethods.some((itm) => itm.normal.toUpperCase().trim() === value.toUpperCase().trim());
				const lessThanFifteen = value?.length || value?.length === 0 ? value.length <= 15 : true;

				return isExisting || lessThanFifteen;
			},
			message: 'Must be less than 15 characters'
		}),
		emails: yup.array().max(2, 'Must be at most two emails'),
		emailsInput: yup.string().max(59, 'Must be less than 60 characters').email('Must be a valid email').trim(),
		address: yup.object().required('Shipping address required').nullable(),
		poNumber: yup.string().required('PO Number Required').test({
			name: 'poNumberMax',
			test: (value) => {
				const newMax = hasSpecialItem ? 17 : 20;
				const isLessThanMax = value?.length || value?.length === 0 ? value.length <= newMax : true;

				return isLessThanMax;
			},
			message: `Must be ${hasSpecialItem ? "17" : "20"} characters or less`
		}),
		orderNotes: yup.string().max(1000, 'Must be 1000 characters or less'),
		cardNumber: yup.string().when('paymentProfileToUse', (paymentProfileToUse, schema) => {
			if (store.getState().users?.currentUser?.userRole === "EPP") {
				if (!!paymentProfileToUse) {
					return schema;
				} else {
					return schema.test('cardNumber', "Card number invalid", (value) => {
						return validateCard.number(value).isValid;
					});
				}
			} else {
				return schema;
			}
		}),
		cardExpiration: yup.string().when('paymentProfileToUse', (paymentProfileToUse, schema) => {
			if (store.getState().users?.currentUser?.userRole === "EPP") {
				if (!!paymentProfileToUse) {
					return schema;
				} else {
					return schema.test('cardExpiration', "Card expiration invalid", (value) => {
						return validateCard.expirationDate(value).isValid;
					});
				}
			} else {
				return schema;
			}
		}),
		cardCvc: yup.string().when(['cardNumber', 'paymentProfileToUse'], (number, paymentProfileToUse, schema) => {
			if (store.getState().users?.currentUser?.userRole === "EPP" && !!!paymentProfileToUse) {
				const cardInfo = validateCard.number(number);
				const isAmex = cardInfo?.card?.type === "american-express";

				return schema.test('check', "Card CVC invalid", (cvc) => {
					return validateCard.cvv(cvc, isAmex ? 4 : 3).isValid;
				});
			} else {
				return schema;
			}
		}),
		eppBillingAddress: yup.object().nullable().test('eppBillingAddress', "Billing address required", (value) => {
			const currentUser = store.getState().users.currentUser;
			const inSepMode = currentUser.userRole === "EPP";

			return !inSepMode ? true : !!value;
		}),
		paymentProfileToUse: yup.object().nullable()
	});

	const {
		register,
		handleSubmit,
		formState: {errors},
		control,
		watch,
		getField,
		getValues,
		setValue,
		reset
	} = useSmartForm({
		schema: checkoutSchema,
		defaultValues: {
			"shipComplete": false,
			"hotOrder": false,
			"onMemo": orderingFor?.accountType === "#",
			"chargeCard": false,
			"shippingMethodInput": 'UPS Ground',
			"emails": isInternal ? [] : [currentUser.email],
			"emailsInput": '',
			"address": null,
			"addressInput": '',
			"eppBillingAddress": null,
			"poNumber": '',
			"orderNotes": '',
			"cardNumber": '',
			"cardExpiration": '',
			"cardCvc": '',
			"paymentProfileToUse": null
		}
	});

	register('paymentProfileToUse');
	const selectedAddress = watch('address');
	const selectedEppBillingAddress = watch('eppBillingAddress');
	const paymentProfileToUse = watch('paymentProfileToUse');


	useEffect(() => {
		(async function () {
			setIsLoading(true);
			await dispatch(getCheckoutSession());
			await dispatch(getShoppingCart());
			const shippingAddresses = store.getState().placeOrder.checkoutShippingAddresses;
			const defaultShippingAddress = shippingAddresses.find((itm) => itm.defaultShipping && itm.name);
			const defaultBillingAddress = shippingAddresses.find((itm) => itm.defaultBilling);

			reset({
				"shipComplete": false,
				"hotOrder": false,
				"onMemo": orderingFor?.accountType === "#",
				"chargeCard": false,
				"shippingMethodInput": 'UPS Ground',
				"emails": isInternal ? [] : [currentUser.email],
				"emailsInput": '',
				"address": defaultShippingAddress ? defaultShippingAddress : defaultBillingAddress ? defaultBillingAddress : null,
				"addressInput": defaultShippingAddress ? defaultShippingAddress.address : defaultBillingAddress ? defaultBillingAddress.address : '',
				"eppBillingAddress": inSepMode ? defaultShippingAddress ? defaultShippingAddress : defaultBillingAddress ? defaultBillingAddress : null : null,
				"poNumber": inSepMode ? `${currentUser.firstName.substr(0, 1)}${currentUser.lastName.substr(0, 1)}${moment().format('MMDDYYYYHHmmss')}` : '',
				"orderNotes": '',
				"cardNumber": '',
				"cardExpiration": '',
				"cardCvc": '',
				"paymentProfileToUse": null
			}, {
				keepErrors: false,
				keepDirty: false,
				keepDefaultValues: false,
				keepTouched: false,
				keepIsValid: false
			});

			if (inSepMode && shippingAddresses.length === 0) {
				setCustomAddressOpen(true);
			}

			if (inSepMode) {
				setPoValid(true);
			}

			setIsLoading(false);
		})();
		// eslint-disable-next-line
	}, [orderingFor]);

	useEffect(() => {
		if (selectedAddress && inSepMode) {
			(async () => {
				setSalesTax(null);
				const response = await axios.post('/salesTax', {
					streetAddress: selectedAddress.address,
					city: selectedAddress.city,
					state: selectedAddress.state,
					zipCode: selectedAddress.zipCode
				});

				setSalesTax(response.data.taxCalculated);
			})();
		}
	}, [inSepMode, selectedAddress]);

	async function createEmail() {
		const responseProm = dispatch(createEmailThunk({email: promptingCreateEmail}));
		setPromptingCreateEmail('');
		const response = await responseProm;
		enqueueSnackbar(response.payload.data.message, {
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			variant: response.payload.data.succeeded ? "success" : "error"
		});
	}

	async function editEmail(data) {
		const oldEmailObject = editingEmail;
		const newEmail = data.email;

		const response = await dispatch(editEmailThunk({id: oldEmailObject.realId, email: newEmail}));
		enqueueSnackbar(response.payload.data.message, {
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			variant: response.payload.data.succeeded ? "success" : "error"
		});
	}

	async function deleteEmail() {
		const responseProm = dispatch(deleteEmailThunk({id: deletingEmail.realId}));
		setDeletingEmail('');
		const response = await responseProm;
		enqueueSnackbar(response.payload.data.message, {
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			variant: response.payload.data.succeeded ? "success" : "error"
		});
	}

	function promptCreateEmail(email) {
		if (canManageEmails) {
			setPromptingCreateEmail(email);
		}
	}

	async function checkValidPo() {
		setCheckingValidPo(true);
		const response = await axios.get('/uniquePoNumber', {
			params: {
				vicariousCompany: checkoutCustomer?.id,
				poNumber: getValues('poNumber')
			}
		});
		if (response.data.valid) {
			setPoValid(true);
		} else {
			setPoValid(false);
		}
		setCheckingValidPo(false);
	}

	async function submitCheckout(data) {
		if (isLoading) {
			return;
		}
		setIsLoading(true);

		if (!poValid) {
			enqueueSnackbar("PO number already on file", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
			setIsLoading(false);
			return;
		}

		const shippingMethodMatch = shippingOptions.find((itm) => {
			return itm.normal.toUpperCase().trim() === data.shippingMethodInput.toUpperCase().trim();
		})?.weird;

		try {
			const result = await dispatch(submitOrder({
				vicariousCompany: checkoutCustomer.id,
				shippingAddress: data.address,
				eppBillingAddress: data.eppBillingAddress,
				shipComplete: data.shipComplete,
				hotOrder: data.hotOrder,
				onMemo: data.onMemo,
				chargeCard: data.chargeCard,
				shippingMethod: shippingMethodMatch ? shippingMethodMatch : data.shippingMethodInput,
				poNumber: data.poNumber,
				emails: data.emails,
				notes: data.orderNotes,
				total: totalPricing,
				subtotal: dealerNetPricing,
				shipping: (totalPricing > 1000 || inSepMode) && getValues('shippingMethodInput') === "UPS Ground" ? "Free" : "Addl. Charge",
				discount: discounts,
				salesTax: (salesTax || 0),
				cardInfo: {
					number: data.cardNumber.replace(/\D/g, ''),
					expiration: `20${data.cardExpiration.substring(5)}-${data.cardExpiration.substring(0, 2)}`,
					cvc: data.cardCvc
				},
				paymentProfileToUse: data.paymentProfileToUse
			}));

			if (result.payload.data.succeeded) {
				await dispatch(getShoppingCart());
				enqueueSnackbar(result.payload.data.message, {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					variant: "success"
				});
				history.push('/home');
			} else {
				if (result.payload.data.refreshCards) {
					setValue('chargeCard', false);
					setValue('cardNumber', '');
					setValue('cardExpiration', '');
					setValue('cardCvc', '');
					setValue('paymentProfileToUse', null);

					await dispatch(getPaymentMethods());
				}

				enqueueSnackbar(result.payload.data.message, {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					variant: "error"
				});
				setIsLoading(false);
			}
		} catch (e) {
			enqueueSnackbar("Something went wrong placing this order", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
			setIsLoading(false);
		}
	}

	if (isLoading || shoppingCartLoading) {
		return (
			<div className={clsx(classes.row, classes.alignCenterCenter, "flex")}>
				<CircularProgress size={54}/>
			</div>
		);
	}

	return (
		<>
			<div className={clsx(classes.row, classes.alignCenter)}>
				<div className={clsx(classes.column, "flex50")}>
					<Card raised>
						<CardContent className={classes.column}>
							<div className={classes.row}>
								<div className={clsx(classes.column, "flex45")}>
									<div className={clsx(classes.row, classes.alignStartCenter, classes.relativePosition)}>
										<div className={classes.shipToContainer}>
											<Typography variant="h5">Bill To Address</Typography>
										</div>
										{inSepMode &&
										<IconButton
											className={classes.searchShipTos}
											color='primary'
											size='small'
											onClick={() => {
												setEditEppAddressOpen(true);
											}}
											disabled={!!!selectedEppBillingAddress}
										>
											<SvgIcon component={Icons.Edit} viewBox="-2.5 -2 24 24"/>
										</IconButton>}
									</div>
									{inSepMode ?
										selectedEppBillingAddress ?
											<AddressBlock
												name={selectedEppBillingAddress?.name}
												name2={selectedEppBillingAddress?.name2}
												address={selectedEppBillingAddress?.address}
												address2={selectedEppBillingAddress?.address2}
												city={selectedEppBillingAddress?.city}
												state={selectedEppBillingAddress?.state}
												zip={selectedEppBillingAddress?.zipCode}
											/> :
											<Typography variant="body2" className={classes.error}>Bill To Required</Typography> :
										<AddressBlock
											name={checkoutCustomer.name}
											name2={checkoutCustomer.name2}
											address={checkoutCustomer.address}
											address2={checkoutCustomer.address2}
											city={checkoutCustomer.city}
											state={checkoutCustomer.state}
											zip={checkoutCustomer.zip}
										/>
									}
								</div>
								<span className="flex10"/>
								<div className={clsx(classes.column, "flex45")}>
									<div className={clsx(classes.relativePosition, classes.shipToContainer)}>
										<Slide
											direction='right'
											in={showShipToSearch}
										>
											<div>
												<FormAutocomplete
													{...autocompleteDefaults}
													formProps={{name: "address", control: control}}
													className={classes.shipToHeader}
													getOptionLabel={(x) => `${x.address}`}
													renderOption={(option, state) => {
														return (
															<div className={classes.column}>
																<span>{option.name}</span>
																<span>{option.name2}</span>
																<span>{option.address}</span>
																<span>{option.address2}</span>
																<span>{option.city}, {option.state} {option.zipCode}</span>
															</div>
														);
													}}
													options={addressOptions}
													getOptionSelected={(o, v) => {
														return o === v;
													}}
													onChange={(e, v, r) => {
														setValue('addressInput', v ? v.address : '');
													}}
													renderInputParams={{
														label: "Ship To Address",
														margin: "normal",
														name: "addressInput",
														register: register,
														error: !!errors.address || !!errors.addressInput,
														helperText: errors.addressInput?.message || errors.address?.message,
														required: true,
														onBlur: (e) => setShowShipToSearch(!showShipToSearch)
													}}
												/>
											</div>
										</Slide>
										<Slide
											direction='left'
											in={!showShipToSearch}
											appear={false}
										>
											<div className={clsx(classes.row, classes.alignStartCenter)}>
												<Typography className={classes.shipToHeader} variant="h5">Ship To Address</Typography>
												{!inSepMode ?
													<>
														<IconButton
															className={classes.createShipTo}
															color='primary'
															size='small'
															onClick={() => {
																setCustomAddressOpen(true);
															}}
														>
															<SvgIcon component={Icons.Add} viewBox="-2 -2 24 24"/>
														</IconButton>
														<IconButton
															className={classes.searchShipTos}
															color='primary'
															size='small'
															onClick={() => {
																setShowShipToSearch(!showShipToSearch);
																if (!!!showShipToSearch) {
																	setTimeout(() => {
																		getField('addressInput')?.ref?.current?.focus();
																	}, 300)
																}
															}}
														>
															<SvgIcon component={Icons.Search} viewBox="-2 -2 24 24"/>
														</IconButton>
													</> :
													<IconButton
														className={classes.searchShipTos}
														color='primary'
														size='small'
														onClick={() => {
															setEditCustomAddressOpen(true);
														}}
														disabled={!!!selectedAddress}
													>
														<SvgIcon component={Icons.Edit} viewBox="-2.5 -2 24 24"/>
													</IconButton>
												}
											</div>
										</Slide>
									</div>

									{selectedAddress ?
										<AddressBlock
											name={selectedAddress?.name}
											name2={selectedAddress?.name2}
											address={selectedAddress?.address}
											address2={selectedAddress?.address2}
											city={selectedAddress?.city}
											state={selectedAddress?.state}
											zip={selectedAddress?.zipCode}
										/> :
										<Typography variant="body2" className={classes.error}>Ship To Required</Typography>}
								</div>
							</div>
							<br/>
							<Divider/>
							<br/>
							<div className={classes.row}>
								<div className={clsx("flex", classes.row, classes.alignCenter)}>
									<FormSwitch
										className={classes.switchWidth}
										name="shipComplete"
										control={control}
										label="Ship Complete"
									/>
								</div>
								{(!inSepMode || paymentMethods.length > 0) &&
								<div className={clsx("flex", classes.row, classes.alignCenter)}>
									<FormSwitch
										className={clsx(classes.switchWidth)}
										name="chargeCard"
										control={control}
										label="Use Card on File"
										onChange={(e) => {
											if (inSepMode && e.target.checked) {
												setValue('cardNumber', '');
												setValue('cardExpiration', '');
												setValue('cardCvc', '');
												setSelectOnFilePayment(true);
											} else if (!e.target.checked) {
												setValue('paymentProfileToUse', null);
											}
										}}
									/>
								</div>}
							</div>
							<div className={classes.row}>
								{isInternal &&
								<>
									<div className={clsx("flex", classes.row, classes.alignCenter)}>
										<FormSwitch
											className={classes.switchWidth}
											name="hotOrder"
											control={control}
											label="Hot Order"
										/>
									</div>
									<div className={clsx("flex", classes.row, classes.alignCenter)}>
										{orderingFor?.accountType === "#" &&
										<FormSwitch
											className={clsx(classes.switchWidth)}
											name="onMemo"
											control={control}
											label="On Memo"
										/>}
									</div>
								</>}
							</div>
							<Spacer vertical={true}/>
							{(inSepMode && !!!paymentProfileToUse) &&
							<div className={classes.row}>
								<FormCardInput
									numberFormProps={{
										name: "cardNumber",
										control: control
									}}
									dateFormProps={{
										name: "cardExpiration",
										control: control
									}}
									cvcFormProps={{
										name: "cardCvc",
										control: control
									}}
								/>
							</div>
							}
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<Autocomplete
									{...autocompleteDefaults}
									clearOnBlur={false}
									freeSolo={true}
									className="flex45"
									options={shippingOptions}
									getOptionLabel={(x) => `${x.normal}`}
									getOptionSelected={(o, v) => {
										return o.normal === v.normal;
									}}
									onInputChange={(e, v, r) => {
										setValue('shippingMethodInput', v, {shouldValidate: true});
									}}
									renderInput={(params) => {
										return (
											<FormTextField
												{...params}
												formProps={{name: 'shippingMethodInput', control: control}}
												className="flex45"
												label="Shipping Method"
												variant="outlined"
												margin="normal"
												fullWidth
												required
											/>
										);
									}}
								/>
								<span className="flex10"/>
								<FormTextField
									className="flex45"
									formProps={{name: 'poNumber', control: control}}
									variant="outlined"
									label="Purchase Order Number"
									margin="normal"
									onBlur={(e) => checkValidPo()}
									required={true}
									disabled={inSepMode}
								/>
							</div>
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<FormAutocomplete
									{...autocompleteDefaults}
									disabled={inSepMode}
									autoHighlight={false}
									formProps={{name: "emails", control: control}}
									className="flex"
									getOptionLabel={(x) => `${x}`}
									options={emailOptions}
									getOptionSelected={(o, v) => {
										return o.address === v;
									}}
									renderOption={(option, state) => {
										return (
											<div className={clsx(classes.row, classes.alignSpaceBetweenCenter, classes.fullWidth)}>
												<span>{option.address}</span>
												{canManageEmails && option.realId &&
												<div>
													<IconButton
														size="small"
														color="primary"
														onClick={(e) => {
															e.stopPropagation();
															setEditingEmail(option);
														}}
													>
														<SvgIcon component={Icons.Edit} viewBox="-2.5 -2 24 24"/>
													</IconButton>
													<IconButton
														className={classes.error}
														size="small"
														onClick={(e) => {
															e.stopPropagation();
															setEmailDropdownOpen(false);
															setDeletingEmail(option);
														}}
													>
														<SvgIcon component={Icons.Trash} viewBox="-4.5 -2 24 24"/>
													</IconButton>
												</div>}
											</div>
										);
									}}
									open={emailDropdownOpen}
									onOpen={(e) => {
										if (getValues('emails') && getValues('emails').length >= 2) {
											return
										}

										setEmailDropdownOpen(true);
									}}
									onClose={(e, r) => {
										setEmailDropdownOpen(false);
									}}
									onChange={(e, v, r) => {
										const newOption = v[v.length - 1];

										if (r === "create-option") {
											try {
												yup.reach(checkoutSchema, 'emailsInput').validateSync(newOption);
												const existing = emailOptions.find((itm) => itm.address.trim().toLowerCase() === newOption.trim().toLowerCase());
												if (!existing) {
													promptCreateEmail(newOption);
												}
												setValue('emails', [...v.slice(0, -1), newOption]);
											} catch (e) {
												setValue('emails', v.slice(0, -1));
											}
										} else if (r === "select-option") {
											setValue('emails', [...v.slice(0, -1), newOption.address]);
										}
										setValue('emailsInput', '', {shouldValidate: true});
									}}
									renderInputParams={{
										label: "Order Email (Max 2)",
										margin: "normal",
										name: "emailsInput",
										register: register,
										error: !!errors.emails || !!errors.emailsInput,
										helperText: errors.emailsInput?.message || errors.emails?.message,
										onBlur: (e) => setValue('emailsInput', '')
									}}
									limitTags={2}
									multiple
									freeSolo
									filterSelectedOptions
								/>
							</div>
							<div className={classes.row}>
								<FormTextField
									className="flex"
									formProps={{name: 'orderNotes', control: control}}
									variant="outlined"
									label="Notes to MAC Group"
									margin="normal"
									multiline
									rowsMax={10}
									rows={10}
								/>
							</div>
						</CardContent>
					</Card>
				</div>
				<span className="flex5"/>
				<div className={clsx(classes.column, "flex30")}>
					<Card raised>
						<CardHeader title="Order Summary"/>
						<CardContent className={classes.column}>
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<Typography variant="subtitle1">Total SKUs</Typography>
								<Typography className={classes.bold} variant="subtitle1">{uniqueSkus}</Typography>
							</div>
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<Typography variant="subtitle1">Total Items</Typography>
								<Typography className={classes.bold} variant="subtitle1">{totalItems}</Typography>
							</div>
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<Typography variant="subtitle1">Dealer Net Pricing</Typography>
								<NumberFormat
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={2}
									value={dealerNetPricing}
									displayType="text"
									prefix="$"
									renderText={(formattedValue, customProps) => <Typography className={classes.bold}
																																					 variant="subtitle1">{formattedValue}</Typography>}
								/>
							</div>
							{/*{discounts !== 0 && discounts !== -0 &&*/}
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<Typography variant="subtitle1">Discounts</Typography>
								<NumberFormat
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={2}
									value={discounts}
									displayType="text"
									prefix="$"
									renderText={(formattedValue, customProps) => <Typography className={classes.bold}
																																					 variant="subtitle1">{formattedValue}</Typography>}
								/>
							</div>
							{/*}*/}
							{!inSepMode &&
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<div className={classes.row}>
									<Typography variant="subtitle1">Shipping</Typography>
									<IconButton
										color='primary'
										size='small'
										onClick={() => {
											setIsViewingShippingTerms(true);
										}}
									>
										<SvgIcon component={Icons.Information} viewBox="-2 -2 24 24"/>
									</IconButton>
								</div>
								<Typography className={classes.bold}
														variant="subtitle1">{totalPricing > 1000 && getValues('shippingMethodInput') === "UPS Ground" ? "Free" : "Additional Charge"}</Typography>
							</div>}
							{inSepMode &&
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<Typography variant="subtitle1">Sales Tax</Typography>
								{salesTax === null ?
									<Typography className={classes.bold}
															variant="subtitle1">TBD</Typography> :
									<NumberFormat
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={2}
										value={salesTax}
										displayType="text"
										prefix="$"
										renderText={(formattedValue, customProps) => <Typography className={classes.bold}
																																						 variant="subtitle1">{formattedValue}</Typography>}
									/>
								}
							</div>}
							<br/>
							<Divider/>
							<br/>
							<div className={clsx(classes.row, classes.alignSpaceBetween)}>
								<Typography variant="subtitle1">Total</Typography>
								<NumberFormat
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={2}
									value={totalPricing}
									displayType="text"
									prefix="$"
									renderText={(formattedValue, customProps) => <Typography className={classes.bold}
																																					 variant="subtitle1">{formattedValue}</Typography>}
								/>
							</div>
							<br/>
							{paymentProfileToUse &&
								<>
									<div className={clsx(classes.row)}>
										<Typography
											className='flex'
											variant="subtitle1"
											align={"center"}
										>
											Using card on file ending in {paymentProfileToUse.payment.creditCard.cardNumber.substring(4)}
										</Typography>
									</div>
									<br/>
								</>
							}
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit(submitCheckout)}
								disabled={totalItems === 0 || checkingValidPo}
							>
								Place Order
							</Button>
						</CardContent>
					</Card>
				</div>
			</div>
			{customAddressOpen && <CustomAddress
				onUse={(data) => {
					const valueObj = {
						name: data.name.toUpperCase(),
						name2: data.name2.toUpperCase(),
						address: data.address.toUpperCase(),
						address2: data.address2.toUpperCase(),
						city: data.city.toUpperCase(),
						state: data.state.toUpperCase(),
						zipCode: data.zipCode.toUpperCase(),
						phoneNumber: data.phoneNumber.replace(/\D*/g, "")
					};
					if (addressOptions.length === 0) {
						setValue(
							'eppBillingAddress',
							valueObj,
							{shouldValidate: true});
					}
					dispatch(addCustomShippingAddress(valueObj));
					setValue(
						'address',
						valueObj,
						{shouldValidate: true});
					setValue('addressInput', valueObj.address);
					if (inSepMode) {
						// noinspection JSIgnoredPromiseFromCall
						axios.post("/sepAddress", {
							name: data.name.toUpperCase().substr(0, 25),
							name2: data.name2.toUpperCase().substr(0, 25),
							address: data.address.toUpperCase().substr(0, 25),
							address2: data.address2.toUpperCase().substr(0, 25),
							city: data.city.toUpperCase().substr(0, 18),
							state: data.state.toUpperCase().substr(0, 2),
							zipCode: data.zipCode.toUpperCase().substr(0, 5),
							phoneNumber: data.phoneNumber.replace(/\D*/g, "")
						});
					}
				}}
				onExited={() => {
					setCustomAddressOpen(false);
				}}
				inSepMode={inSepMode}
			/>}
			{editCustomAddressOpen && <CustomAddress
				onUse={(data) => {
					const valueObj = {
						name: data.name.toUpperCase(),
						name2: data.name2.toUpperCase(),
						address: data.address.toUpperCase(),
						address2: data.address2.toUpperCase(),
						city: data.city.toUpperCase(),
						state: data.state.toUpperCase(),
						zipCode: data.zipCode.toUpperCase(),
						phoneNumber: data.phoneNumber.replace(/\D*/g, "")
					};
					dispatch(replaceShippingAddresses(valueObj));
					setValue(
						'address',
						valueObj,
						{shouldValidate: true});
					setValue('addressInput', valueObj.address);
				}}
				defaultValues={{...selectedAddress, phoneNumber: formatPhoneNumber(selectedAddress.phoneNumber)}}
				onExited={() => {
					setEditCustomAddressOpen(false);
				}}
				inSepMode={inSepMode}
			/>}
			{editEppAddressOpen && <CustomAddress
				onUse={(data) => {
					const valueObj = {
						name: data.name.toUpperCase(),
						name2: data.name2.toUpperCase(),
						address: data.address.toUpperCase(),
						address2: data.address2.toUpperCase(),
						city: data.city.toUpperCase(),
						state: data.state.toUpperCase(),
						zipCode: data.zipCode.toUpperCase(),
						phoneNumber: data.phoneNumber.replace(/\D*/g, "")
					};
					setValue(
						'eppBillingAddress',
						valueObj,
						{shouldValidate: true});
					if (inSepMode) {
						// noinspection JSIgnoredPromiseFromCall
						axios.put("/sepAddress", {
							name: data.name.toUpperCase().substr(0, 25),
							name2: data.name2.toUpperCase().substr(0, 25),
							address: data.address.toUpperCase().substr(0, 25),
							address2: data.address2.toUpperCase().substr(0, 25),
							city: data.city.toUpperCase().substr(0, 18),
							state: data.state.toUpperCase().substr(0, 2),
							zipCode: data.zipCode.toUpperCase().substr(0, 5),
							phoneNumber: data.phoneNumber.replace(/\D*/g, "")
						});
					}
				}}
				defaultValues={{
					...selectedEppBillingAddress,
					phoneNumber: formatPhoneNumber(selectedEppBillingAddress.phoneNumber)
				}}
				onExited={() => {
					setEditEppAddressOpen(false);
				}}
				inSepMode={inSepMode}
			/>}
			{!!editingEmail &&
			<EditEmail
				email={editingEmail}
				onExited={() => setEditingEmail('')}
				onUpdate={async (data) => await editEmail(data)}
			/>}
			<Dialog open={!!deletingEmail}>
				<DialogContent>
					Are you sure you want to permanently delete {deletingEmail?.address}?
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						onClick={(e) => setDeletingEmail(null)}
					>
						Cancel
					</Button>
					<Button
						className={classes.error}
						variant="text"
						onClick={(e) => deleteEmail()}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={!!promptingCreateEmail}>
				<DialogContent>
					Do you want to save {promptingCreateEmail} for future checkouts?
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						onClick={(e) => setPromptingCreateEmail('')}
					>
						No
					</Button>
					<Button
						variant="text"
						onClick={(e) => createEmail()}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={!!isViewingShippingTerms}
				TransitionComponent={Transition}
				fullScreen={true}
				classes={{
					container: classes.column,
					paper: clsx(classes.column, "flex")
				}}
			>
				<Iframe
					url="https://cnc-api.zmags.com/view/lite/6154c434a3dd511e9634b9fc"
					className={clsx(classes.iFrame, "flex")}
				/>
				<DialogActions>
					<Button color="primary" onClick={() => setIsViewingShippingTerms(false)}>Back</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={selectOnFilePayment}>
				<DialogTitle>Select Payment Method to Use</DialogTitle>
				<DialogContent>
					<List>
						<Divider/>
						{paymentMethods.map((pm, idx) => (
							<React.Fragment key={idx}>
								<ListItem
									button={true}
									onClick={() => {
										setValue("paymentProfileToUse", pm);
										setSelectOnFilePayment(false);
									}}
								>
									<Typography className={classes.breakOnSpace}>
										{`${pm.payment.creditCard.cardType} ending in ${pm.payment.creditCard.cardNumber.substring(4)}\nExpires ${pm.payment.creditCard.expirationDate.substring(5)}/${pm.payment.creditCard.expirationDate.substring(0, 4)}`}
									</Typography>
								</ListItem>
								<Divider/>
							</React.Fragment>
						))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setValue('chargeCard', false);
							setSelectOnFilePayment(false);
						}}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const phoneRegExp = /\([1-9]\d{2}\) \d{3}-\d{4}/g;

const customAddressSchema = yup.object().shape({
	name: yup.string().required('This is required').max(25, 'Must be 25 characters or less'),
	name2: yup.string().max(50, 'Must be 50 characters or less'),
	address: yup.string().required('This is required').max(50, 'Must be 50 characters or less'),
	address2: yup.string().max(50, 'Must be 50 characters or less'),
	city: yup.string().required('This is required').max(50, 'Must be 50 characters or less'),
	state: yup.string().required('Required').max(2, 'Must be 2 characters').min(2, 'Must be 2 characters'),
	zipCode: yup.string().required('This is required').max(12, 'Must be 12 characters or less'),
	phoneNumber: yup.string().required('This is required').matches(phoneRegExp, 'Phone number is not valid')
});

function CustomAddress({onUse, onExited, inSepMode, defaultValues}) {
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(true);
	const {handleSubmit, control} = useSmartForm({
		schema: customAddressSchema,
		defaultValues: !!!defaultValues ? {
			"name": '',
			"name2": '',
			"address": '',
			"address2": '',
			"city": '',
			"state": '',
			"zipCode": '',
			"phoneNumber": ''
		} : defaultValues
	});

	function useCustomAddress(data) {
		onUse(data);
		setIsOpen(false);
	}

	return (
		<Dialog
			open={isOpen}
			maxWidth="sm"
			fullWidth={true}
			TransitionProps={{
				onExited: onExited
			}}
		>
			<DialogContent className={clsx(classes.column)}>
				<FormTextField
					formProps={{
						name: 'name',
						control
					}}
					label="Name"
					variant="outlined"
					autoFocus={true}
					margin="normal"
					required={true}
				/>
				<FormTextField
					formProps={{
						name: 'name2',
						control
					}}
					label="Name 2"
					variant="outlined"
					margin="normal"
				/>
				<FormTextField
					formProps={{
						name: 'address',
						control
					}}
					label="Address"
					variant="outlined"
					margin="normal"
					required={true}
				/>
				<FormTextField
					formProps={{
						name: 'address2',
						control
					}}
					label="Address 2"
					variant="outlined"
					margin="normal"
				/>
				<div className={classes.row}>
					<FormTextField
						formProps={{
							name: 'city',
							control
						}}
						className="flex60"
						label="City"
						variant="outlined"
						margin="normal"
						required
					/>
					<Spacer/>
					<FormTextField
						formProps={{
							name: 'state',
							control
						}}
						className="flex15"
						label="State"
						variant="outlined"
						margin="normal"
						required
					/>
					<Spacer/>
					<FormTextField
						formProps={{
							name: 'zipCode',
							control
						}}
						className="flex25"
						label="Zip"
						variant="outlined"
						margin="normal"
						required
					/>
				</div>
				<FormTelephoneInput
					formProps={{
						name: 'phoneNumber',
						control
					}}
					label="Phone Number"
					variant="outlined"
					margin="normal"
					required
				/>
				<br/>
			</DialogContent>
			<DialogActions className={clsx(classes.row, classes.alignSpaceBetweenCenter)}>
				<Typography variant="body2">&nbsp; * denotes required field</Typography>
				<div>
					{(!inSepMode || !!defaultValues) &&
					<Button
						variant="text"
						color="inherit"
						onClick={() => {
							setIsOpen(false);
						}}
					>
						Cancel
					</Button>}
					<Button
						variant="text"
						color="primary"
						onClick={handleSubmit(useCustomAddress)}
					>
						Submit
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

const emailManagementSchema = yup.object().shape({
	email: yup.string().required('This is required').email('Must be a valid email').max(59, 'Must be less than 60 characters'),
});

function EditEmail({email, onExited, onUpdate}) {
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const {handleSubmit, control} = useSmartForm({
		schema: emailManagementSchema,
		defaultValues: {
			"email": email.address
		}
	});

	async function update(data) {
		if (isSaving) {
			return;
		}
		setIsSaving(true);
		await onUpdate(data);
		setIsOpen(false);
	}

	return (
		<Dialog
			open={isOpen}
			maxWidth="xs"
			fullWidth={true}
			TransitionProps={{
				onExited: onExited
			}}
		>
			<DialogTitle>Update Email</DialogTitle>
			<DialogContent className={classes.column}>
				<FormTextField
					formProps={{
						name: 'email',
						control
					}}
					disabled={isSaving}
					label="Email"
					variant="outlined"
					margin="normal"
					required={true}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="text"
					disabled={isSaving}
					onClick={(e) => setIsOpen(false)}
				>
					Cancel
				</Button>
				<Button
					variant="text"
					disabled={isSaving}
					color="primary"
					onClick={handleSubmit(update)}
				>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default PlaceOrderCheckout;
