import * as React from "react";

function SvgKaiserFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" {...props}>
      <path
        d="M17.9 11.7c-.7-.4-1.5-.6-2.3-.7-.6-.1-.8-1.2 0-1.4.8-.1 1.5-.3 2.2-.7.8-.6 1.2-1.5 1.1-2.5V0h-7v8.3c0 1-.8 1.7-1.8 1.7H7.2V0H0v20h7.3v-9.2h2.5c2 0 2.1 2.3 2.1 2.3V20H19v-6c.1-.9-.3-1.8-1.1-2.3z"
      />
    </svg>
  );
}

export default SvgKaiserFavicon;
