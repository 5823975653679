import * as React from "react";

function SvgArrowDown(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 8.5" {...props}>
      <path
        d="M1.383.029l-1.4 1.3 6.8 7.2h1.4l6.8-7.2-1.3-1.3-6.2 6.4z"
      />
    </svg>
  );
}

export default SvgArrowDown;
