import * as React from "react";

function SvgFobaFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 16.5" {...props}>
      <path
        d="M.1 4.537h25.4v-4.6H.1v4.6zm-.1 1.5v10.4h4.4v-5.8h21v-4.6H0z"
      />
    </svg>
  );
}

export default SvgFobaFavicon;
