import * as React from "react";

function SvgNanliteFavicon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 20" {...props}>
      <path
        d="M22.762.14l-2.5 14.2-7.5-14.2c0-.1-.1-.1-.1-.1h-9c-.1 0-.2.1-.2.1l-3.4 19.7c0 .1 0 .2.1.2h3.9c.1 0 .2-.1.2-.1l2.5-14.3 7.5 14.3c0 .1.1.1.1.1h9c.1 0 .2-.1.2-.1l3.4-19.7c0-.1 0-.2-.1-.2h-3.9c-.1 0-.1 0-.2.1"
      />
    </svg>
  );
}

export default SvgNanliteFavicon;
